import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Chip, Paper, Button, Fade, Divider } from '@mui/material';
import axios from 'axios';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RefreshIcon from '@mui/icons-material/Refresh';

const genres = [
  "Money and Finance", "Personal Development", "Fiction", "Historical Fiction", "Biographies",
  "Science and Technology", "Health and Wellness", "Thriller", "Fantasy", "Science Fiction",
  "Romance", "Young Adult", "Children", "Cookbooks", "Poetry", "Travel", "Philosophy", "Politics"
];

const BooksWidget = ({ user }) => {
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUserGenres = useCallback(async () => {
    if (!user) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/books/user-genres/${user.googleId}`);
      setSelectedGenres(response.data.genres);
    } catch (error) {
      console.error('Error fetching user genres:', error);
    }
  }, [user]);

  const fetchBook = useCallback(async () => {
    if (selectedGenres.length === 0 || !user) return;
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/books/recommendation/${user.googleId}`);
      setBook(response.data);
    } catch (error) {
      console.error('Error fetching book recommendation:', error);
      setBook(null);
    } finally {
      setLoading(false);
    }
  }, [selectedGenres, user]);

  useEffect(() => {
    fetchUserGenres();
  }, [fetchUserGenres]);

  useEffect(() => {
    if (selectedGenres.length > 0) {
      fetchBook();
    } else {
      setBook(null);
    }
  }, [selectedGenres, fetchBook]);

  const saveUserGenres = async (genres) => {
    if (!user) return;
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/books/user-genres/${user.googleId}`, { genres });
    } catch (error) {
      console.error('Error saving user genres:', error);
    }
  };

  const handleGenreToggle = (genre) => {
    setSelectedGenres(prev => {
      const newGenres = prev.includes(genre)
        ? prev.filter(g => g !== genre)
        : [...prev, genre];
      saveUserGenres(newGenres);
      return newGenres;
    });
  };

  const formatResume = (resume) => {
    return resume.split('\n').map((paragraph, index) => (
      <Typography key={index} variant="body2" paragraph>
        {paragraph}
      </Typography>
    ));
  };

  return (
    <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Meet your next favorite book
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
        Select genres to receive daily recommendations for the absolutely best books ever written.
      </Typography>
      
      <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {genres.map((genre) => (
          <Chip
            key={genre}
            label={genre}
            onClick={() => handleGenreToggle(genre)}
            color={selectedGenres.includes(genre) ? "primary" : "default"}
            variant={selectedGenres.includes(genre) ? "filled" : "outlined"}
            size="small"
          />
        ))}
      </Box>

      {loading ? (
        <Typography variant="body1" sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'text.secondary' }}>
          Loading recommendation...
        </Typography>
      ) : book ? (
        <Fade in={true} timeout={1000}>
          <Paper elevation={3} sx={{ 
            p: 2, 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
            overflow: 'auto'
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Box>
                <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  {book.title}
                </Typography>
                <Typography variant="subtitle1">
                  by {book.author} ({book.year})
                </Typography>
              </Box>
              <Button 
                variant="contained" 
                color="primary" 
                href={book.link} 
                target="_blank" 
                rel="noopener noreferrer"
                startIcon={<ShoppingCartIcon />}
                size="small"
              >
                Buy on Amazon
              </Button>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              {formatResume(book.resume)}
            </Box>
          </Paper>
        </Fade>
      ) : (
        <Typography variant="body1" sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'text.secondary' }}>
          Select at least one genre to get a book recommendation.
        </Typography>
      )}
    </Box>
  );
};

export default BooksWidget;