import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, Box, Typography, IconButton, CircularProgress, Paper } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';

const PromptRefinerWidget = ({ userId }) => {
  const [selectedRefiner, setSelectedRefiner] = useState('cursorCoding');
  const [userPrompt, setUserPrompt] = useState('');
  const [refinedPrompt, setRefinedPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const systemPrompts = {
    cursorCoding: "You are an AI prompt engineer specialized in refining user prompts. Your goal is to transform vague or general coding requests into clear, specific, and actionable prompts that will yield better results when used with a code-generation AI.",
    // Add more system prompts for other refiner types here
  };

  const refinerDescriptions = {
    cursorCoding: "This prompt refiner will translate plain English into a more technical prompt, using more specific terminology to get better results from Cursor.",
    // Add more descriptions for other refiner types here
  };

  const handleRefinerChange = (event) => {
    setSelectedRefiner(event.target.value);
  };

  const handleRefinePrompt = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/ai/openai-chat`, { 
        messages: [
          { role: 'system', content: systemPrompts[selectedRefiner] },
          { role: 'user', content: `Hello ChatGPT, please refine this prompt. If you see content that can be inside " " or ' ' please add them. Just answer with the refined prompt. Don't add the programminglanguage. No need to explain or add anything else. Structure and create a chain action plan if you feel is important. Here is the prompt: "${userPrompt}"` }
        ]
      });
      setRefinedPrompt(response.data.result);
    } catch (error) {
      console.error('Error refining prompt:', error);
      setRefinedPrompt('An error occurred while refining your prompt. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(refinedPrompt);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%', overflow: 'hidden' }}>
      <Typography variant="h6">Select which prompt refiner you want to use:</Typography>
      <Select
        value={selectedRefiner}
        onChange={handleRefinerChange}
        sx={{ width: '100%' }}
      >
        <MenuItem value="cursorCoding">Cursor Coding</MenuItem>
        {/* Add more refiner options here */}
      </Select>
      <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
        {refinerDescriptions[selectedRefiner]}
      </Typography>
      <TextField
        label="Your Prompt"
        multiline
        rows={4}
        value={userPrompt}
        onChange={(e) => setUserPrompt(e.target.value)}
        sx={{ 
          '& .MuiInputBase-input': { 
            color: 'text.primary',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'text.primary',
            },
            '&:hover fieldset': {
              borderColor: 'text.primary',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
        }}
      />
      <Button 
        variant="contained" 
        onClick={handleRefinePrompt}
        disabled={isLoading}
        startIcon={isLoading ? <CircularProgress size={20} /> : null}
      >
        {isLoading ? 'Refining prompt...' : refinedPrompt ? 'Retry' : 'Refine my Prompt'}
      </Button>
      {refinedPrompt && (
        <Paper 
          elevation={3} 
          sx={{ 
            mt: 2, 
            p: 2, 
            position: 'relative', 
            flexGrow: 1, 
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid slategrey'
            }
          }}
        >
          <Typography variant="h6" gutterBottom>Refined Prompt:</Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              whiteSpace: 'pre-wrap', 
              wordBreak: 'break-word',
              mb: 4 // Add bottom margin to ensure space for the copy button
            }}
          >
            {refinedPrompt}
          </Typography>
          <IconButton 
            onClick={handleCopyToClipboard} 
            sx={{ position: 'absolute', bottom: 8, right: 8 }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Paper>
      )}
    </Box>
  );
};

export default PromptRefinerWidget;