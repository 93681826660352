import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Tabs, Tab, List, ListItem, ListItemIcon, ListItemText, Checkbox, IconButton, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Tooltip, Popper, Paper, Modal, CircularProgress, useTheme } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import axios from 'axios';
import debounce from 'lodash/debounce';

const TabsRef = React.forwardRef((props, ref) => (
  <Tabs
    {...props}
    ref={ref}
  />
));

const TaskProjectsWidget = ({ userId }) => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const lastSavedProjects = useRef(null);
  const [currentProject, setCurrentProject] = useState(0);
  const [selectedTask, setSelectedTask] = useState(null);
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [newTask, setNewTask] = useState({ title: '', description: '' });
  const [isAddingNewTask, setIsAddingNewTask] = useState(false);
  const [deleteConfirmAnchorEl, setDeleteConfirmAnchorEl] = useState(null);
  const [deleteConfirmTaskId, setDeleteConfirmTaskId] = useState(null);
  const [deleteProjectAnchorEl, setDeleteProjectAnchorEl] = useState(null);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [aiProjectModalOpen, setAiProjectModalOpen] = useState(false);
  const [aiProjectPrompt, setAiProjectPrompt] = useState('');
  const [aiProjectResponse, setAiProjectResponse] = useState('');
  const [isGeneratingProject, setIsGeneratingProject] = useState(false);
  const [aiTaskBreakdownModalOpen, setAiTaskBreakdownModalOpen] = useState(false);
  const [aiTaskBreakdownPrompt, setAiTaskBreakdownPrompt] = useState('');
  const [aiTaskBreakdownResponse, setAiTaskBreakdownResponse] = useState('');
  const [isBreakingDownTask, setIsBreakingDownTask] = useState(false);
  const [selectedTaskForBreakdown, setSelectedTaskForBreakdown] = useState(null);

  const tabsRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (userId) {
      fetchProjects();
    }
  }, [userId]);

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/task-projects/${userId}`);
      if (response.data.length > 0) {
        setProjects(response.data);
        lastSavedProjects.current = JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveProjects = useCallback(async () => {
    if (userId && !isLoading && !isSaving) {
      setIsSaving(true);
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/task-projects`, {
          userId,
          projects
        });
        lastSavedProjects.current = JSON.parse(JSON.stringify(projects));
      } catch (error) {
        console.error('Error saving projects:', error);
        // Optionally, revert to last saved state if save fails
        // setProjects(lastSavedProjects.current);
      } finally {
        setIsSaving(false);
      }
    }
  }, [userId, projects, isLoading, isSaving]);

  const debouncedSave = useCallback(
    debounce(() => {
      if (!isLoading && !isSaving && JSON.stringify(projects) !== JSON.stringify(lastSavedProjects.current)) {
        saveProjects();
      }
    }, 1000),
    [saveProjects, projects, isLoading, isSaving]
  );

  useEffect(() => {
    debouncedSave();
  }, [projects, debouncedSave]);

  const handleScrollProjects = (direction) => {
    if (tabsRef.current) {
      const tabsScroller = tabsRef.current.querySelector('.MuiTabs-scroller');
      if (tabsScroller) {
        const tabs = tabsScroller.querySelector('.MuiTabs-flexContainer').children;
        const currentTabIndex = currentProject;
        const targetIndex = Math.max(0, Math.min(currentTabIndex + direction, projects.length - 1));
        
        if (tabs[targetIndex]) {
          tabs[targetIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
          });
          setCurrentProject(targetIndex);
        }
      }
    }
  };

  const handleAddProject = () => {
    const newProject = { id: Date.now(), name: `Project ${projects.length + 1}`, tasks: [] };
    setProjects(prevProjects => {
      const updatedProjects = [...prevProjects, newProject];
      setCurrentProject(updatedProjects.length - 1);
      return updatedProjects;
    });
  };

  const handleChangeProject = (event, newValue) => {
    if (newValue === projects.length) {
      handleAddProject();
    } else {
      setCurrentProject(newValue);
    }
  };

  const handleAddTask = () => {
    const task = {
      id: Date.now(),
      title: newTask.title || 'New Task',
      description: newTask.description || 'Task description',
      dueDate: new Date().toISOString().split('T')[0],
      completed: false,
      priority: 'Low'
    };
    setProjects(prevProjects => {
      const updatedProjects = [...prevProjects];
      if (!updatedProjects[currentProject]) {
        updatedProjects.push({ id: Date.now(), name: 'New Project', tasks: [] });
        setCurrentProject(updatedProjects.length - 1);
      }
      updatedProjects[currentProject].tasks.push(task);
      return updatedProjects;
    });
    setNewTask({ title: '', description: '' });
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
  };

  const handleBackToList = () => {
    setSelectedTask(null);
  };

  const handleCompleteTask = (taskId) => {
    setProjects(prevProjects => {
      const updatedProjects = [...prevProjects];
      const taskIndex = updatedProjects[currentProject].tasks.findIndex(task => task.id === taskId);
      updatedProjects[currentProject].tasks[taskIndex].completed = !updatedProjects[currentProject].tasks[taskIndex].completed;
      return updatedProjects;
    });
  };

  const handlePriorityChange = (taskId) => {
    setProjects(prevProjects => {
      const updatedProjects = [...prevProjects];
      const taskIndex = updatedProjects[currentProject].tasks.findIndex(task => task.id === taskId);
      const currentPriority = updatedProjects[currentProject].tasks[taskIndex].priority;
      const priorities = ['Low', 'Medium', 'High'];
      const nextPriority = priorities[(priorities.indexOf(currentPriority) + 1) % 3];
      updatedProjects[currentProject].tasks[taskIndex].priority = nextPriority;
      return updatedProjects;
    });
  };

  const getDaysLeft = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const diffTime = Math.abs(due - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleEditProject = (projectId) => {
    setEditingProjectId(projectId);
  };

  const handleProjectNameChange = (event, projectId) => {
    setProjects(prevProjects =>
      prevProjects.map(project =>
        project.id === projectId ? { ...project, name: event.target.value } : project
      )
    );
  };

  const handleProjectNameBlur = () => {
    setEditingProjectId(null);
  };

  const handleProjectNameKeyDown = (event, projectId) => {
    if (event.key === 'Enter') {
      handleProjectNameBlur();
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Low': return 'green';
      case 'Medium': return 'orange';
      case 'High': return 'red';
      default: return 'inherit';
    }
  };

  const handleTaskChange = (field, value) => {
    setSelectedTask(prevTask => ({ ...prevTask, [field]: value }));
    setProjects(prevProjects => {
      const updatedProjects = [...prevProjects];
      const taskIndex = updatedProjects[currentProject].tasks.findIndex(task => task.id === selectedTask.id);
      updatedProjects[currentProject].tasks[taskIndex] = { ...updatedProjects[currentProject].tasks[taskIndex], [field]: value };
      return updatedProjects;
    });
  };

  const handleDeleteTask = (taskId) => {
    setProjects(prevProjects => {
      const updatedProjects = [...prevProjects];
      updatedProjects[currentProject].tasks = updatedProjects[currentProject].tasks.filter(task => task.id !== taskId);
      return updatedProjects;
    });
  };

  const handleAddNewTaskClick = () => {
    setIsAddingNewTask(true);
  };

  const handleSaveNewTask = () => {
    handleAddTask();
    setIsAddingNewTask(false);
  };

  const handleCancelNewTask = () => {
    setNewTask({ title: '', description: '' });
    setIsAddingNewTask(false);
  };

  const handleDeleteConfirmOpen = (event, taskId) => {
    setDeleteConfirmAnchorEl(event.currentTarget);
    setDeleteConfirmTaskId(taskId);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmAnchorEl(null);
    setDeleteConfirmTaskId(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteConfirmTaskId) {
      handleDeleteTask(deleteConfirmTaskId);
    }
    handleDeleteConfirmClose();
  };

  const handleDeleteProjectConfirmOpen = (event, projectId) => {
    event.stopPropagation();
    setDeleteProjectAnchorEl(event.currentTarget);
    setDeleteProjectId(projectId);
  };

  const handleDeleteProjectConfirmClose = () => {
    setDeleteProjectAnchorEl(null);
    setDeleteProjectId(null);
  };

  const handleDeleteProjectConfirm = () => {
    if (deleteProjectId) {
      setProjects(prevProjects => {
        const updatedProjects = prevProjects.filter(project => project.id !== deleteProjectId);
        // If we're deleting the last project, create a new empty project
        if (updatedProjects.length === 0) {
          updatedProjects.push({ id: 1, name: 'New Project', tasks: [] });
        }
        // Adjust currentProject if necessary
        setCurrentProject(prevCurrentProject => 
          Math.min(prevCurrentProject, updatedProjects.length - 1)
        );
        return updatedProjects;
      });
    }
    handleDeleteProjectConfirmClose();
  };

  const ControlledOpenSelect = ({ value, onChange }) => {
    return (
      <FormControl fullWidth>
        <InputLabel id="priority-label">Priority</InputLabel>
        <Select
          labelId="priority-label"
          id="priority-select"
          value={value}
          label="Priority"
          onChange={onChange}
        >
          <MenuItem value="Low">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LabelImportantIcon sx={{ color: 'green', mr: 1 }} />
              Low
            </Box>
          </MenuItem>
          <MenuItem value="Medium">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LabelImportantIcon sx={{ color: 'orange', mr: 1 }} />
              Medium
            </Box>
          </MenuItem>
          <MenuItem value="High">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LabelImportantIcon sx={{ color: 'red', mr: 1 }} />
              High
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  const handleOpenAiProjectModal = () => {
    setAiProjectModalOpen(true);
  };

  const handleCloseAiProjectModal = () => {
    setAiProjectModalOpen(false);
    setAiProjectPrompt('');
    setAiProjectResponse('');
  };

  const handleGenerateAiProject = async () => {
    setIsGeneratingProject(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/ai/openai-chat`, { 
        messages: [
          { role: 'system', content: 'You are a project manager. Always provide your response in the following format:\n[title] Project Title\n\n- Task 1 [priority:High/Medium/Low]\nDescription: Brief description of Task 1\n\n- Task 2 [priority:High/Medium/Low]\nDescription: Brief description of Task 2\n\n...' },
          { role: 'user', content: aiProjectPrompt }
        ]
      });
      setAiProjectResponse(response.data.result);
    } catch (error) {
      console.error('Error generating AI project:', error);
      setAiProjectResponse('An error occurred while generating the project.');
    } finally {
      setIsGeneratingProject(false);
    }
  };

  const handleCreateAiProject = () => {
    const lines = aiProjectResponse.split('\n');
    const title = lines[0].match(/\[title\] (.+)/)?.[1] || 'New AI Generated Project';
    const tasks = [];
    let currentTask = null;

    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.startsWith('-')) {
        if (currentTask) {
          tasks.push(currentTask);
        }
        const match = line.match(/- (.+) \[priority:(.+)\]/);
        if (match) {
          currentTask = {
            id: Date.now() + Math.random(),
            title: match[1],
            description: '',
            dueDate: new Date().toISOString().split('T')[0],
            completed: false,
            priority: match[2].charAt(0).toUpperCase() + match[2].slice(1).toLowerCase() // Capitalize first letter
          };
        }
      } else if (line.startsWith('Description:') && currentTask) {
        currentTask.description = line.replace('Description:', '').trim();
      }
    }

    if (currentTask) {
      tasks.push(currentTask);
    }

    const newProject = {
      id: projects.length + 1,
      name: title,
      tasks: tasks
    };

    setProjects(prevProjects => {
      const updatedProjects = [...prevProjects, newProject];
      setCurrentProject(updatedProjects.length - 1);
      return updatedProjects;
    });
    handleCloseAiProjectModal();
  };

  const handleOpenAiTaskBreakdownModal = (task) => {
    setSelectedTaskForBreakdown(task);
    setAiTaskBreakdownPrompt(`Hey ChatGPT, I would like to break down this task into even smaller and more manageable tasks:
[${task.title}]
${task.description}`);
    setAiTaskBreakdownModalOpen(true);
  };

  const handleCloseAiTaskBreakdownModal = () => {
    setAiTaskBreakdownModalOpen(false);
    setAiTaskBreakdownPrompt('');
    setAiTaskBreakdownResponse('');
    setSelectedTaskForBreakdown(null);
  };

  const handleGenerateAiTaskBreakdown = async () => {
    setIsBreakingDownTask(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/ai/openai-chat`, { 
        messages: [
          { role: 'system', content: 'You are a task breakdown specialist. Provide a very detailed step by step breakdown of the given task, like a tutorial or how-to-do. Format your response as follows:\n\n1. Step 1 [priority:High/Medium/Low]\nDescription: Detailed description of Step 1\n\n2. Step 2 [priority:High/Medium/Low]\nDescription: Detailed description of Step 2\n\n...' },
          { role: 'user', content: aiTaskBreakdownPrompt }
        ]
      });
      setAiTaskBreakdownResponse(response.data.result);
    } catch (error) {
      console.error('Error generating AI task breakdown:', error);
      setAiTaskBreakdownResponse('An error occurred while breaking down the task.');
    } finally {
      setIsBreakingDownTask(false);
    }
  };

  const handleCreateAiTaskBreakdown = () => {
    const lines = aiTaskBreakdownResponse.split('\n');
    const tasks = [];
    let currentTask = null;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.match(/^\d+\./)) {
        if (currentTask) {
          tasks.push(currentTask);
        }
        const match = line.match(/^\d+\. (.+) \[priority:(.+)\]/);
        if (match) {
          currentTask = {
            id: Date.now() + Math.random(),
            title: match[1],
            description: '',
            dueDate: new Date().toISOString().split('T')[0],
            completed: false,
            priority: match[2].charAt(0).toUpperCase() + match[2].slice(1).toLowerCase()
          };
        }
      } else if (line.startsWith('Description:') && currentTask) {
        currentTask.description = line.replace('Description:', '').trim();
      }
    }

    if (currentTask) {
      tasks.push(currentTask);
    }

    const newProject = {
      id: projects.length + 1,
      name: `${selectedTaskForBreakdown.title} (Breakdown)`,
      tasks: tasks
    };

    setProjects(prevProjects => {
      const updatedProjects = [...prevProjects, newProject];
      setCurrentProject(updatedProjects.length - 1);
      return updatedProjects;
    });
    handleCloseAiTaskBreakdownModal();
  };

  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const handleMoveTask = (taskId, direction) => {
    const updatedProjects = [...projects];
    const taskIndex = updatedProjects[currentProject].tasks.findIndex(task => task.id === taskId);
    const newIndex = taskIndex + direction;

    if (newIndex >= 0 && newIndex < updatedProjects[currentProject].tasks.length) {
      const [movedTask] = updatedProjects[currentProject].tasks.splice(taskIndex, 1);
      updatedProjects[currentProject].tasks.splice(newIndex, 0, movedTask);
      setProjects(updatedProjects);
    }
  };

  return (
    <Box sx={{ width: '100%' }} data-widget-id="taskProjects" data-is-saving={isSaving.toString()}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {selectedTask ? (
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                  label="Title"
                  value={selectedTask.title}
                  onChange={(e) => handleTaskChange('title', e.target.value)}
                  sx={{ flexGrow: 1, mr: 2 }}
                />
                <IconButton color="error" onClick={(e) => handleDeleteConfirmOpen(e, selectedTask.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <TextField
                fullWidth
                multiline
                rows={8}  // Doubled the size of the Description field
                label="Description"
                value={selectedTask.description}
                onChange={(e) => handleTaskChange('description', e.target.value)}
                sx={{ my: 2, flexGrow: 1 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
                <TextField
                  type="date"
                  label="Due Date"
                  value={selectedTask.dueDate}
                  onChange={(e) => handleTaskChange('dueDate', e.target.value)}
                  sx={{ width: '48%' }}
                />
                <Box sx={{ width: '48%' }}>
                  <ControlledOpenSelect
                    value={selectedTask.priority}
                    onChange={(e) => handleTaskChange('priority', e.target.value)}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 'auto', pt: 2 }}>
                <Button 
                  startIcon={<ArrowBackIcon />} 
                  onClick={handleBackToList}
                  sx={{ mr: 2 }}
                >
                  Back
                </Button>
                <Button 
                  variant="contained" 
                  onClick={() => handleCompleteTask(selectedTask.id)} 
                >
                  {selectedTask.completed ? 'Mark as Incomplete' : 'Mark as Complete'}
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box sx={{ 
                borderBottom: 1, 
                borderColor: 'divider', 
                display: 'flex', 
                alignItems: 'center', 
                position: 'relative',
                width: '100%'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 100px)' }}>
                  <IconButton 
                    onClick={() => handleScrollProjects(-1)} 
                    sx={{ zIndex: 1 }}
                    disabled={currentProject === 0}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <Box 
                    sx={{ 
                      width: 'calc(100% - 80px)',
                      overflow: 'hidden',
                      position: 'relative',
                    }}
                  >
                    <TabsRef 
                      value={currentProject} 
                      onChange={handleChangeProject} 
                      aria-label="project tabs"
                      variant="scrollable"
                      scrollButtons={false}
                      ref={tabsRef}
                      sx={{ 
                        '& .MuiTabs-flexContainer': {
                          justifyContent: 'flex-start'
                        }
                      }}
                    >
                      {projects.map((project, index) => (
                        <Tab 
                          key={project.id} 
                          label={
                            editingProjectId === project.id ? (
                              <TextField
                                value={project.name}
                                onChange={(e) => handleProjectNameChange(e, project.id)}
                                onBlur={handleProjectNameBlur}
                                onKeyDown={(e) => handleProjectNameKeyDown(e, project.id)}
                                autoFocus
                                size="small"
                              />
                            ) : (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {project.name}
                                {index === currentProject && (
                                  <>
                                    <IconButton size="small" onClick={() => handleEditProject(project.id)} sx={{ ml: 1 }}>
                                      <EditIcon fontSize="small" sx={{ width: 16, height: 16 }} />
                                    </IconButton>
                                    <IconButton size="small" onClick={(e) => handleDeleteProjectConfirmOpen(e, project.id)} sx={{ ml: 1 }}>
                                      <CloseIcon fontSize="small" sx={{ width: 16, height: 16 }} />
                                    </IconButton>
                                  </>
                                )}
                              </Box>
                            )
                          } 
                        />
                      ))}
                    </TabsRef>
                  </Box>
                  <IconButton 
                    onClick={() => handleScrollProjects(1)} 
                    sx={{ zIndex: 1 }}
                    disabled={currentProject === projects.length - 1}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
                <Box sx={{ 
                  width: '100px', 
                  display: 'flex', 
                  justifyContent: 'flex-end', 
                  alignItems: 'center',
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: 'background.paper'
                }}>
                  <Tooltip title="Add new project">
                    <IconButton onClick={handleAddProject}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Use AI to design the entire project, creating all steps and tasks">
                    <IconButton onClick={handleOpenAiProjectModal}>
                      <AutoAwesomeIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <List>
                {projects.length > 0 && projects[currentProject] && projects[currentProject].tasks.map((task) => (
                  <ListItem
                    key={task.id}
                    disablePadding
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      py: 1
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, mr: 2 }}>
                      <IconButton
                        onClick={() => handleMoveTask(task.id, -1)}
                        disabled={projects[currentProject].tasks.indexOf(task) === 0}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleMoveTask(task.id, 1)}
                        disabled={projects[currentProject].tasks.indexOf(task) === projects[currentProject].tasks.length - 1}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={task.completed}
                          onChange={() => handleCompleteTask(task.id)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={task.title}
                        secondary={
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.primary"
                            sx={{
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {task.description}
                          </Typography>
                        }
                        onClick={() => handleTaskClick(task)}
                        sx={{
                          textDecoration: task.completed ? 'line-through' : 'none',
                          cursor: 'pointer'
                        }}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 330, justifyContent: 'flex-end' }}>
                      <Box sx={{ width: 100, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Typography variant="body2" sx={{ mr: 1 }}>
                          {task.priority}
                        </Typography>
                        <IconButton onClick={() => handlePriorityChange(task.id)}>
                          <LabelImportantIcon sx={{ color: getPriorityColor(task.priority) }} />
                        </IconButton>
                      </Box>
                      <Typography variant="body2" color="text.secondary" sx={{ width: 110, textAlign: 'right', mr: 2 }}>
                        {getDaysLeft(task.dueDate)} days left
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Break down this task into smaller steps using AI">
                          <IconButton edge="end" aria-label="ai-breakdown" onClick={() => handleOpenAiTaskBreakdownModal(task)}>
                            <AutoAwesomeIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteConfirmOpen(e, task.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
                <ListItem>
                  {isAddingNewTask ? (
                    <>
                      <TextField
                        placeholder="New task title"
                        value={newTask.title}
                        onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                        sx={{ mr: 1, flexGrow: 1 }}
                      />
                      <TextField
                        placeholder="New task description"
                        value={newTask.description}
                        onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                        sx={{ mr: 1, flexGrow: 1 }}
                      />
                      <Button onClick={handleSaveNewTask} variant="contained" color="primary">
                        Save
                      </Button>
                      <Button onClick={handleCancelNewTask} sx={{ ml: 1 }}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button startIcon={<AddIcon />} onClick={handleAddNewTaskClick}>
                      Add new task
                    </Button>
                  )}
                </ListItem>
              </List>
            </>
          )}
          <Popper open={Boolean(deleteConfirmAnchorEl)} anchorEl={deleteConfirmAnchorEl} placement="top">
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ mb: 2 }}>Are you sure you want to delete this task?</Typography>
              <Box>
                <Button variant="contained" color="error" onClick={handleDeleteConfirm} sx={{ mr: 1 }}>Yes</Button>
                <Button variant="outlined" onClick={handleDeleteConfirmClose}>No</Button>
              </Box>
            </Paper>
          </Popper>
          <Popper open={Boolean(deleteProjectAnchorEl)} anchorEl={deleteProjectAnchorEl} placement="bottom">
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ mb: 2 }}>Are you sure you want to delete this project?</Typography>
              <Box>
                <Button variant="contained" color="error" onClick={handleDeleteProjectConfirm} sx={{ mr: 1 }}>Yes</Button>
                <Button variant="outlined" onClick={handleDeleteProjectConfirmClose}>No</Button>
              </Box>
            </Paper>
          </Popper>
          <Modal
            open={aiProjectModalOpen}
            onClose={handleCloseAiProjectModal}
            aria-labelledby="ai-project-modal-title"
            aria-describedby="ai-project-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '40%', // Changed from 50% to 40% to move it slightly above center
              left: '50%',
              transform: 'translate(-50%, -40%)', // Changed from -50% to -40% to match the top change
              width: 1000, // Increased from 800 to 1000
              height: 800, // Increased from 600 to 800
              bgcolor: theme.palette.mode === 'dark' ? '#0b0c10' : '#f5f5f5',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Typography id="ai-project-modal-title" variant="h4" component="h2" gutterBottom>
                AI Project Generator
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Describe your project, and our AI will generate a structured plan with tasks and priorities.
              </Typography>
              <TextField
                multiline
                minRows={4}
                placeholder="E.g., Create a marketing campaign for our new product launch..."
                value={aiProjectPrompt}
                onChange={(e) => setAiProjectPrompt(e.target.value)}
                sx={{ 
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    color: theme.palette.text.primary,
                    '& fieldset': {
                      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                    },
                    '&:hover fieldset': {
                      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: theme.palette.text.secondary,
                  },
                }}
                fullWidth
              />
              <Button
                onClick={handleGenerateAiProject}
                variant="contained"
                disabled={isGeneratingProject}
                sx={{ alignSelf: 'flex-start', mb: 2 }}
              >
                {isGeneratingProject ? <CircularProgress size={24} sx={{ mr: 1 }} /> : null}
                {isGeneratingProject ? 'Generating...' : 'Generate Project Plan'}
              </Button>
              {aiProjectResponse && (
                <>
                  <Box sx={{ 
                    flexGrow: 1, 
                    overflow: 'auto', 
                    bgcolor: theme.palette.mode === 'dark' ? '#111217' : '#ffffff',
                    color: theme.palette.text.primary,
                    p: 2, 
                    borderRadius: 1,
                    mb: 2,
                    border: `1px solid ${theme.palette.divider}`,
                    height: '60%', // Increased the height to take up more space
                  }}>
                    <Typography variant="h6" gutterBottom>AI Generated Project Plan:</Typography>
                    <Typography sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>{aiProjectResponse}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button 
                      onClick={handleCreateAiProject} 
                      variant="contained" 
                      color="success"
                    >
                      Create Project
                    </Button>
                    <Button 
                      onClick={() => {
                        setAiProjectResponse('');
                        setAiProjectPrompt('');
                      }} 
                      variant="outlined"
                      sx={{
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.text.primary,
                        '&:hover': {
                          borderColor: theme.palette.primary.main,
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      Start Over
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Modal>
          <Modal
            open={aiTaskBreakdownModalOpen}
            onClose={handleCloseAiTaskBreakdownModal}
            aria-labelledby="ai-task-breakdown-modal-title"
            aria-describedby="ai-task-breakdown-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '40%', // Changed from 50% to 40% to move it slightly above center
              left: '50%',
              transform: 'translate(-50%, -40%)', // Changed from -50% to -40% to match the top change
              width: 1000, // Increased from 800 to 1000
              height: 800, // Increased from 600 to 800
              bgcolor: theme.palette.mode === 'dark' ? '#0b0c10' : '#f5f5f5',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Typography id="ai-task-breakdown-modal-title" variant="h4" component="h2" gutterBottom>
                AI Task Breakdown
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Modify the prompt if needed, then click "Generate" to break down the task.
              </Typography>
              <TextField
                multiline
                minRows={6}
                value={aiTaskBreakdownPrompt}
                onChange={(e) => setAiTaskBreakdownPrompt(e.target.value)}
                sx={{ 
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    color: theme.palette.text.primary,
                    '& fieldset': {
                      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                    },
                    '&:hover fieldset': {
                      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: theme.palette.text.secondary,
                  },
                }}
                fullWidth
              />
              <Button
                onClick={handleGenerateAiTaskBreakdown}
                variant="contained"
                disabled={isBreakingDownTask}
                sx={{ alignSelf: 'flex-start', mb: 2 }}
              >
                {isBreakingDownTask ? <CircularProgress size={24} sx={{ mr: 1 }} /> : null}
                {isBreakingDownTask ? 'Generating...' : 'Generate'}
              </Button>
              {aiTaskBreakdownResponse && (
                <>
                  <Box sx={{ 
                    flexGrow: 1, 
                    overflow: 'auto', 
                    bgcolor: theme.palette.mode === 'dark' ? '#111217' : '#ffffff',
                    color: theme.palette.text.primary,
                    p: 2, 
                    borderRadius: 1,
                    mb: 2,
                    border: `1px solid ${theme.palette.divider}`,
                    height: '60%', // Increased the height to take up more space
                  }}>
                    <Typography variant="h6" gutterBottom>AI Generated Task Breakdown:</Typography>
                    <Typography sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>{aiTaskBreakdownResponse}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button 
                      onClick={handleCreateAiTaskBreakdown} 
                      variant="contained" 
                      color="success"
                    >
                      Add Project
                    </Button>
                    <Button 
                      onClick={() => {
                        setAiTaskBreakdownResponse('');
                        setAiTaskBreakdownPrompt(`Hey ChatGPT, I would like to break down this task into even smaller and more manageable tasks:
[${selectedTaskForBreakdown.title}]
${selectedTaskForBreakdown.description}`);
                      }} 
                      variant="outlined"
                      sx={{
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.text.primary,
                        '&:hover': {
                          borderColor: theme.palette.primary.main,
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      Start Over
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default TaskProjectsWidget;