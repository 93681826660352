import React from 'react';
import { Typography, Box } from '@mui/material';

const WeatherWidget = () => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>Weather</Typography>
      <Typography>Coming soon.</Typography>
    </Box>
  );
};

export default WeatherWidget;