import React from 'react';
import { Box, Typography, IconButton, List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function NotificationList({ onClose, notifications }) {
  return (
    <Box sx={{ 
      width: 450,
      height: 650,
      bgcolor: '#0b0c10',
      borderRadius: 3,
      boxShadow: 3, 
      overflow: 'hidden',
      border: '1px solid #1b1c22',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
        borderBottom: '1px solid #1b1c22',
      }}>
        <Typography variant="h6" sx={{ color: '#b5b7c8' }}>
          Notifications
        </Typography>
        <IconButton onClick={onClose} sx={{ color: '#b5b7c8' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List sx={{ 
        flexGrow: 1, 
        overflowY: 'auto', 
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#3a3b45',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#4a4b55',
        },
      }}>
        {notifications.length === 0 ? (
          <ListItem>
            <ListItemText primary="No notifications" sx={{ color: '#b5b7c8' }} />
          </ListItem>
        ) : (
          notifications.map((notification, index) => (
            <ListItem key={index} sx={{ 
              borderBottom: '1px solid #1b1c22',
              '&:last-child': { borderBottom: 'none' },
            }}>
              <ListItemText 
                primary={notification.title} 
                secondary={notification.message}
                sx={{ 
                  color: '#b5b7c8',
                  '& .MuiListItemText-secondary': { color: '#9a9aaa' },
                }}
              />
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
}

export default NotificationList;