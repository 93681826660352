import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, Box, Typography, IconButton, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const PromptSaverWidget = ({ userId }) => {
  const [savedPrompts, setSavedPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [title, setTitle] = useState('');
  const [promptText, setPromptText] = useState('');
  const [result, setResult] = useState('');
  const [variables, setVariables] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchSavedPrompts();
  }, [userId]);

  const fetchSavedPrompts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/prompts/${userId}`);
      const data = await response.json();
      setSavedPrompts(data);
    } catch (error) {
      console.error('Error fetching saved prompts:', error);
    }
  };

  const handlePromptChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPrompt(selectedValue);
    if (selectedValue === 'new') {
      clearForm();
    } else {
      const selected = savedPrompts.find(prompt => prompt._id === selectedValue);
      if (selected) {
        setTitle(selected.title);
        setPromptText(selected.promptText);
        extractVariables(selected.promptText);
      }
    }
  };

  const clearForm = () => {
    setTitle('');
    setPromptText('');
    setVariables({});
    setResult('');
  };

  const extractVariables = (text) => {
    const regex = /\[([^\]]+)\]/g;
    const matches = text.match(regex) || [];
    const vars = {};
    matches.forEach(match => {
      const varName = match.slice(1, -1);
      vars[varName] = '';
    });
    setVariables(vars);
  };

  const handleVariableChange = (varName, value) => {
    setVariables(prev => ({ ...prev, [varName]: value }));
  };

  const handleSavePrompt = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/prompts`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, title, promptText }),
      });
      if (response.ok) {
        clearForm();
        fetchSavedPrompts();
      }
    } catch (error) {
      console.error('Error saving prompt:', error);
    }
  };

  const handleDeletePrompt = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/prompts/${selectedPrompt}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        clearForm();
        setSelectedPrompt('');
        fetchSavedPrompts();
      }
    } catch (error) {
      console.error('Error deleting prompt:', error);
    }
  };

  const handleRunPrompt = async () => {
    setIsLoading(true);
    let finalPrompt = promptText;
    Object.entries(variables).forEach(([key, value]) => {
      finalPrompt = finalPrompt.replace(`[${key}]`, value);
    });

    try {
      console.log('Sending request to backend:', {
        messages: [
          { role: 'system', content: 'You are a helpful assistant.' },
          { role: 'user', content: finalPrompt }
        ]
      });
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/ai/openai-chat`, { 
        messages: [
          { role: 'system', content: 'You are a helpful assistant.' },
          { role: 'user', content: finalPrompt }
        ]
      }, {
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        }
      });
      setResult(response.data.result);
    } catch (error) {
      console.error('Error running prompt:', error);
      console.error('Error response:', error.response?.data);
      setResult(`An error occurred while processing your request: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Select 
          value={selectedPrompt} 
          onChange={handlePromptChange}
          sx={{ flexGrow: 1 }}
        >
          <MenuItem value="new">Create a new one</MenuItem>
          {savedPrompts.map(prompt => (
            <MenuItem key={prompt._id} value={prompt._id}>{prompt.title}</MenuItem>
          ))}
        </Select>
        {selectedPrompt && selectedPrompt !== 'new' && (
          <IconButton onClick={handleDeletePrompt} size="small">
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        label="Prompt"
        multiline
        rows={4}
        value={promptText}
        onChange={(e) => {
          setPromptText(e.target.value);
          extractVariables(e.target.value);
        }}
        sx={{ 
          '& .MuiInputBase-input': { 
            color: 'text.primary',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'text.primary',
            },
            '&:hover fieldset': {
              borderColor: 'text.primary',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
        }}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {Object.entries(variables).map(([key, value]) => (
          <TextField
            key={key}
            label={key}
            value={value}
            onChange={(e) => handleVariableChange(key, e.target.value)}
            size="small"
            sx={{ width: 'auto' }}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button 
          variant="contained" 
          onClick={handleRunPrompt}
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {isLoading ? 'Running...' : 'Run Prompt'}
        </Button>
        <Button variant="outlined" onClick={handleSavePrompt}>Save Prompt</Button>
      </Box>
      {result && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Result:</Typography>
          <Typography>{result}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default PromptSaverWidget;