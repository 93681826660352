import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Paper, Fade, Zoom } from '@mui/material';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const TicTacToeWidget = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [xIsNext, setXIsNext] = useState(true);
  const [winner, setWinner] = useState(null);
  const [winningLine, setWinningLine] = useState([]);
  const [scores, setScores] = useState({ X: 0, O: 0, Tie: 0 });
  const [audio] = useState(new Audio('/sounds/beep2.wav'));
  const [singlePlayer, setSinglePlayer] = useState(false);
  const [aiStarts, setAiStarts] = useState(true);
  const [aiTurn, setAiTurn] = useState(false);

  useEffect(() => {
    if (singlePlayer && aiTurn && !winner) {
      const timer = setTimeout(() => makeAIMove(), 500);
      return () => clearTimeout(timer);
    }
  }, [singlePlayer, aiTurn, winner]);

  useEffect(() => {
    if (singlePlayer && aiStarts && board.every(square => square === null)) {
      makeAIMove();
    }
  }, [singlePlayer, aiStarts]);

  const calculateWinner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return { winner: squares[a], line: lines[i] };
      }
    }
    return null;
  };

  const handleClick = (i) => {
    if (winner || board[i] || (singlePlayer && aiTurn)) return;
    const newBoard = board.slice();
    newBoard[i] = xIsNext ? 'X' : 'O';
    setBoard(newBoard);
    setXIsNext(!xIsNext);
    audio.play();

    checkGameState(newBoard);

    if (singlePlayer && !winner) {
      setAiTurn(true);
    }
  };

  const checkGameState = (board) => {
    const result = calculateWinner(board);
    if (result) {
      setWinner(result.winner);
      setWinningLine(result.line);
      setScores(prev => ({ ...prev, [result.winner]: prev[result.winner] + 1 }));
      setAiStarts(!aiStarts);
    } else if (board.every(square => square !== null)) {
      setWinner('Tie');
      setScores(prev => ({ ...prev, Tie: prev.Tie + 1 }));
      setAiStarts(!aiStarts);
    }
  };

  const makeAIMove = () => {
    const availableMoves = board.reduce((acc, cell, index) => {
      if (cell === null) acc.push(index);
      return acc;
    }, []);

    if (availableMoves.length > 0) {
      const randomMove = availableMoves[Math.floor(Math.random() * availableMoves.length)];
      const newBoard = board.slice();
      newBoard[randomMove] = 'O';
      setBoard(newBoard);
      setXIsNext(true);
      audio.play();
      checkGameState(newBoard);
    }
    setAiTurn(false);
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setXIsNext(true);
    setWinner(null);
    setWinningLine([]);
    setAiTurn(singlePlayer && aiStarts);
  };

  const restartGame = () => {
    resetGame();
    setScores({ X: 0, O: 0, Tie: 0 });
    setAiStarts(true);
  };

  const toggleGameMode = () => {
    setSinglePlayer(!singlePlayer);
    restartGame();
  };

  const renderSquare = (i) => {
    const isWinningSquare = winningLine.includes(i);
    return (
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: 56,
            height: 56,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: isWinningSquare ? '#4caf50' : 'inherit',
          }}
          onClick={() => handleClick(i)}
        >
          <Zoom in={board[i] !== null} style={{ transitionDelay: board[i] ? '100ms' : '0ms' }}>
            <Box>
              {board[i] === 'X' && <CloseIcon sx={{ fontSize: 42, color: '#f44336' }} />}
              {board[i] === 'O' && <RadioButtonUncheckedIcon sx={{ fontSize: 35, color: '#2196f3' }} />}
            </Box>
          </Zoom>
        </Paper>
      </motion.div>
    );
  };

  return (
    <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2 }}>
        <Typography variant="body2">
          Player (X): {scores.X}
        </Typography>
        <Typography variant="body2">
          Tie: {scores.Tie}
        </Typography>
        <Typography variant="body2">
          {singlePlayer ? "AI" : "Player"} (O): {scores.O}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        {[0, 1, 2].map(row => (
          <Grid container item key={row} justifyContent="center" spacing={1}>
            {[0, 1, 2].map(col => (
              <Grid item key={col}>
                {renderSquare(row * 3 + col)}
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Fade in={winner !== null || (singlePlayer && aiTurn)}>
          <Typography variant="body1" color="primary">
            {winner === 'Tie' ? "It's a tie!" : 
             winner ? `Winner: ${winner === 'X' ? 'Player' : (singlePlayer ? 'AI' : 'Player O')}` : 
             (singlePlayer && aiTurn) ? "AI is thinking..." :
             `Next player: ${xIsNext ? 'X' : 'O'}`}
          </Typography>
        </Fade>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button variant="contained" onClick={resetGame}>
          New Game
        </Button>
        <Button variant="outlined" onClick={restartGame}>
          Restart
        </Button>
      </Box>
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Button variant="outlined" onClick={toggleGameMode}>
          {singlePlayer ? "Switch to 2 Players" : "Switch to 1 Player"}
        </Button>
      </Box>
    </Box>
  );
};

export default TicTacToeWidget;