import React, { useState, useMemo } from 'react';
import { Typography, Box, Button, TextField, Select, MenuItem, FormControl, InputLabel, Tooltip, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { QRCodeSVG } from 'qrcode.react';
import { useTheme } from '@mui/material/styles';

const QRCodeWidget = () => {
  const [qrType, setQRType] = useState('link');
  const [qrData, setQRData] = useState('');
  const [ssid, setSsid] = useState('');
  const [password, setPassword] = useState('');
  const [encryption, setEncryption] = useState('WPA');
  const theme = useTheme();

  const qrTypes = useMemo(() => [
    { value: 'link', label: 'Link/URL', tooltip: 'Example: Insert a link starting with http:// or https://' },
    { value: 'text', label: 'Text', tooltip: 'Plain text for ex: Instructions, a quote, a coupon code.' },
    { value: 'email', label: 'E-mail', tooltip: 'Example: mailto:example@example.com?subject=Hello&body=How are you?' },
    { value: 'phone', label: 'Phone Number (SMS)', tooltip: 'Example: tel:+1234567890" for calling, "sms:+1234567890?body=Hello" for texting.' },
    { value: 'wifi', label: 'WiFi', tooltip: 'Automatically connects to the specified WiFi network.' },
  ], []);

  const handleQRTypeChange = (event) => {
    setQRType(event.target.value);
    setQRData('');
    setSsid('');
    setPassword('');
    setEncryption('WPA');
  };

  const generateQRCode = () => {
    if (qrType === 'wifi') {
      return `WIFI:T:${encryption};S:${ssid};P:${password};;`;
    }
    return qrData;
  };

  const downloadQRCode = (printQuality = false) => {
    const svg = document.getElementById('qr-code');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      const scale = printQuality ? 2 : 1;
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;
      ctx.scale(scale, scale);
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = printQuality ? 'qrcode_print.png' : 'qrcode.png';
      downloadLink.href = pngFile;
      downloadLink.click();
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
  };

  return (
    <Box sx={{ 
      textAlign: 'center', 
      p: 1, 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      background: theme.palette.mode === 'light' 
        ? 'linear-gradient(135deg, #f5f7fa 0%, #e4e8f0 100%)' 
        : 'inherit',
    }}>
      <Typography variant="body2" sx={{ mb: 1, fontWeight: 'bold' }}>
        QR Code Generator
      </Typography>

      <FormControl fullWidth size="small" sx={{ mb: 1 }}>
        <InputLabel id="qr-type-label">QR Code Type</InputLabel>
        <Select
          labelId="qr-type-label"
          value={qrType}
          onChange={handleQRTypeChange}
          label="QR Code Type"
        >
          {qrTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2">{type.label}</Typography>
                <Tooltip title={type.tooltip} placement="right">
                  <IconButton size="small" sx={{ ml: 0.5 }}>
                    <HelpOutlineIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {qrType === 'wifi' ? (
        <>
          <TextField
            fullWidth
            size="small"
            label="SSID"
            value={ssid}
            onChange={(e) => setSsid(e.target.value)}
            sx={{ mb: 1 }}
          />
          <TextField
            fullWidth
            size="small"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 1 }}
          />
          <FormControl fullWidth size="small" sx={{ mb: 1 }}>
            <InputLabel>Encryption</InputLabel>
            <Select
              value={encryption}
              onChange={(e) => setEncryption(e.target.value)}
              label="Encryption"
            >
              <MenuItem value="WEP">WEP</MenuItem>
              <MenuItem value="WPA">WPA/WPA2</MenuItem>
              <MenuItem value="nopass">No Password</MenuItem>
            </Select>
          </FormControl>
        </>
      ) : (
        <TextField
          fullWidth
          size="small"
          label={qrType.charAt(0).toUpperCase() + qrType.slice(1)}
          value={qrData}
          onChange={(e) => setQRData(e.target.value)}
          sx={{ mb: 1 }}
        />
      )}

      <Box sx={{ mb: 1, display: 'flex', justifyContent: 'center' }}>
        <QRCodeSVG
          id="qr-code"
          value={generateQRCode()}
          size={120}
          level="H"
          includeMargin={true}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          onClick={() => downloadQRCode(false)}
          disabled={!qrData && qrType !== 'wifi'}
          size="small"
          sx={{ flex: 1, mr: 0.5 }}
        >
          Download (PNG)
        </Button>
        <Button
          variant="outlined"
          onClick={() => downloadQRCode(true)}
          disabled={!qrData && qrType !== 'wifi'}
          size="small"
          sx={{ flex: 1, ml: 0.5 }}
        >
          Download (Print Quality)
        </Button>
      </Box>
    </Box>
  );
};

export default QRCodeWidget;