import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, Button, TextField, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Collapse, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HtmlIcon from '@mui/icons-material/Html';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import axios from 'axios';
import { isElement } from 'lodash';

const FileTreeItem = ({ file, depth = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleToggle}
        sx={{ pl: depth * 2 }}
      >
        <ListItemIcon>
          {file.isDirectory ? (
            isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />
          ) : file.name.endsWith('.html') ? (
            <HtmlIcon />
          ) : (
            <InsertDriveFileIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary={file.name}
          secondary={!file.isDirectory && `${(file.size / 1024).toFixed(2)} KB`}
          primaryTypographyProps={{ variant: 'body2' }}
          secondaryTypographyProps={{ variant: 'caption' }}
        />
      </ListItem>
      {file.isDirectory && file.children && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {file.children.map((childFile, index) => (
              <FileTreeItem key={index} file={childFile} depth={depth + 1} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const SitesWidget = ({ userId, userEmail }) => {
  const [files, setFiles] = useState([]);
  const [siteName, setSiteName] = useState('');
  const [publishedUrl, setPublishedUrl] = useState('');
  const [error, setError] = useState('');
  const [siteNameError, setSiteNameError] = useState('');
  const [publishedSites, setPublishedSites] = useState([]);
  const [maxSites, setMaxSites] = useState(3);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [showFileTree, setShowFileTree] = useState(false);

  useEffect(() => {
    fetchPublishedSites();
    fetchUserLimits();
  }, [userEmail]);

  const fetchPublishedSites = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sites/${userEmail}`);
      setPublishedSites(response.data);
    } catch (error) {
      console.error('Error fetching published sites:', error);
    }
  };

  const fetchUserLimits = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userEmail}/limits`);
      setMaxSites(response.data.maxSites || 3);
    } catch (error) {
      console.error('Error fetching user limits:', error);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles[0].size > 10 * 1024 * 1024) {
      setError('File size exceeds 10MB limit');
      return;
    }

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    formData.append('userEmail', userEmail);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sites/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setFiles(response.data.files || []);
      setError('');
      setShowFileTree(true);
      setIsPublished(false);
    } catch (error) {
      console.error('Error uploading file:', error.response?.data || error);
      setError(`Error uploading file: ${error.response?.data?.message || error.message}`);
      if (error.response?.data?.error) {
        console.error('Detailed error:', error.response.data.error);
        console.error('Stack trace:', error.response.data.stack);
      }
    }
  }, [userEmail]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handlePublish = async () => {
    if (!siteName) {
      setSiteNameError('Please enter a site name');
      return;
    }

    if (!/^[a-zA-Z0-9-]+$/.test(siteName)) {
      setSiteNameError('Site name can only contain letters, numbers, and hyphens');
      return;
    }

    if (publishedSites.length >= maxSites) {
      setIsLimitReached(true);
      return;
    }

    // Check if the site name already exists
    if (publishedSites.some(site => site.siteName === siteName)) {
      setSiteNameError('This site name is already in use. Please choose a different name.');
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sites/publish`, {
        userEmail,
        siteName,
      });
      setPublishedUrl(response.data.url);
      setError('');
      setSiteNameError('');
      fetchPublishedSites();
      setIsPublished(true);
      setShowFileTree(false);
      setSiteName('');
    } catch (error) {
      console.error('Error publishing site:', error);
      setError('Error publishing site. Please try again.');
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(publishedUrl);
  };

  const handleCloseLimitDialog = () => {
    setIsLimitReached(false);
  };

  const handleDeleteSite = async (siteName) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/sites/${userEmail}/${siteName}`);
      console.log('Delete response:', response.data);
      fetchPublishedSites();
    } catch (error) {
      console.error('Error deleting site:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(`Error deleting site: ${error.response.data.message}`);
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('Error deleting site: No response received from server');
      } else {
        setError(`Error deleting site: ${error.message}`);
      }
    }
  };

  const handleCopySiteUrl = (url) => {
    navigator.clipboard.writeText(url);
  };

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Drop your files and websites online!</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle1">Yes, it's this easy!</Typography>
        <Tooltip title="Upload a zip file containing your website files, choose a unique name, and publish. Your site will be live instantly!" arrow>
          <IconButton size="small">
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      {!showFileTree && (
        <Paper
          {...getRootProps()}
          sx={{
            p: 3,
            mb: 2,
            width: '100%',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: isDragActive ? 'action.hover' : 'background.paper',
            border: '2px dashed',
            borderColor: isDragActive ? 'primary.main' : 'divider',
          }}
        >
          <input {...getInputProps()} />
          <HtmlIcon sx={{ fontSize: 48, mb: 1, color: 'primary.main' }} />
          <FolderIcon sx={{ fontSize: 48, mb: 1, color: 'secondary.main' }} />
          <Typography>
            {isDragActive ? 'Drop the .zip file here' : 'Drag and drop your .zip file here (max 10MB)'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            or click to select a file
          </Typography>
        </Paper>
      )}

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {showFileTree && (
        <>
          <Paper sx={{ p: 2, mb: 2, width: '100%' }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>Uploaded Files:</Typography>
            <List dense>
              {files.map((file, index) => (
                <FileTreeItem key={index} file={file} />
              ))}
            </List>
          </Paper>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
            <Typography sx={{ mr: 1 }}>dashflow.app/sites/</Typography>
            <TextField
              size="small"
              placeholder="Enter site name (letters, numbers, hyphens only)"
              value={siteName}
              onChange={(e) => {
                setSiteName(e.target.value);
                setSiteNameError('');
              }}
              sx={{ flexGrow: 1, mr: 1 }}
              error={!!siteNameError}
              helperText={siteNameError}
            />
            <Button
              variant="contained"
              onClick={handlePublish}
            >
              Publish {publishedSites.length + 1}/{maxSites}
            </Button>
          </Box>
        </>
      )}

      {publishedUrl && (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            value={publishedUrl}
            InputProps={{ readOnly: true }}
          />
          <IconButton onClick={handleCopyUrl} sx={{ ml: 1 }}>
            <ContentCopyIcon />
          </IconButton>
        </Box>
      )}

      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Your Published Sites</Typography>
      {publishedSites.length === 0 ? (
        <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
          No sites hosted yet
        </Typography>
      ) : (
        <List sx={{ width: '100%' }}>
          {publishedSites.map((site, index) => (
            <ListItem key={index} sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <ListItemText primary={site.siteName} secondary={site.url} />
              <IconButton onClick={() => window.open(site.url, '_blank')}>
                <VisibilityIcon />
              </IconButton>
              <IconButton onClick={() => handleCopySiteUrl(site.url)}>
                <ContentCopyIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteSite(site.siteName)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}

      <Dialog open={isLimitReached} onClose={handleCloseLimitDialog}>
        <DialogTitle>Site Limit Reached</DialogTitle>
        <DialogContent>
          <Typography>
            You've reached your limit of {maxSites} sites. You need to upgrade your account or delete one of the sites below.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLimitDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SitesWidget;