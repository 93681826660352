import axios from 'axios';

export const saveSpotifyToken = async (userId, token, expiresIn) => {
  const expiryTime = new Date().getTime() + expiresIn * 1000;
  try {
    await axios.post(
      "https://dashflow.onrender.com/api/spotify/save-token",
      {
        userId,
        token,
        expiryTime
      }
    );
  } catch (error) {
    console.error('Error saving Spotify token:', error);
  }
};

export const getStoredSpotifyToken = async (userId) => {
  try {
    const response = await axios.get(
      `https://dashflow.onrender.com/api/spotify/get-token/${userId}`
    );
    // ... rest of the function
  } catch (error) {
    console.error('Error getting Spotify token:', error);
  }
  return null;
};

export const removeSpotifyToken = async (userId) => {
  try {
    await axios.delete(
      `https://dashflow.onrender.com/api/spotify/remove-token/${userId}`
    );
  } catch (error) {
    console.error('Error removing Spotify token:', error);
  }
};