import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Avatar, IconButton, Tooltip, Link, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import axios from 'axios';

const StartupElevatorWidget = ({ userId }) => {
  const [startups, setStartups] = useState([]);
  const [userStartup, setUserStartup] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [formData, setFormData] = useState({
    logo: '',
    name: '',
    website: '',
    description: '',
    shortDescription: ''
  });
  const [voterData, setVoterData] = useState({});

  useEffect(() => {
    const fetchStartups = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/startups`);
        setStartups(response.data);
        const userStartup = response.data.find(startup => startup.userId === userId);
        setUserStartup(userStartup);
      } catch (error) {
        console.error('Error fetching startups:', error);
      }
    };
    fetchStartups();
  }, [userId]);

  const fetchVoterData = async (voterId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${voterId}`);
      setVoterData(prev => ({ ...prev, [voterId]: response.data }));
    } catch (error) {
      console.error('Error fetching voter data:', error);
    }
  };

  useEffect(() => {
    startups.forEach(startup => {
      startup.voters.forEach(voterId => {
        if (!voterData[voterId]) {
          fetchVoterData(voterId);
        }
      });
    });
  }, [startups]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleAddStartup = async () => {
    try {
      if (userStartup) {
        const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/startups/${userId}`, { ...formData });
        setUserStartup(response.data);
        setStartups(prev => prev.map(startup => startup.userId === userId ? response.data : startup));
      } else {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/startups`, { ...formData, userId });
        setUserStartup(response.data);
        setStartups(prev => [response.data, ...prev]);
      }
      setShowForm(false);
    } catch (error) {
      console.error('Error adding startup:', error);
    }
  };

  const handleEditStartup = () => {
    setFormData({
      logo: userStartup.logo,
      name: userStartup.name,
      website: userStartup.website,
      description: userStartup.description,
      shortDescription: userStartup.shortDescription
    });
    setShowForm(true);
  };

  const handleDeleteStartup = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/startups/${userId}`);
      setUserStartup(null);
      setStartups(prev => prev.filter(startup => startup.userId !== userId));
    } catch (error) {
      console.error('Error deleting startup:', error);
    }
  };

  const handleUpvote = async (startupId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/startups/upvote`, { startupId, userId });
      setStartups(prev => prev.map(startup => startup._id === startupId ? response.data : startup));
    } catch (error) {
      console.error('Error upvoting startup:', error);
    }
  };

  const handleShowDetail = (startup) => {
    setSelectedStartup(startup);
    setShowDetail(true);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const hasUserVoted = (startup) => startup.voters.includes(userId);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, pb: 0 }}>
        {userStartup && (
          <>
            <Tooltip title="Edit Startup">
              <IconButton size="small" onClick={handleEditStartup}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Startup">
              <IconButton size="small" onClick={handleDeleteStartup}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Tooltip title="Add Startup">
          <IconButton size="small" onClick={() => setShowForm(true)}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ flexGrow: 1, p: 2, overflow: 'auto' }}>
        {showForm ? (
          <Box>
            <TextField
              fullWidth
              label="Logo URL"
              name="logo"
              value={formData.logo}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Startup Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Website"
              name="website"
              value={formData.website}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Short Description"
              name="shortDescription"
              value={formData.shortDescription}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" onClick={handleAddStartup}>Save</Button>
          </Box>
        ) : showDetail && selectedStartup ? (
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar src={selectedStartup.logo} alt={selectedStartup.name} sx={{ mr: 2, width: 56, height: 56 }} />
              <Typography variant="h4">{selectedStartup.name}</Typography>
            </Box>
            <Typography variant="body1" sx={{ mb: 2 }}>{selectedStartup.shortDescription}</Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>{selectedStartup.description}</Typography>
            <Typography variant="h6">Comments</Typography>
            <Box sx={{ mt: 2 }}>
              {/* Placeholder for comments section */}
              <Typography variant="body2">Comments section coming soon...</Typography>
            </Box>
            <Button variant="contained" onClick={() => setShowDetail(false)}>Back</Button>
          </Box>
        ) : (
          <Box>
            {startups.map((startup, index) => (
              <Box key={startup._id} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {index < 3 && (
                  <WorkspacePremiumIcon
                    sx={{
                      color: index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze',
                      mr: 1
                    }}
                  />
                )}
                <Avatar src={startup.logo} alt={startup.name} sx={{ mr: 2 }} />
                <Link
                  component="button"
                  onClick={() => handleShowDetail(startup)}
                  sx={{ color: '#0056b3', textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  <Typography variant="body1">{index + 1}. {startup.name}</Typography>
                </Link>
                <Typography variant="body2" sx={{ ml: 1 }}>{truncateText(startup.shortDescription, 40)}</Typography>
                <IconButton
                  onClick={() => handleUpvote(startup._id)}
                  sx={{ ml: 'auto', color: hasUserVoted(startup) ? 'gray' : 'inherit' }}
                  disabled={hasUserVoted(startup)}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <Typography variant="body2" sx={{ ml: 1 }}>{startup.upvotes}</Typography>
                <Box sx={{ display: 'flex', ml: 2 }}>
                  {startup.voters.slice(-3).map((voterId, i) => (
                    <Tooltip key={i} title={voterData[voterId]?.name || 'Loading...'}>
                      <Avatar
                        src={voterData[voterId]?.picture || `https://api.adorable.io/avatars/40/${voterId}.png`}
                        sx={{ width: 24, height: 24, ml: i > 0 ? -1 : 0 }}
                      />
                    </Tooltip>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StartupElevatorWidget;