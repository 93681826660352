import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Paper, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import 'react-resizable/css/styles.css';

const Widget = React.memo(({ id, onClose, children, initialPos, initialSize, zIndex, onPositionChange, onSizeChange, bringToFront }) => {
  const [position, setPosition] = useState(initialPos);
  const [size, setSize] = useState(initialSize);
  const nodeRef = useRef(null);
  const theme = useTheme();

  const handleDrag = useCallback((e, ui) => {
    const { x, y } = ui;
    setPosition({ x, y });
  }, []);

  const formatWidgetTitle = (id) => {
    // Special case for 'sites' to keep 'Easy Hosting'
    if (id === 'sites') return 'Easy Hosting';
    
    // Insert a space before all caps except the first character
    return id.replace(/([A-Z])/g, ' $1').trim()
      // Capitalize the first letter
      .replace(/^./, str => str.toUpperCase());
  };

  const handleDragStop = useCallback((e, ui) => {
    const { x, y } = ui;
    setPosition({ x, y });
    onPositionChange(id, { x, y });
    bringToFront(id);
  }, [id, onPositionChange, bringToFront]);

  const handleResize = useCallback((e, direction, ref, d) => {
    const newSize = {
      width: Math.max(200, size.width + d.width),
      height: Math.max(150, size.height + d.height)
    };
    setSize(newSize);
  }, [size]);

  const handleResizeStop = useCallback((e, direction, ref, delta) => {
    const newSize = {
      width: Math.max(200, Math.min(1000, ref.offsetWidth)),
      height: Math.max(150, Math.min(800, ref.offsetHeight))
    };
    setSize(newSize);
    onSizeChange(id, newSize);
  }, [id, onSizeChange]);

  const scrollbarStyles = useMemo(() => ({
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#3a7bd5' : '#4c7baf',
      borderRadius: '3px',
      transition: 'background 0.2s ease',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.mode === 'light' ? '#2d62aa' : '#3a6491',
    },
    '&::-webkit-scrollbar-corner': {
      background: 'transparent',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.mode === 'light' ? '#3a7bd5' : '#4c7baf'} transparent`,
  }), [theme.palette.mode]);

  return (
    <Draggable
      handle=".widget-header"
      position={position}
      onDrag={handleDrag}
      onStop={handleDragStop}
      bounds="parent"
      nodeRef={nodeRef}
    >
      <div ref={nodeRef} style={{ position: 'absolute', zIndex }} onClick={() => bringToFront(id)}>
        <Resizable
          size={size}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          minWidth={200}
          minHeight={150}
          maxWidth={1000}
          maxHeight={800}
          enable={{
            top: false,
            right: true,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: true,
            bottomLeft: false,
            topLeft: false,
          }}
        >
          <Paper
            elevation={3}
            className="widget"
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#111217',
              color: theme.palette.mode === 'light' ? '#2c3e50' : '#ffffff',
              borderRadius: '12px',
              overflow: 'hidden',
              border: 1,
              borderColor: theme.palette.mode === 'light' ? '#ecf0f1' : '#1b1c22',
              display: 'flex',
              flexDirection: 'column',
              boxShadow: theme.palette.mode === 'light' 
                ? '0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)' 
                : '0 4px 6px rgba(0,0,0,0.3)',
              transition: 'all 0.3s ease',
            }}
          >
            <div className="widget-header" style={{ 
              cursor: 'move', 
              padding: '12px 16px', 
              borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#ecf0f1' : '#1b1c22'}`,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: theme.palette.mode === 'light' ? '#2c3e50' : '#ffffff',
              background: theme.palette.mode === 'light' 
                ? 'linear-gradient(to right, #f5f7fa, #e4e8f0)' 
                : '#111217',
            }}>
              <span>{formatWidgetTitle(id)}</span>
              <IconButton
                size="small"
                onClick={() => onClose(id)}
                sx={{
                  color: theme.palette.mode === 'light' ? '#7f8c8d' : '#bdc3c7',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'light' 
                      ? 'rgba(0, 0, 0, 0.04)' 
                      : 'rgba(255, 255, 255, 0.08)',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="widget-content" style={{ 
              padding: '16px', 
              flexGrow: 1, 
              overflow: 'auto',
              color: theme.palette.mode === 'light' ? '#34495e' : '#9a9aaa',
              backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#111217',
              ...scrollbarStyles,
            }}>
              {children}
            </div>
          </Paper>
        </Resizable>
      </div>
    </Draggable>
  );
});

export default Widget;