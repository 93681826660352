import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography, Modal, IconButton } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

const BackgroundRemoverWidget = () => {
  const [file, setFile] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setResultImage(null);
    setError(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleRemoveBackground = async () => {
    if (!file) {
      setError('Please select an image first.');
      return;
    }

    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/ai/remove-background`, formData, {  
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setResultImage(response.data.result);
    } catch (error) {
      console.error('Error removing background:', error);
      setError('An error occurred while removing the background. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    if (resultImage) {
      window.open(resultImage, '_blank');
    }
  };

  const handleImageClick = (image) => {
    setModalImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleStartOver = () => {
    setFile(null);
    setResultImage(null);
    setError(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {!file && (
        <Box
          {...getRootProps()}
          sx={{
            border: '2px dashed #3f51b5',
            borderRadius: 2,
            p: 2,
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'all 0.3s',
            '&:hover': {
              backgroundColor: 'rgba(63, 81, 181, 0.04)',
            },
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>Drop the image here ...</Typography>
          ) : (
            <Box>
              <CloudUploadIcon sx={{ fontSize: 48, color: '#3f51b5', mb: 1 }} />
              <Typography>Drag 'n' drop an image here, or click to select one</Typography>
            </Box>
          )}
        </Box>
      )}
      {error && <Typography color="error">{error}</Typography>}
      {file && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Box sx={{ width: '48%' }}>
            <Typography variant="h6" gutterBottom>Original:</Typography>
            <Box
              component="img"
              src={URL.createObjectURL(file)}
              alt="Original"
              sx={{ width: '100%', height: 200, objectFit: 'contain', cursor: 'pointer' }}
              onClick={() => handleImageClick(URL.createObjectURL(file))}
            />
          </Box>
          <Box sx={{ width: '48%' }}>
            <Typography variant="h6" gutterBottom>
              {resultImage ? 'Background Removed:' : 'Ready to process'}
            </Typography>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                <CircularProgress />
              </Box>
            ) : resultImage ? (
              <Box
                component="img"
                src={resultImage}
                alt="Background Removed"
                sx={{ width: '100%', height: 200, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => handleImageClick(resultImage)}
              />
            ) : (
              <Box sx={{ width: '100%', height: 200, bgcolor: 'rgba(0, 0, 0, 0.1)', borderRadius: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleRemoveBackground}
                  disabled={isLoading}
                >
                  Remove Background
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {file && (
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
          <Button variant="outlined" onClick={handleStartOver}>
            Start Over
          </Button>
          {resultImage && (
            <Button variant="contained" onClick={handleDownload} startIcon={<DownloadIcon />}>
              Download Result
            </Button>
          )}
        </Box>
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="image-modal"
        aria-describedby="full-size-image"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflow: 'auto',
        }}>
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: 'absolute', right: 8, top: 8 }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <img src={modalImage} alt="Full size" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </Box>
      </Modal>
    </Box>
  );
};

export default BackgroundRemoverWidget;