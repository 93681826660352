export const recommendedSizes = {
  spotify: { width: 615, height: 230 },
  news: { width: 300, height: 205 },
  pomodoro: { width: 375, height: 404 },
  weather: { width: 300, height: 300 },
  tasks: { width: 300, height: 400 },
  qrcode: { width: 411, height: 422 },
  notepad: { width: 660, height: 600 },
  startupElevator: { width: 651, height: 325 },
  urlShortener: { width: 445, height: 422 },
  tictactoe: { width: 296, height: 486 },
  socialMediaBanner: { width: 820, height: 722 },
  emojiZone: { width: 391, height: 530 },
  colorPicker: { width: 453, height: 543 },
  tokenizer: { width: 500, height: 600 },
  encryption: { width: 337, height: 627 },
  random: { width: 352, height: 532 },
  recorder: { width: 342, height: 442 },
  speechToText: { width: 300, height: 450 },
  sites: { width: 500, height: 600 },
  meditation: { width: 338, height: 447 }, // Make sure this line is present
  userReview: { width: 400, height: 600 },
  books: { width: 856, height: 554 },
  promptRefiner: { width: 500, height: 757 },
  imageGenerator: { width: 580, height: 800 },
  calculator: { width: 655, height: 605 },
  aiTranslator: { width: 512, height: 650 }, // Increased height to accommodate larger result field
  cursorPromptGenerator: { width: 620, height: 780 },
  taskProjects: { width: 1000, height: 800 },
  promptSaver: { width: 884, height: 745 },
  backgroundRemover: { width: 570, height: 440 },
  // Add any missing widgets here with their recommended sizes
};