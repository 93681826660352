import React, { useState, useRef, useEffect } from 'react';
import { Button, Select, MenuItem, Box, Typography, IconButton } from '@mui/material';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const socialMediaSizes = {
  facebook: { width: 820, height: 312, label: 'Facebook Cover' },
  twitter: { width: 1500, height: 500, label: 'Twitter Banner' },
  linkedin: { width: 1584, height: 396, label: 'LinkedIn Banner' },
  youtube: { width: 2560, height: 1440, label: 'YouTube Channel Art' },
  tiktok: { width: 200, height: 200, label: 'TikTok Profile Picture' },
};

const SocialMediaBanner = () => {
  const [image, setImage] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState('facebook');
  const cropperRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.error('Invalid file type. Please upload an image.');
    }
  };

  const handleUrlUpload = () => {
    const url = prompt('Enter image URL:');
    if (url) {
      setImage(url);
    }
  };

  const handlePlatformChange = (event) => {
    setSelectedPlatform(event.target.value);
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const { width, height } = socialMediaSizes[event.target.value];
      cropper.setAspectRatio(width / height);
      cropper.crop();
    }
  };

  const handleSaveImage = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const { width: targetWidth, height: targetHeight } = socialMediaSizes[selectedPlatform];
      const canvas = cropper.getCroppedCanvas({
        width: targetWidth,
        height: targetHeight,
      });

      canvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${selectedPlatform}_banner.png`;
          link.click();
          URL.revokeObjectURL(url);
        } else {
          console.error('Failed to create blob from canvas');
        }
      }, 'image/png');
    } else {
      console.error('Cropper reference not available');
    }
  };

  useEffect(() => {
    if (image && cropperRef.current) {
      const cropper = new Cropper(cropperRef.current, {
        aspectRatio: socialMediaSizes[selectedPlatform].width / socialMediaSizes[selectedPlatform].height,
        viewMode: 1,
        autoCropArea: 1,
        responsive: true,
        zoomable: true,
        scalable: true,
        movable: true,
        cropBoxResizable: false,
        cropBoxMovable: false,
        background: false,
        guides: false,
        center: true,
        highlight: false,
        dragMode: 'move',
        ready() {
          const containerData = this.cropper.getContainerData();
          const cropBoxData = this.cropper.getCropBoxData();
          const imageData = this.cropper.getImageData();
          this.cropper.setCropBoxData({
            left: (containerData.width - cropBoxData.width) / 2,
            top: (containerData.height - cropBoxData.height) / 2,
          });
          this.cropper.setCanvasData({
            left: (containerData.width - imageData.naturalWidth) / 2,
            top: (containerData.height - imageData.naturalHeight) / 2,
            width: imageData.naturalWidth,
            height: imageData.naturalHeight,
          });
        },
        cropBoxData: {
          strokeWidth: 4, // Increase the crop area stroke width
        },
      });

      return () => {
        cropper.destroy();
      };
    }
  }, [image, selectedPlatform]);

  const handleZoomIn = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(0.1);
    }
  };

  const handleZoomOut = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(-0.1);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h6">Social Media Banner</Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button variant="contained" component="label">
          Upload File
          <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
        </Button>
        <Button variant="contained" onClick={handleUrlUpload}>
          Upload from URL
        </Button>
      </Box>
      {image && (
        <>
          <Select value={selectedPlatform} onChange={handlePlatformChange}>
            {Object.entries(socialMediaSizes).map(([key, value]) => (
              <MenuItem key={key} value={key}>{value.label}</MenuItem>
            ))}
          </Select>
          <Box 
            sx={{ 
              position: 'relative', 
              width: '100%', 
              height: '400px', 
              overflow: 'hidden',
            }}
          >
            <img 
              ref={cropperRef} 
              src={image} 
              alt="Uploaded" 
              style={{
                width: 'auto',
                height: '100%',
              }}
            />
            <Box sx={{ position: 'absolute', top: 8, right: 8, display: 'flex', gap: 1 }}>
              <IconButton onClick={handleZoomOut}>
                <ZoomOutIcon />
              </IconButton>
              <IconButton onClick={handleZoomIn}>
                <ZoomInIcon />
              </IconButton>
            </Box>
          </Box>
          <Button variant="contained" onClick={handleSaveImage}>
            Save Image
          </Button>
        </>
      )}
    </Box>
  );
};

export default SocialMediaBanner;