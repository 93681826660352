import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Grid, Paper, Typography, useTheme, } from '@mui/material';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { HTML5Backend, } from 'react-dnd-html5-backend';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BackspaceIcon from '@mui/icons-material/Backspace';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';

import { motion, AnimatePresence } from 'framer-motion';

const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const DraggableResult = ({ result, expression, preResult, onDragStart, isAnimating }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'result',
    item: () => ({ result, preResult }),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [result, preResult]);

  const theme = useTheme();

  useEffect(() => {
    const preventDefault = (e) => {
      if (e.shiftKey || e.ctrlKey || e.altKey) {
        e.preventDefault();
      }
    };

    const element = drag.current;
    if (element) {
      element.addEventListener('mousedown', preventDefault);
      element.addEventListener('mousemove', preventDefault);
    }

    return () => {
      if (element) {
        element.removeEventListener('mousedown', preventDefault);
        element.removeEventListener('mousemove', preventDefault);
      }
    };
  }, [drag]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
      <motion.div layout>
        <Typography variant="h4" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
          {expression.split(' ').map((part, index) => (
            <span
              key={index}
              ref={drag}
              style={{
                color: ['+', '-', '*', '/'].includes(part) ? theme.palette.primary.main : 'inherit',
                cursor: 'move',
                opacity: isDragging ? 0.5 : 1,
              }}
              onMouseDown={onDragStart}
            >
              {isNaN(part) ? part : formatNumber(part)}{' '}
            </span>
          ))}
        </Typography>
      </motion.div>
      <AnimatePresence>
        {preResult && !isAnimating && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            <Typography
              variant="h6"
              ref={drag}
              sx={{
                textAlign: 'right',
                fontSize: '1em',
                color: theme.palette.text.secondary,
                cursor: 'move',
                opacity: isDragging ? 0.5 : 1,
              }}
              onMouseDown={onDragStart}
            >
              {formatNumber(preResult)}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

const ResultSlot = ({ index, onDrop, result }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'result',
    canDrop: (item) => item.index !== index,
    drop: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      const { shiftKey, ctrlKey, altKey } = window.event || {};
      return onDrop(item, index, { shiftKey, ctrlKey, altKey, ...dropResult });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }), [index, onDrop]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'result',
    item: { result, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [result, index]);

  useEffect(() => {
    const preventDefault = (e) => {
      if (e.shiftKey || e.ctrlKey || e.altKey) {
        e.preventDefault();
      }
    };

    const element = drop.current;
    if (element) {
      element.addEventListener('mousedown', preventDefault);
      element.addEventListener('mousemove', preventDefault);
    }

    return () => {
      if (element) {
        element.removeEventListener('mousedown', preventDefault);
        element.removeEventListener('mousemove', preventDefault);
      }
    };
  }, [drop]);

  const handleClick = () => {
    if (result) {
      navigator.clipboard.writeText(result).then(() => {
        console.log('Copied to clipboard');
      });
    }
  };

  return (
    <Box
      ref={drop}
      onClick={handleClick}
      sx={{
        width: '190px',
        height: '48px',
        border: '1px solid',
        borderColor: (theme) => isOver && canDrop ? theme.palette.primary.main : theme.palette.divider,
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: (theme) => theme.palette.background.paper,
        opacity: isDragging ? 0 : 1,
        userSelect: 'none',
        cursor: result ? 'move' : 'default',
      }}
    >
      {result && (
        <Typography
          ref={drag}
          variant="body1"
          onClick={handleClick}
          sx={{
            cursor: 'move',
            opacity: isDragging ? 0 : 1,
          }}
        >
          {result}
        </Typography>
      )}
    </Box>
  );
};

const DragPreview = () => {
  const { itemType, item, currentOffset } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    item: monitor.getItem(),
    currentOffset: monitor.getClientOffset(),
  }));

  const [calculatorOffset, setCalculatorOffset] = useState({ x: 0, y: 0 });
  const [operation, setOperation] = useState('+');

  useEffect(() => {
    const calculator = document.querySelector('.calculator-widget');
    if (calculator) {
      const rect = calculator.getBoundingClientRect();
      setCalculatorOffset({ x: rect.left, y: rect.top });
    }
  }, []);

  useEffect(() => {
    const handleKeyChange = (e) => {
      if (e.ctrlKey && e.altKey) {
        setOperation('÷');
      } else if (e.ctrlKey) {
        setOperation('-');
      } else if (e.altKey) {
        setOperation('×');
      } else {
        setOperation('+');
      }
    };

    window.addEventListener('keydown', handleKeyChange);
    window.addEventListener('keyup', handleKeyChange);

    return () => {
      window.removeEventListener('keydown', handleKeyChange);
      window.removeEventListener('keyup', handleKeyChange);
    };
  }, []);

  if (!currentOffset) {
    return null;
  }

  return (
<div style={{
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: currentOffset.x - calculatorOffset.x + 50,
  top: currentOffset.y - calculatorOffset.y + 40,
  transform: 'translate(50%, 50%)'
}}>

      <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
        {operation}
      </Typography>
    </div>
  );
};

const CalculatorWidget = () => {
  const [display, setDisplay] = useState('0');
  const [operation, setOperation] = useState(null);
  const [prevValue, setPrevValue] = useState(null);
  const [slotResults, setSlotResults] = useState(Array(8).fill(null));
  const [showCopyAnimation, setShowCopyAnimation] = useState(false);
  const theme = useTheme();
  const [expression, setExpression] = useState('');
  const [preResult, setPreResult] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [clearTimeoutId, setClearTimeoutId] = useState(null);

  const calculatePreResult = useCallback(() => {
    if (operation && prevValue && display !== '0') {
      const prev = parseFloat(prevValue);
      const current = parseFloat(display);
      let result;
      switch (operation) {
        case '+':
          result = prev + current;
          break;
        case '-':
          result = prev - current;
          break;
        case '*':
          result = prev * current;
          break;
        case '/':
          result = prev / current;
          break;
        default:
          return;
      }
      setPreResult(result.toString());
    } else {
      setPreResult('');
    }
  }, [operation, prevValue, display]);

  const handleNumberClick = useCallback((num) => {
    setDisplay((prev) => {
      const newDisplay = prev === '0' ? num : prev + num;
      setExpression((prevExp) => prevExp + num);
      return newDisplay;
    });
  }, []);

  const handleOperationClick = useCallback((op) => {
    if (prevValue === null) {
      setPrevValue(display);
      setOperation(op);
      setDisplay('0');
      setExpression((prev) => `${prev.trim()} ${op} `);
    } else if (display !== '0') {
      const result = calculateResult(prevValue, display, operation);
      setPrevValue(result.toString());
      setOperation(op);
      setDisplay('0');
      setExpression((prev) => `${prev.trim()} ${op} `);
      setPreResult(result.toString());
    } else {
      setOperation(op);
      setExpression((prev) => {
        const parts = prev.trim().split(' ');
        parts[parts.length - 1] = op;
        return parts.join(' ') + ' ';
      });
    }
  }, [display, prevValue, operation]);

  const calculateResult = (prev, current, op) => {
    const a = parseFloat(prev);
    const b = parseFloat(current);
    switch (op) {
      case '+': return Math.round((a + b) * 1e12) / 1e12;
      case '-': return Math.round((a - b) * 1e12) / 1e12;
      case '*': return Math.round((a * b) * 1e12) / 1e12;
      case '/': return Math.round((a / b) * 1e12) / 1e12;
      default: return current;
    }
  };

  const handleEqualsClick = useCallback(() => {
    if (operation && prevValue) {
      const result = calculateResult(prevValue, display, operation);
      setIsAnimating(true);
      setTimeout(() => {
        setDisplay(result.toString());
        setExpression(formatNumber(result.toString()));
        setOperation(null);
        setPrevValue(null);
        setPreResult('');
        setIsAnimating(false);
      }, 300);
    }
  }, [operation, prevValue, display]);

  const handleClearClick = useCallback(() => {
    setDisplay('0');
    setOperation(null);
    setPrevValue(null);
    setExpression('');
    setPreResult('');
  }, []);

  const handleClearLongPress = useCallback(() => {
    handleClearClick();
    setSlotResults(Array(8).fill(null));
  }, [handleClearClick]);

  const handleClearMouseDown = useCallback(() => {
    const timeout = setTimeout(handleClearLongPress, 2000);
    setClearTimeoutId(timeout);
  }, [handleClearLongPress]);

  const handleClearMouseUp = useCallback(() => {
    if (clearTimeoutId) {
      clearTimeout(clearTimeoutId);
      setClearTimeoutId(null);
    }
    handleClearClick();
  }, [clearTimeoutId, handleClearClick]);

  const handleBackspace = useCallback(() => {
    setDisplay((prev) => {
      if (prev.length > 1) {
        return prev.slice(0, -1);
      }
      return '0';
    });
    setExpression((prev) => {
      if (prev.length > 1) {
        return prev.slice(0, -1);
      }
      return '';
    });
  }, []);

  const handleKeyDown = useCallback((event) => {
    if (event.key >= '0' && event.key <= '9') {
      handleNumberClick(event.key);
    } else if (event.key === '.') {
      handleNumberClick('.');
    } else if (['+', '-', '*', '/'].includes(event.key)) {
      handleOperationClick(event.key);
    } else if (event.key === 'Enter' || event.key === '=') {
      event.preventDefault();
      handleEqualsClick();
    } else if (event.key === 'Escape') {
      handleClearClick();
    } else if (event.key === 'Backspace') {
      handleBackspace();
    }
  }, [handleNumberClick, handleOperationClick, handleEqualsClick, handleClearClick, handleBackspace]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    calculatePreResult();
  }, [calculatePreResult, display, operation, prevValue]);

  const buttonStyle = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    width: '100%',
    height: '60px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  };

  const operationStyle = {
    ...buttonStyle,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    color: theme.palette.primary.main,
  };

  const equalStyle = {
    ...buttonStyle,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  const handleDrop = useCallback((item, targetIndex, modifiers) => {
    const sourceIndex = item.index;
    setSlotResults(prev => {
      const newSlotResults = [...prev];
      let sourceValue, targetValue;

      if (sourceIndex === undefined) {
        sourceValue = parseFloat((item.preResult || item.result).replace(/,/g, ''));
      } else {
        sourceValue = parseFloat(newSlotResults[sourceIndex].replace(/,/g, ''));
      }

      targetValue = newSlotResults[targetIndex] ? parseFloat(newSlotResults[targetIndex].replace(/,/g, '')) : 0;

      let result;
      if (modifiers.ctrlKey && modifiers.altKey) {
        result = Math.round((targetValue / sourceValue) * 1e12) / 1e12;
      } else if (modifiers.ctrlKey) {
        result = Math.round((targetValue - sourceValue) * 1e12) / 1e12;
      } else if (modifiers.altKey) {
        result = Math.round((targetValue * sourceValue) * 1e12) / 1e12;
      } else {
        result = Math.round((targetValue + sourceValue) * 1e12) / 1e12;
      }

      newSlotResults[targetIndex] = formatNumber(result.toString());
      if (sourceIndex !== undefined) {
        newSlotResults[sourceIndex] = null;
      }
      return newSlotResults;
    });
  }, []);

  const handleResultClick = () => {
    const textToCopy = preResult || display;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setShowCopyAnimation(true);
      setTimeout(() => setShowCopyAnimation(false), 1000);
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box className="calculator-widget" sx={{ 
        display: 'flex', 
        p: 2, 
        bgcolor: theme.palette.background.default, 
        borderRadius: 2, 
        width: 'fit-content',
        height: 'fit-content',
      }}>
        <Box sx={{ 
          width: '350px', 
          mr: 2, 
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Paper 
            elevation={3} 
            sx={{ 
              p: 2, 
              mb: 2, 
              height: '130px',
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
              color: theme.palette.text.primary,
              cursor: 'pointer',
              position: 'relative',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              flexShrink: 0,
            }}
            onClick={handleResultClick}
          >
            <AnimatePresence mode="wait">
              <DraggableResult 
                key={expression}
                result={formatNumber(display)} 
                expression={expression} 
                preResult={preResult ? formatNumber(preResult) : ''}
                onDragStart={handleResultClick}
                isAnimating={isAnimating}
              />
            </AnimatePresence>
            <AnimatePresence>
              {isAnimating && (
                <motion.div
                  initial={{ opacity: 1, y: 40, x: 0 }}
                  animate={{ opacity: 0, y: -40, x: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '16px',
                  }}
                >
                  <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
                    {formatNumber(preResult)}
                  </Typography>
                </motion.div>
              )}
              {showCopyAnimation && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <ContentCopyIcon color="primary" />
                </motion.div>
              )}
            </AnimatePresence>
          </Paper>
          <Grid container spacing={1} sx={{ flexGrow: 1 }}>
            {['C', '±', '%', '/', 7, 8, 9, '*', 4, 5, 6, '-', 1, 2, 3, '+', '', 0, '.', '='].map((item, index) => (
              <Grid item xs={3} key={index}>
                {item !== '' ? (
                  <Button
                    variant="contained"
                    onMouseDown={item === 'C' ? handleClearMouseDown : undefined}
                    onMouseUp={item === 'C' ? handleClearMouseUp : undefined}
                    onMouseLeave={item === 'C' ? handleClearMouseUp : undefined}
                    onClick={() => {
                      if (typeof item === 'number' || item === '.') {
                        handleNumberClick(item.toString());
                      } else if (['+', '-', '*', '/'].includes(item)) {
                        handleOperationClick(item);
                      } else if (item === '=') {
                        handleEqualsClick();
                      }
                    }}
                    sx={
                      ['+', '-', '*', '/'].includes(item)
                        ? operationStyle
                        : item === '='
                        ? equalStyle
                        : buttonStyle
                    }
                  >
                    {item}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleBackspace}
                    sx={buttonStyle}
                  >
                    <BackspaceIcon />
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '430px',
          width: '220px',
        }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            mb: 1,
          }}>
            <SaveIcon color="primary" sx={{ cursor: 'pointer', mr: 1, mt: 6.5 }} />
            <Tooltip 
              title={
                <div style={{ textAlign: 'left', padding: '8px' }}>
                  <strong>Calculator Tips:</strong>
                  <ul style={{ margin: 0, paddingLeft: '20px' }}>
                    <li>Drag a result to save it on any slot below.</li>
                    <li>Combine items to sum their values.</li>
                    <li>Hold <kbd>ALT</kbd> and drag to multiply.</li>
                    <li>Hold <kbd>CTRL</kbd> and drag to subtract.</li>
                    <li>Hold <kbd>ALT + CTRL</kbd> and drag to divide.</li>
                    <li>Press and hold <kbd>C</kbd> for 2s to clear saved values.</li>
                  </ul>
                </div>
              } 
              arrow 
              placement="bottom-end"
            >
            <InfoIcon color="primary" sx={{ cursor: 'pointer', mr: 4, mt: 6.5 }} />
            </Tooltip>
          </Box>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}>
            {slotResults.map((result, index) => (
              <ResultSlot key={index} index={index} onDrop={handleDrop} result={result} />
            ))}
          </Box>
        </Box>
      </Box>
      <DragPreview />
    </DndProvider>
  );
};

export default CalculatorWidget;