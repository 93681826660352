import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton, Typography, Fade, Paper, TextField } from '@mui/material';
import { styled } from '@mui/system';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const RecordButton = styled(IconButton)(({ theme }) => ({
  width: 80,
  height: 80,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StopButton = styled(IconButton)(({ theme }) => ({
  width: 80,
  height: 80,
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const CountdownTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '3rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

const WaveformCanvas = styled('canvas')({
  width: '100%',
  height: '50px',
  backgroundColor: 'transparent',
});

const SpeechToTextWidget = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);

  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const beepAudio = useRef(new Audio('/sounds/beep.wav'));

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  useEffect(() => {
    if (countdown > 0) {
      beepAudio.current.play();
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      startRecording();
    }
  }, [countdown]);

  useEffect(() => {
    if (isRecording && canvasRef.current) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationRef.current);
      if (canvasRef.current) {
        const canvasCtx = canvasRef.current.getContext('2d');
        canvasCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }
  }, [isRecording]);

  const startCountdown = () => {
    setCountdown(3);
  };

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const source = audioCtx.createMediaStreamSource(stream);
    const analyserNode = audioCtx.createAnalyser();
    analyserNode.fftSize = 2048;
    source.connect(analyserNode);

    setAudioContext(audioCtx);
    setAnalyser(analyserNode);

    setIsRecording(true);
    setCountdown(null);
    resetTranscript();
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopRecording = () => {
    setIsRecording(false);
    if (audioContext) {
      audioContext.close();
    }
    SpeechRecognition.stopListening();
  };

  const drawWaveform = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const canvasCtx = canvas.getContext('2d');
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      if (!canvas) return;

      animationRef.current = requestAnimationFrame(draw);
      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = '#87CEFA';
      canvasCtx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    draw();
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'relative',
        height: '100px',
      }}>
        {!isRecording && countdown === null && (
          <RecordButton onClick={startCountdown}>
            <MicIcon fontSize="large" />
          </RecordButton>
        )}
        {countdown !== null && (
          <Fade in={countdown !== null}>
            <CountdownTypography>{countdown}</CountdownTypography>
          </Fade>
        )}
        {isRecording && (
          <StopButton onClick={stopRecording} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <StopIcon />
          </StopButton>
        )}
      </Box>
      {isRecording && (
        <Box sx={{ height: 50, mb: 2 }}>
          <WaveformCanvas ref={canvasRef} />
        </Box>
      )}
      <Paper sx={{ 
        flexGrow: 1, 
        overflow: 'auto', 
        maxHeight: 'calc(100% - 150px)',
        padding: '16px',
      }}>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          value={transcript}
          InputProps={{
            readOnly: true,
          }}
        />
      </Paper>
    </Box>
  );
};

export default SpeechToTextWidget;