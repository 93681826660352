import React, { useState, useCallback, useEffect } from 'react';
import { Box, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PaletteIcon from '@mui/icons-material/Palette';
import Draggable from 'react-draggable';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import debounce from 'lodash/debounce';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const colors = ['#feff9c', '#f39d55', '#ec6595', '#50b8da', '#aece56'];

const StickyNotes = React.memo(({ id, content, initialPosition, zIndex, color, onClose, bringToFront, onPositionChange, onContentChange, onColorChange }) => {
  const [position, setPosition] = useState(initialPosition);
  const [colorIndex, setColorIndex] = useState(colors.indexOf(color) !== -1 ? colors.indexOf(color) : 0);
  const [editorState, setEditorState] = useState(() => {
    if (content) {
      return EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
    }
    return EditorState.createEmpty();
  });

  const debouncedContentChange = useCallback(
    debounce((newContent) => {
      onContentChange(id, JSON.stringify(convertToRaw(newContent)));
    }, 3000),
    [id, onContentChange]
  );

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    debouncedContentChange(newEditorState.getCurrentContent());
  };

  const handleColorChange = () => {
    const newIndex = (colorIndex + 1) % colors.length;
    setColorIndex(newIndex);
    onColorChange(id, colors[newIndex]);
  };

  const handleDrag = useCallback((e, ui) => {
    setPosition({ x: ui.x, y: ui.y });
  }, []);

  const handleStop = useCallback(() => {
    onPositionChange(id, position);
  }, [id, onPositionChange, position]);

  return (
    <Draggable
      position={position}
      onDrag={handleDrag}
      onStop={handleStop}
      bounds="parent"
      handle=".handle"
    >
      <Box
        onClick={() => bringToFront(id)}
        sx={{
          position: 'absolute',
          width: 235,
          height: 235,
          backgroundColor: colors[colorIndex],
          borderRadius: '10px',
          overflow: 'hidden',
          zIndex: zIndex,
          boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          className="handle"
          sx={{
            cursor: 'move',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '4px 8px',
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
          }}
        >
          <IconButton size="small" sx={{ color: '#707045', padding: '4px' }} onClick={handleColorChange}>
            <PaletteIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onClose(id);
            }}
            sx={{ color: '#707045', padding: '4px' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          toolbarHidden
          wrapperClassName="sticky-note-wrapper"
          editorClassName="sticky-note-editor"
        />
      </Box>
    </Draggable>
  );
});

export default StickyNotes;