import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  LinearProgress,
  Box,
  TextField,
  Tooltip,
} from '@mui/material';
import { encodingForModel } from "js-tiktoken";

const TokenizerWidget = () => {
  const [inputText, setInputText] = useState('');
  const [tokenCount, setTokenCount] = useState(0);
  const [highlightedText, setHighlightedText] = useState('');

  const models = [
    { name: 'ChatGPT-4 (Latest)', maxTokens: 128000, costPerMillionTokens: 2.50, encodingName: 'cl100k_base' },
    { name: 'GPT-4 Turbo', maxTokens: 16000, costPerMillionTokens: 10, encodingName: 'cl100k_base' },
    { name: 'Claude 3 Haiku', maxTokens: 200000, costPerMillionTokens: 0.25, encodingName: 'cl100k_base' },
    { name: 'Claude 3 Opus', maxTokens: 200000, costPerMillionTokens: 15, encodingName: 'cl100k_base' },
    { name: 'Claude 3.5 Sonnet', maxTokens: 200000, costPerMillionTokens: 3, encodingName: 'cl100k_base' },
    { name: 'Gemini 1.5', maxTokens: 128000, costPerMillionTokens: 14, encodingName: 'cl100k_base' },
  ];

  useEffect(() => {
    const calculateTokens = () => {
      if (inputText) {
        try {
          const enc = encodingForModel("gpt-4");
          const tokens = enc.encode(inputText);
          setTokenCount(tokens.length);

          // Highlight tokens
          let highlighted = '';
          let lastIndex = 0;
          const colors = ['#362a59', '#3d6d46', '#75592b', '#732e31', '#235c73'];
          tokens.forEach((token, index) => {
            const tokenText = enc.decode([token]);
            const startIndex = inputText.indexOf(tokenText, lastIndex);
            const endIndex = startIndex + tokenText.length;
            highlighted += inputText.slice(lastIndex, startIndex);
            highlighted += `<span style="background-color: ${colors[index % colors.length]}; color: white;">${tokenText}</span>`;
            lastIndex = endIndex;
          });
          highlighted += inputText.slice(lastIndex);
          setHighlightedText(highlighted);
        } catch (error) {
          console.error('Error calculating tokens:', error);
          setTokenCount(0);
          setHighlightedText(inputText);
        }
      } else {
        setTokenCount(0);
        setHighlightedText('');
      }
    };

    calculateTokens();
  }, [inputText]);

  return (
    <Card sx={{ maxWidth: 600, m: 2 }}>
      <CardContent>
        <TextField
          multiline
          rows={6}
          fullWidth
          variant="outlined"
          placeholder="Type or paste your text here..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Box 
          dangerouslySetInnerHTML={{ __html: highlightedText }} 
          sx={{ 
            mb: 2, 
            p: 2, 
            border: '1px solid #ccc', 
            borderRadius: 1, 
            maxHeight: '200px', 
            overflowY: 'auto' 
          }}
        />
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Typography variant="h2" color="primary" gutterBottom>
                {tokenCount}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                tokens
              </Typography>
            </Box>
            <Box sx={{ maxWidth: '60%', mt: '5px' }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                <Typography component="span" variant="subtitle2" fontWeight="bold">
                  This text
                </Typography>{' '}
                uses approximately {tokenCount} tokens across various AI models.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                The bars below represent the token usage relative to each model's maximum allowed tokens.
              </Typography>
            </Box>
          </Stack>
          <Stack spacing={1.5}>
            {models.map((model) => {
              const percentage = (tokenCount / model.maxTokens) * 100;
              const cost = (tokenCount / 1000000) * model.costPerMillionTokens;
              return (
                <Box key={model.name}>
                  <Typography variant="subtitle1">{model.name}</Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Tooltip title={`Your text uses ${percentage.toFixed(2)}% of the token limit for ${model.name}`} arrow>
                      <LinearProgress
                        variant="determinate"
                        value={Math.min(percentage, 100)}
                        sx={{ flexGrow: 1, height: 8, borderRadius: 4 }}
                      />
                    </Tooltip>
                    <Typography variant="body2" minWidth={60}>
                      ${cost.toFixed(5)}
                    </Typography>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TokenizerWidget;