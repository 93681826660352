import { createTheme } from '@mui/material/styles';
import React from 'react';

export const createAppTheme = (mode) => {
  return createTheme({
    palette: {
      mode,
      background: {
        default: mode === 'light' ? '#ffffff' : '#0d0e12',
        paper: mode === 'light' ? '#ffffff' : '#111217',
      },
      text: {
        primary: mode === 'light' ? '#071437' : '#f5f5f5',
        secondary: mode === 'light' ? '#626e82' : '#9a9aaa',
      },
      primary: {
        main: '#006ae6',
      },
    },
    typography: {
      fontFamily: '"Montserrat", sans-serif',
      h1: { fontWeight: 500 },
      h2: { fontWeight: 600 },
      h3: { fontWeight: 500 },
      h4: { fontWeight: 700 },
      h5: { fontWeight: 400 },
      h6: { fontWeight: 600 },
      button: { fontWeight: 700 },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'light' ? '#ffffff' : '#111217',
            borderColor: mode === 'light' ? '#f1f1f4' : '#1b1c22',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: '#006ae6',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#0056b3',
            },
          },
        },
      },
    },
  });
};

export const useColorMode = (setMode) => {
  return React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [setMode]
  );
};