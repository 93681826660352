import React, { useState, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, TextField, Button, CircularProgress, IconButton, useTheme } from '@mui/material';
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

const imageTypes = [
  { name: 'Coloring Book', value: 'coloring-book', time: 22 },
  // Add more image types here as needed
];

const ImageGeneratorWidget = ({ userId }) => {
  const theme = useTheme();
  const [selectedType, setSelectedType] = useState(imageTypes[0].value);
  const [prompt, setPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    fetchGeneratedImages();
  }, [userId]);

  const fetchGeneratedImages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/ai/generated-images/${userId}`);
      setGeneratedImages(response.data);
      if (response.data.length > 0) {
        setCurrentImage(response.data[0].imageUrl);
      }
    } catch (error) {
      console.error('Error fetching generated images:', error);
    }
  };

  const handleGenerate = async () => {
    setIsGenerating(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/ai/generate-image`, {
        type: selectedType,
        prompt: prompt,
        userId: userId
      });
      const newImage = response.data.result[0];
      setCurrentImage(newImage);
      setGeneratedImages(prev => [{ imageUrl: newImage, prompt, modelType: selectedType }, ...prev]);
    } catch (error) {
      console.error('Error generating image:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownload = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  const handleDelete = async (imageUrl) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/ai/generated-image`, { data: { userId, imageUrl } });
      setGeneratedImages(prev => prev.filter(img => img.imageUrl !== imageUrl));
      if (currentImage === imageUrl) {
        setCurrentImage(generatedImages[0]?.imageUrl || null);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const getSelectedTypeTime = () => {
    return imageTypes.find(type => type.value === selectedType)?.time || 0;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', overflow: 'hidden' }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'text.primary' }}>
        Generate AI Images
      </Typography>
      <Select
        value={selectedType}
        onChange={(e) => setSelectedType(e.target.value)}
        fullWidth
        margin="normal"
        size="small"
        sx={{ mb: 2, bgcolor: 'background.paper' }}
      >
        {imageTypes.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {type.name} (approx. {type.time} seconds)
          </MenuItem>
        ))}
      </Select>
      <TextField
        fullWidth
        margin="normal"
        label="Enter your prompt"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        multiline
        rows={3}
        size="small"
        sx={{ mb: 2, bgcolor: 'background.paper' }}
      />
      <Button
        variant="contained"
        onClick={handleGenerate}
        disabled={isGenerating || !prompt}
        sx={{ mb: 2, bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}
      >
        {isGenerating ? (
          <>
            <CircularProgress size={24} sx={{ mr: 1, color: 'white' }} />
            Generating... this model takes approximately {getSelectedTypeTime()} seconds
          </>
        ) : (
          'Generate'
        )}
      </Button>
      {currentImage && (
        <Box sx={{ display: 'flex', height: 'calc(100% - 250px)', overflow: 'hidden' }}>
          <Box sx={{ flex: 1, mr: 2, position: 'relative', overflow: 'hidden', borderRadius: 2 }}>
            <img src={currentImage} alt="Generated" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            <IconButton
              onClick={() => handleDownload(currentImage)}
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                bgcolor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.8)' },
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Box>
          {generatedImages.length > 1 && (
            <Box sx={{ width: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                Collection
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: theme.palette.background.paper,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  },
                }}
              >
                {generatedImages.map((img, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: '100%',
                      height: '100px',
                      mb: 1,
                      border: '2px solid #333',
                      borderRadius: '4px',
                      overflow: 'hidden',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={img.imageUrl}
                      alt={`Generated ${index}`}
                      style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                      onClick={() => setCurrentImage(img.imageUrl)}
                    />
                    <IconButton
                      onClick={() => handleDelete(img.imageUrl)}
                      sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        bgcolor: 'rgba(0, 0, 0, 0.6)',
                        color: 'white',
                        '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.8)' },
                        padding: '4px',
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ImageGeneratorWidget;