import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

export const handleGoogleLoginSuccess = async (credentialResponse, setUser, setMode, setWorkspaceWidgets, setWidgetPositions, setWidgetSizes, setWidgetZIndex, setStickyNotes) => {
  try {
    const decoded = jwtDecode(credentialResponse.credential);
    let userData;
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${decoded.sub}`);
      userData = response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // User not found, create a new user
        const newUser = {
          googleId: decoded.sub,
          name: decoded.name,
          email: decoded.email,
          picture: decoded.picture,
          theme: 'light',
          workspaceWidgets: [[], [], []],
          widgetPositions: {0: {}, 1: {}, 2: {}},
          widgetSizes: {0: {}, 1: {}, 2: {}},
          widgetZIndex: {},
          stickyNotes: []
        };
        const createResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users`, newUser);
        userData = createResponse.data;
      } else {
        // If it's not a 404 error, rethrow it
        throw error;
      }
    }
    
    setUser(userData);
    setMode(userData.theme || 'light');
    setWorkspaceWidgets(userData.workspaceWidgets || [[], [], []]);
    setWidgetPositions(userData.widgetPositions || {0: {}, 1: {}, 2: {}});
    setWidgetSizes(userData.widgetSizes || {0: {}, 1: {}, 2: {}});
    setWidgetZIndex(userData.widgetZIndex || {});
    setStickyNotes(userData.stickyNotes || []);

    return userData;
  } catch (error) {
    console.error('Error handling Google login:', error);
    throw error;
  }
};

export const handleLogout = async (
  user,
  mode,
  workspaceWidgets,
  widgetPositions,
  widgetSizes,
  widgetZIndex,
  setUser,
  setMode,
  setWorkspaceWidgets,
  setWidgetPositions,
  setWidgetSizes,
  setWidgetZIndex,
  setStickyNotes,
  handleCloseWidgetList,
  handleCloseNotifications
) => {
  try {
    // Save user data before logging out
    const updatedUserData = {
      ...user,
      theme: mode,
      workspaceWidgets,
      widgetPositions,
      widgetSizes,
      widgetZIndex
    };
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users`, updatedUserData);

    // Clear user data from state
    setUser(null);
    setMode('light');
    // Don't clear these states, keep them as is
    // setWorkspaceWidgets([[], [], []]);
    // setWidgetPositions({});
    // setWidgetSizes({});
    // setWidgetZIndex({});
    setStickyNotes([]);
    handleCloseWidgetList();
    handleCloseNotifications();

    // Clear local storage
    localStorage.removeItem('user');

    console.log('Logged out successfully');
  } catch (error) {
    console.error('Error during logout:', error);
  }
};