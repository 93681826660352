import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, TextField, List, ListItem, ListItemText, IconButton, Tooltip, Popover, Button, ClickAwayListener } from '@mui/material';
import SpotifyWebPlayer from 'react-spotify-web-playback';
import axios from 'axios';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import SearchIcon from '@mui/icons-material/Search';
import LogoutIcon from '@mui/icons-material/Logout';

const SpotifyWidget = ({ onClose, isVisible, token, isAuthenticated, onLogin, onLogout, userId }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [play, setPlay] = useState(false);
  const [uris, setUris] = useState([]);
  const searchInputRef = useRef(null);

  const open = Boolean(anchorEl);
  const id = open ? 'search-popover' : undefined;

  useEffect(() => {
    const handleSpotifyCallback = async (event) => {
      if (event.origin !== "https://dashflow.onrender.com") return;
      
      if (event.data.type === 'SPOTIFY_TOKEN') {
        const { access_token, expires_in } = event.data;
        onLogin(access_token, expires_in);
      } else if (event.data.type === 'SPOTIFY_ERROR') {
        console.error('Spotify authentication error:', event.data.error);
      }
    };

    window.addEventListener('message', handleSpotifyCallback);

    return () => {
      window.removeEventListener('message', handleSpotifyCallback);
    };
  }, [onLogin]);

  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://api.spotify.com/v1/search`, {
        params: { q: searchQuery, type: 'track,playlist', limit: 5 },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setSearchResults([
        ...response.data.tracks.items.map(track => ({ ...track, type: 'track' })),
        ...response.data.playlists.items.map(playlist => ({ ...playlist, type: 'playlist' }))
      ]);
    } catch (error) {
      console.error('Error searching:', error);
    }
  };

  const handleSelect = async (item) => {
    if (item.type === 'track') {
      setUris([item.uri]);
    } else if (item.type === 'playlist') {
      try {
        const response = await axios.get(`https://api.spotify.com/v1/playlists/${item.id}/tracks`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const trackUris = response.data.items.map(item => item.track.uri);
        setUris(trackUris);
      } catch (error) {
        console.error('Error fetching playlist tracks:', error);
      }
    }
    setPlay(true);
    setAnchorEl(null);
  };

  const handleSearchIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSearchQuery('');
    setSearchResults([]);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(
      `https://dashflow.onrender.com/api/spotify/login?userId=${userId}`,
      'Spotify Login',
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  if (!isVisible) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        height="100%"
      >
        <Typography variant="h6" gutterBottom>
          Connect to Spotify
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
          Link your Spotify account to start playing music
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<QueueMusicIcon />} 
          onClick={handleLogin}
        >
          Connect Spotify
        </Button>
      </Box>
    );
  }

  const handlePlaybackStateChange = (state) => {
    if (state.track) {
      setPlay(true);
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Spotify</Typography>
        <Box>
          <Tooltip title="Search for a playlist or song">
            <IconButton onClick={handleSearchIconClick}>
              <QueueMusicIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton onClick={onLogout}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <ClickAwayListener onClickAway={handleClosePopover}>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box sx={{ p: 1.4, width: 210 }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 1.4 }}>
              <SearchIcon sx={{ color: 'action.active', mr: 0.7, my: 0.35, fontSize: '1.2rem' }} />
              <TextField 
                fullWidth
                id="input-with-sx" 
                label="Search song or playlist" 
                variant="standard" 
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  style: { fontSize: '0.9rem' }
                }}
                InputLabelProps={{
                  style: { fontSize: '0.9rem' }
                }}
                autoFocus
                inputRef={searchInputRef}
              />
            </Box>
            <List sx={{ maxHeight: 140, overflow: 'auto' }}>
              {searchResults.map((item) => (
                <ListItem key={item.id} button onClick={() => handleSelect(item)} dense>
                  <ListItemText 
                    primary={item.name} 
                    secondary={item.type === 'track' ? item.artists[0].name : `Playlist • ${item.owner.display_name}`}
                    primaryTypographyProps={{ fontSize: '0.9rem' }}
                    secondaryTypographyProps={{ fontSize: '0.8rem' }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Popover>
      </ClickAwayListener>
      {token && (
        <SpotifyWebPlayer
          token={token}
          uris={uris}
          play={play}
          callback={handlePlaybackStateChange}
          styles={{
            activeColor: '#fff',
            bgColor: '#333',
            color: '#fff',
            loaderColor: '#fff',
            sliderColor: '#1cb954',
            trackArtistColor: '#ccc',
            trackNameColor: '#fff',
          }}
          key={uris.join(',')}
          autoPlay={true}
        />
      )}
    </Box>
  );
};

export default SpotifyWidget;