import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Box, 
  TextField, 
  Button,  
  useTheme,
  Autocomplete,
  CircularProgress,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const techStacks = [
  "MEAN (MongoDB / Express / Angular / Node.js)",
  "MERN (MongoDB / Express / React / Node.js)",
  "LAMP (Linux / Apache / MySQL / PHP)",
  "LEMP (Linux / Nginx / MySQL / PHP / Python)",
  "JAMstack (JavaScript / APIs / Markup)",
  "Serverless (AWS Lambda / API Gateway / S3 / DynamoDB)",
  ".NET (C# / ASP.NET Core / SQL Server / Blazor)",
  "Python (Django / PostgreSQL / Celery / React)",
  "Ruby on Rails (Ruby on Rails / PostgreSQL / Redis)",
  "Go (Go / Gin/Echo / PostgreSQL / Docker)"
];

const uiLibraries = {
  "MEAN (MongoDB / Express / Angular / Node.js)": ["None", "Angular Material"],
  "MERN (MongoDB / Express / React / Node.js)": ["None", "Material UI", "Chakra UI", "Shadcn UI"],
  "LAMP (Linux / Apache / MySQL / PHP)": ["None", "Bootstrap", "Bulma"],
  "LEMP (Linux / Nginx / MySQL / PHP / Python)": ["None", "Bootstrap", "Bulma"],
  "JAMstack (JavaScript / APIs / Markup)": ["None", "Tailwind UI", "Shadcn UI"],
  "Serverless (AWS Lambda / API Gateway / S3 / DynamoDB)": ["None", "Bootstrap", "Tailwind CSS", "Shadcn UI"],
  ".NET (C# / ASP.NET Core / SQL Server / Blazor)": ["None", "Blazorise"],
  "Python (Django / PostgreSQL / Celery / React)": ["None", "Django Bootstrap", "Materialize"],
  "Ruby on Rails (Ruby on Rails / PostgreSQL / Redis)": ["None", "Bootstrap", "Tailwind CSS"],
  "Go (Go / Gin/Echo / PostgreSQL / Docker)": ["None", "Bootstrap", "Tailwind CSS"]
};

const folderStructures = {
  "MEAN (MongoDB / Express / Angular / Node.js)": 
`project-root/
│
├── backend/                  # Node.js & Express server
│   ├── config/               # Configuration files
│   ├── controllers/          # Express controllers
│   ├── models/               # Mongoose models
│   ├── routes/               # Express routes
│   ├── middleware/           # Custom middleware
│   ├── services/             # Business logic
│   ├── utils/                # Utility functions
│   ├── app.js                # Main application file
│   └── server.js             # Server entry point
│
├── frontend/                 # Angular application
│   ├── src/
│   │   ├── app/              # Angular components, services, modules
│   │   ├── assets/           # Static assets (images, styles)
│   │   ├── environments/     # Environment-specific settings
│   │   ├── index.html        # Main HTML file
│   │   └── styles.css        # Global styles
│   ├── angular.json          # Angular configuration
│   ├── package.json          # Frontend dependencies
│   └── tsconfig.json         # TypeScript configuration
│
├── .gitignore
├── README.md
└── package.json              # Project dependencies and scripts`,
    "MERN (MongoDB / Express / React / Node.js)":
`
project-root/
│
├── backend/                  # Node.js & Express server
│   ├── config/
│   ├── controllers/
│   ├── models/
│   ├── routes/
│   ├── middleware/
│   ├── services/
│   ├── utils/
│   ├── app.js
│   └── server.js
│
├── frontend/                 # React application
│   ├── src/
│   │   ├── components/       # React components
│   │   ├── hooks/            # Custom React hooks
│   │   ├── pages/            # React pages/views
│   │   ├── services/         # API services
│   │   ├── styles/           # Component and global styles
│   │   ├── App.js            # Main React component
│   │   ├── index.js          # Entry point
│   │   └── setupTests.js     # Testing setup
│   ├── public/
│   │   ├── index.html
│   │   └── favicon.ico
│   ├── package.json
│   └── .env                  # Environment variables
│
├── .gitignore
├── README.md
└── package.json              # Project dependencies and scripts`,
"LAMP (Linux / Apache / MySQL / PHP)":
`
project-root/
│
├── public/                   # Publicly accessible files (HTML, CSS, JS)
│   ├── assets/
│   ├── css/
│   ├── js/
│   └── index.php             # Entry point
│
├── src/                      # PHP source code
│   ├── config/               # Configuration files
│   ├── controllers/          # Controllers
│   ├── models/               # Models
│   ├── views/                # Views (templates)
│   ├── helpers/              # Helper functions
│   └── routes.php            # Routing configuration
│
├── .gitignore
├── README.md
└── composer.json             # PHP dependencies`,
"LEMP (Linux / Nginx / MySQL / PHP / Python)":
`project-root/
│
├── public/                   # Publicly accessible files
│   ├── assets/
│   ├── css/
│   ├── js/
│   └── index.php
│
├── src/
│   ├── config/
│   ├── controllers/
│   ├── models/
│   ├── views/
│   ├── helpers/
│   └── routes.php
│
├── .gitignore
├── README.md
└── composer.json`,
"JAMstack (JavaScript / APIs / Markup)": 
`project-root/
│
├── public/                   # Static files
│   ├── assets/
│   ├── css/
│   ├── js/
│   └── index.html
│
├── src/
│   ├── components/           # JavaScript components
│   ├── utils/                # Utility functions
│   └── services/             # API services
│
├── .gitignore
├── README.md
└── package.json              # JavaScript dependencies`,
"Serverless (AWS Lambda / API Gateway / S3 / DynamoDB)": 
`project-root/
│
├── functions/                # Lambda functions
│   ├── function1/            # Individual function directories
│   │   ├── index.js
│   │   └── package.json
│   ├── function2/
│   └── ...
│
├── config/                   # Configuration files
│
├── templates/                # CloudFormation templates (optional)
│
├── .gitignore
├── README.md
└── serverless.yml            # Serverless framework configuration`,
".NET (C# / ASP.NET Core / SQL Server / Blazor)": 
`project-root/
│
├── src/                      # Source code
│   ├── MyApp/                # Main application folder
│   │   ├── Controllers/      # ASP.NET Core controllers
│   │   ├── Models/           # Data models
│   │   ├── Views/            # Razor views
│   │   ├── wwwroot/          # Static files
│   │   ├── Program.cs        # Application entry point
│   │   └── Startup.cs        # Application startup configuration
│
├── .gitignore
├── README.md
└── MyApp.sln                 # Solution file`,
"Python (Django / PostgreSQL / Celery / React)": 
`project-root/
│
├── backend/                  # Django application
│   ├── myapp/                # Django app
│   ├── myproject/            # Project settings
│   ├── manage.py
│   ├── requirements.txt
│   └── config/               # Configuration files
│
├── frontend/                 # React/Vue.js application
│   ├── src/
│   │   ├── components/
│   │   ├── hooks/
│   │   ├── pages/
│   │   ├── services/
│   │   ├── styles/
│   │   ├── App.js
│   │   └── index.js
│   ├── public/
│   │   ├── index.html
│   │   └── favicon.ico
│   ├── package.json
│   └── .env
│
├── .gitignore
├── README.md`,
"Ruby on Rails (Ruby on Rails / PostgreSQL / Redis)": 
`project-root/
│
├── app/                      # Rails application code
│   ├── controllers/
│   ├── models/
│   ├── views/
│   ├── jobs/                 # Background jobs (ActiveJob)
│   ├── mailers/              # Email templates
│   └── helpers/              # View helpers
│
├── config/                   # Configuration files
│
├── db/                       # Database migrations and schema
│
├── lib/                      # Custom libraries
│
├── public/                   # Static files
│
├── test/                     # Tests
│
├── .gitignore
├── README.md
└── Gemfile                   # Ruby dependencies`,
"Go (Go / Gin/Echo / PostgreSQL / Docker)":
`project-root/
│
├── cmd/                      # Main application entry points
│   ├── main.go               # Application entry point
│   └── other_commands.go     # Other command-line tools or executables
│
├── pkg/                      # Library code that's used by the application
│   ├── models/               # Data models
│   ├── routes/               # HTTP routes and handlers
│   ├── services/             # Business logic
│   └── utils/                # Utility functions
│
├── internal/                 # Application-specific code (not exposed to external applications)
│   ├── database/             # Database connection and setup
│   ├── middleware/           # Custom middleware
│   ├── config/               # Configuration handling
│   └── controllers/          # Controllers for handling requests
│
├── api/                      # API definitions and documentation
│   ├── swagger/              # Swagger API documentation
│   └── routes.yaml           # Route definitions (if using YAML)
│
├── docker/                   # Docker-related files
│   ├── Dockerfile            # Dockerfile for building the image
│   └── docker-compose.yml    # Docker Compose configuration (if needed)
│
├── migrations/               # Database migration files
│
├── .gitignore
├── README.md
└── go.mod                    # Go module definition
└── go.sum                    # Go module checksums
`

  // Add other folder structures here...
};

const CursorPromptGeneratorWidget = () => {
  const [selectedStack, setSelectedStack] = useState('');
  const [selectedUILibrary, setSelectedUILibrary] = useState('None');
  const [isLoading, setIsLoading] = useState(false);
  const [generatedPrompt, setGeneratedPrompt] = useState('');
  const theme = useTheme();

  useEffect(() => {
    setSelectedUILibrary('None');
  }, [selectedStack]);

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/cursor-prompt/generate`, {
        stack: selectedStack,
        uiLibrary: selectedUILibrary
      });
      const projectStructure = folderStructures[selectedStack] || '';
      const fullPrompt = `${response.data.result}\n\nProject Structure: 
Please adhere to this folder structure when creating or editing files in my project:
${projectStructure}`;
      setGeneratedPrompt(fullPrompt);
    } catch (error) {
      console.error('Error generating prompt:', error);
      setGeneratedPrompt('An error occurred while generating the prompt. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedPrompt);
    // You might want to add some visual feedback here, like a snackbar
  };

  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 2,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ flex: 1 }}>
          <Autocomplete
            options={techStacks}
            renderInput={(params) => <TextField {...params} label="Select your Tech Stack" />}
            value={selectedStack}
            onChange={(event, newValue) => setSelectedStack(newValue)}
          />
          {selectedStack && (
            <Autocomplete
              options={uiLibraries[selectedStack] || ["None"]}
              renderInput={(params) => <TextField {...params} label="Select UI Component Library" />}
              value={selectedUILibrary}
              onChange={(event, newValue) => setSelectedUILibrary(newValue)}
              sx={{ mt: 2 }}
            />
          )}
          <Button 
            variant="contained" 
            onClick={handleGenerate}
            disabled={isLoading || !selectedStack}
            sx={{ mt: 2 }}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Generate'}
          </Button>
        </Box>
        <Box sx={{ flex: 1, maxHeight: 200, overflowY: 'auto', fontFamily: 'monospace', whiteSpace: 'pre', fontSize: '0.8rem' }}>
          {selectedStack && folderStructures[selectedStack]}
        </Box>
      </Box>
      {generatedPrompt && (
        <Box sx={{ mt: 2, position: 'relative', flex: 1 }}>
          <Typography variant="h6" gutterBottom>.cursorrules File:</Typography>
          <TextField
            multiline
            rows={15}
            variant="outlined"
            fullWidth
            value={generatedPrompt}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mb: 7 }} // Add bottom margin to make space for the buttons
          />
          <Box sx={{ 
            position: 'absolute', 
            bottom: 0, 
            right: 0, 
            display: 'flex', 
            gap: 1,
            p: 1,
            backgroundColor: theme.palette.background.paper,
            borderTop: `1px solid ${theme.palette.divider}`,
            width: '100%',
            justifyContent: 'flex-end'
          }}>
            <Tooltip title="Copy to clipboard">
              <IconButton onClick={handleCopy}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CursorPromptGeneratorWidget;