import {
  Avatar,
  Box,
  CssBaseline,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

import CircleIcon from "@mui/icons-material/Circle";
import LightModeIcon from "@mui/icons-material/LightMode";
import LogoutIcon from "@mui/icons-material/Logout";
import NightlightIcon from "@mui/icons-material/Nightlight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WidgetsIcon from "@mui/icons-material/Widgets";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import { convertFromRaw, convertToRaw } from "draft-js";
import debounce from "lodash/debounce";
import { useBeforeunload } from "react-beforeunload";
import { v4 as uuidv4 } from "uuid";
import AiTranslatorWidget from "./components/AiTranslatorWidget";
import BackgroundRemoverWidget from "./components/BackgroundRemoverWidget";
import BooksWidget from "./components/BooksWidget";
import CalculatorWidget from "./components/CalculatorWidget";
import ColorPicker from "./components/ColorPicker";
import CursorPromptGeneratorWidget from "./components/CursorPromptGeneratorWidget";
import EmojiZone from "./components/EmojiZone";
import EncryptionWidget from "./components/EncryptionWidget";
import ImageGeneratorWidget from "./components/ImageGeneratorWidget";
import LandingPage from "./components/LandingPage";
import MeditationWidget from "./components/MeditationWidget";
import NewsWidget from "./components/NewsWidget";
import NotepadWidget from "./components/NotepadWidget";
import NotificationList from "./components/NotificationList";
import PomodoroWidget from "./components/PomodoroWidget";
import PromptRefinerWidget from "./components/PromptRefinerWidget";
import PromptSaverWidget from "./components/PromptSaverWidget";
import QRCodeWidget from "./components/QRCodeWidget";
import RandomWidget from "./components/RandomWidget";
import RecorderWidget from "./components/RecorderWidget";
import SitesWidget from "./components/SitesWidget";
import SocialMediaBanner from "./components/SocialMediaBanner";
import SpeechToTextWidget from "./components/SpeechToTextWidget";
import SpotifyWidget from "./components/SpotifyWidget";
import StartupDetail from "./components/StartupDetail";
import StartupElevatorWidget from "./components/StartupElevatorWidget";
import StickyNotes from "./components/StickyNotes";
import TaskProjectsWidget from "./components/TaskProjectsWidget";
import TaskWidget from "./components/TaskWidget";
import TicTacToeWidget from "./components/TicTacToeWidget";
import TokenizerWidget from "./components/TokenizerWidget";
import URLShortenerWidget from "./components/URLShortenerWidget";
import UserReviewWidget from "./components/UserReviewWidget";
import WeatherWidget from "./components/WeatherWidget";
import Widget from "./components/Widget";
import WidgetList from "./components/WidgetList";
import { recommendedSizes } from "./config/widgetSizes";
import { createAppTheme, useColorMode } from "./theme/theme";
import {
  handleGoogleLoginSuccessWrapper,
  handleLogoutWrapper,
} from "./utils/authHelpers";
import {
  getStoredSpotifyToken,
  removeSpotifyToken,
  saveSpotifyToken,
} from "./utils/spotifyUtils";

function App() {
  const [user, setUser] = useState(null);
  const [mode, setMode] = useState("light");
  const [showWidgetList, setShowWidgetList] = useState(false);
  const widgetListRef = useRef(null);
  const [activeWidgets, setActiveWidgets] = useState([]);
  const [widgetPositions, setWidgetPositions] = useState({
    0: {},
    1: {},
    2: {},
  });
  const [widgetSizes, setWidgetSizes] = useState({ 0: {}, 1: {}, 2: {} });
  const [widgetZIndex, setWidgetZIndex] = useState({});
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications] = useState([]);
  const notificationListRef = useRef(null);
  const [stickyNotes, setStickyNotes] = useState([]);
  const [currentWorkspace, setCurrentWorkspace] = useState(0);
  const workspaceColors = ["#eb1e4b", "#311eeb", "#eb701e"];
  const [workspaceWidgets, setWorkspaceWidgets] = useState([[], [], []]);
  const [saveCurrentNote, setSaveCurrentNote] = useState(() => async () => {});
  const [showSpotifyWidget, setShowSpotifyWidget] = useState(false);
  const [spotifyToken, setSpotifyToken] = useState("");
  const [isSpotifyAuthenticated, setIsSpotifyAuthenticated] = useState(false);

  const theme = React.useMemo(() => createAppTheme(mode), [mode]);
  const colorMode = useColorMode(setMode);

  const handleWidgetsClick = () => {
    setShowWidgetList((prev) => !prev);
    setShowNotifications(false);
  };

  const handleNotificationsClick = () => {
    setShowNotifications((prev) => !prev);
    setShowWidgetList(false);
  };

  const handleCloseWidgetList = useCallback(() => {
    setShowWidgetList(false);
  }, []);

  const handleCloseNotifications = useCallback(() => {
    setShowNotifications(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        widgetListRef.current &&
        !widgetListRef.current.contains(event.target) &&
        !event.target.closest(".top-buttons")
      ) {
        handleCloseWidgetList();
      }
      if (
        notificationListRef.current &&
        !notificationListRef.current.contains(event.target) &&
        !event.target.closest(".top-buttons")
      ) {
        handleCloseNotifications();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleCloseWidgetList, handleCloseNotifications]);

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    await handleGoogleLoginSuccessWrapper(
      credentialResponse,
      setUser,
      setMode,
      setWorkspaceWidgets,
      setWidgetPositions,
      setWidgetSizes,
      setWidgetZIndex,
      setStickyNotes,
      setActiveWidgets,
      currentWorkspace
    );
  };

  const handleLogout = async () => {
    await handleLogoutWrapper(
      user,
      mode,
      workspaceWidgets,
      widgetPositions,
      widgetSizes,
      widgetZIndex,
      setUser,
      setMode,
      setWorkspaceWidgets,
      setWidgetPositions,
      setWidgetSizes,
      setWidgetZIndex,
      setStickyNotes,
      handleCloseWidgetList,
      handleCloseNotifications,
      saveCurrentNote,
      saveUserPreferences
    );
  };

  const saveUserPreferences = useCallback(
    debounce(async () => {
      if (user) {
        try {
          const updatedUserData = {
            ...user,
            theme: mode,
            workspaceWidgets: workspaceWidgets,
            widgetPositions: {
              ...widgetPositions,
              spotify: widgetPositions.spotify,
            },
            widgetSizes: {
              ...widgetSizes,
              spotify: widgetSizes.spotify,
            },
            widgetZIndex: Object.fromEntries(Object.entries(widgetZIndex)),
            stickyNotes: stickyNotes.map((note) => ({
              id: note.id,
              content: note.content,
              position: note.position,
              zIndex: note.zIndex,
              color: note.color,
              workspace: note.workspace,
            })),
            showSpotifyWidget: showSpotifyWidget,
          };
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/users`, 
            updatedUserData
          );
        } catch (error) {
          console.error("Error saving user preferences:", error);
          console.error("Error response:", error.response?.data);
        }
      }
    }, 1000),
    [
      user,
      mode,
      workspaceWidgets,
      widgetPositions,
      widgetSizes,
      widgetZIndex,
      stickyNotes,
      showSpotifyWidget,
    ]
  );

  useBeforeunload(() => {
    saveUserPreferences();
  });

  const handleToggleWidget = async (widgetId) => {
    if (widgetId === "spotify") {
      setShowSpotifyWidget((prev) => !prev);
    } else {
      let updatedWorkspaceWidgets = [...workspaceWidgets];
      if (!Array.isArray(updatedWorkspaceWidgets[currentWorkspace])) {
        updatedWorkspaceWidgets[currentWorkspace] = [];
      }

      if (updatedWorkspaceWidgets[currentWorkspace].includes(widgetId)) {
        updatedWorkspaceWidgets[currentWorkspace] = updatedWorkspaceWidgets[
          currentWorkspace
        ].filter((id) => id !== widgetId);
      } else {
        updatedWorkspaceWidgets[currentWorkspace] = [
          ...updatedWorkspaceWidgets[currentWorkspace],
          widgetId,
        ];
      }
      setWorkspaceWidgets(updatedWorkspaceWidgets);
      setActiveWidgets(updatedWorkspaceWidgets[currentWorkspace]);

      if (user) {
        try {
          await saveUserPreferences();
        } catch (error) {
          console.error("Error updating user preferences:", error);
        }
      }
    }
  };

  const handleCloseWidget = useCallback(
    (widgetId) => {
      setWorkspaceWidgets((prev) => {
        const updatedWorkspaceWidgets = [...prev];
        updatedWorkspaceWidgets[currentWorkspace] = updatedWorkspaceWidgets[
          currentWorkspace
        ].filter((id) => id !== widgetId);
        return updatedWorkspaceWidgets;
      });
      setActiveWidgets((prev) => prev.filter((id) => id !== widgetId));

      if (user) {
        const updatedUserData = {
          ...user,
          workspaceWidgets: workspaceWidgets.map((workspace, index) =>
            index === currentWorkspace
              ? workspace.filter((id) => id !== widgetId)
              : workspace
          ),
        };
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/api/users`,
            updatedUserData
          )
          .catch((error) =>
            console.error("Error updating user preferences:", error)
          );
      }
    },
    [currentWorkspace, user, workspaceWidgets]
  );

  const handleWidgetPositionChange = useCallback(
    (widgetId, newPosition) => {
      setWidgetPositions((prev) => ({
        ...prev,
        [currentWorkspace]: {
          ...prev[currentWorkspace],
          [widgetId]: newPosition,
        },
      }));
      saveUserPreferences();
    },
    [currentWorkspace, saveUserPreferences]
  );

  const handleWidgetSizeChange = useCallback(
    (widgetId, newSize) => {
      setWidgetSizes((prev) => ({
        ...prev,
        [currentWorkspace]: {
          ...prev[currentWorkspace],
          [widgetId]: newSize,
        },
      }));
      saveUserPreferences();
    },
    [currentWorkspace, saveUserPreferences]
  );

  const bringWidgetToFront = useCallback((widgetId) => {
    setWidgetZIndex((prev) => {
      const highestZIndex = Math.max(...Object.values(prev), 1000);
      return {
        ...prev,
        [widgetId]: highestZIndex + 1,
      };
    });
  }, []);

  const handleCreateStickyNote = useCallback(() => {
    const newStickyNote = {
      id: uuidv4(),
      content: "",
      position: {
        x: Math.random() * (windowSize.width - 235),
        y: Math.random() * (windowSize.height - 235),
      },
      zIndex:
        Math.max(
          ...stickyNotes.map((note) => note.zIndex),
          ...Object.values(widgetZIndex),
          1000
        ) + 1,
      color: "#feff9c",
      workspace: currentWorkspace,
    };

    setStickyNotes((prevNotes) => [...prevNotes, newStickyNote]);
    saveUserPreferences();
  }, [
    windowSize,
    stickyNotes,
    widgetZIndex,
    currentWorkspace,
    saveUserPreferences,
  ]);

  const handleCloseStickyNote = useCallback(
    (id) => {
      setStickyNotes((prevNotes) => prevNotes.filter((note) => note.id !== id));
      saveUserPreferences();
    },
    [saveUserPreferences]
  );

  const handleStickyNotePositionChange = useCallback(
    (id, newPosition) => {
      setStickyNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === id ? { ...note, position: newPosition } : note
        )
      );
      saveUserPreferences();
    },
    [saveUserPreferences]
  );

  const handleStickyNoteContentChange = useCallback(
    (id, newContent) => {
      setStickyNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === id ? { ...note, content: newContent } : note
        )
      );
      saveUserPreferences();
    },
    [saveUserPreferences]
  );

  const handleStickyNoteColorChange = useCallback(
    (id, newColor) => {
      setStickyNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === id ? { ...note, color: newColor } : note
        )
      );
      saveUserPreferences();
    },
    [saveUserPreferences]
  );

  const bringStickyNoteToFront = useCallback(
    (noteId) => {
      setStickyNotes((prev) => {
        const highestZIndex = Math.max(
          ...prev.map((note) => note.zIndex),
          ...Object.values(widgetZIndex),
          1000
        );
        return prev.map((note) =>
          note.id === noteId ? { ...note, zIndex: highestZIndex + 1 } : note
        );
      });
      saveUserPreferences();
    },
    [widgetZIndex, saveUserPreferences]
  );

  useEffect(() => {
    const fetchStickyNotes = async () => {
      if (user) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/sticky-notes/${user.googleId}`
          );
          setStickyNotes(
            response.data.map((note) => ({
              ...note,
              content: note.content
                ? JSON.stringify(
                    convertToRaw(convertFromRaw(JSON.parse(note.content)))
                  )
                : "",
              color: note.color || "#feff9c",
            }))
          );
        } catch (error) {
          console.error("Error fetching sticky notes:", error);
        }
      }
    };
  }, [user]);

  const renderWidget = useCallback(
    (widgetId) => {
      switch (widgetId) {
        case "spotify":
          return (
            <SpotifyWidget
              onClose={() => setShowSpotifyWidget(false)}
              isVisible={showSpotifyWidget}
              token={spotifyToken}
              isAuthenticated={isSpotifyAuthenticated}
              onLogin={handleSpotifyLogin}
              onLogout={handleSpotifyLogout}
              userId={user?.googleId}
            />
          );
        case "news":
          return <NewsWidget />;
        case "pomodoro":
          return <PomodoroWidget />;
        case "weather":
          return <WeatherWidget />;
        case "tasks":
          return <TaskWidget />;
        case "qrcode":
          return <QRCodeWidget />;
        case "notepad":
          return (
            <NotepadWidget
              userId={user?.googleId}
              onCreateStickyNote={handleCreateStickyNote}
              setSaveCurrentNote={setSaveCurrentNote}
              key={`notepad-${currentWorkspace}`}
            />
          );
        case "startupElevator":
          return <StartupElevatorWidget userId={user?.googleId} />;
        case "urlShortener":
          return <URLShortenerWidget userId={user?.googleId} />;
        case "tictactoe":
          return <TicTacToeWidget />;
        case "socialMediaBanner":
          return <SocialMediaBanner />;
        case "emojiZone":
          return <EmojiZone />;
        case "colorPicker":
          return <ColorPicker />;
        case "tokenizer":
          return <TokenizerWidget />;
        case "encryption":
          return <EncryptionWidget />;
        case "random":
          return <RandomWidget userId={user?.googleId} />;
        case "recorder":
          return <RecorderWidget userId={user?.googleId} />;
        case "speechToText":
          return <SpeechToTextWidget />;
        case "sites":
          return (
            <SitesWidget userId={user?.googleId} userEmail={user?.email} />
          );
        case "meditation":
          return <MeditationWidget user={user} />;
        case "userReview":
          return <UserReviewWidget user={user} />;
        case "books":
          return <BooksWidget user={user} />;
        case "promptSaver":
          return <PromptSaverWidget userId={user?.googleId} />;
        case "backgroundRemover":
          return <BackgroundRemoverWidget />;
        case "taskProjects":
          return <TaskProjectsWidget userId={user?.googleId} />;
        case "promptRefiner":
          return <PromptRefinerWidget userId={user?.googleId} />;
        case "imageGenerator":
          return <ImageGeneratorWidget userId={user?.googleId} />;
        case "calculator":
          return <CalculatorWidget userId={user?.googleId} />;
        case "aiTranslator":
          return <AiTranslatorWidget />;
        case "cursorPromptGenerator":
          return <CursorPromptGeneratorWidget />;
        default:
          return null;
      }
    },
    [
      user,
      handleCreateStickyNote,
      currentWorkspace,
      showSpotifyWidget,
      setShowSpotifyWidget,
      spotifyToken,
      isSpotifyAuthenticated,
    ]
  );

  const memoizedWidgets = useMemo(() => {
    return user && Array.isArray(activeWidgets)
      ? activeWidgets.map((widgetId) => {
          const recommendedSize = recommendedSizes[widgetId] || {
            width: 300,
            height: 400,
          };

          const widgetPosition = widgetPositions[currentWorkspace]?.[
            widgetId
          ] || {
            x: Math.random() * (window.innerWidth - recommendedSize.width),
            y: Math.random() * (window.innerHeight - recommendedSize.height),
          };

          const widgetSize =
            widgetSizes[currentWorkspace]?.[widgetId] || recommendedSize;

          return (
            <Widget
              key={`${currentWorkspace}-${widgetId}`}
              id={widgetId}
              onClose={handleCloseWidget}
              initialPos={widgetPosition}
              initialSize={widgetSize}
              zIndex={widgetZIndex[widgetId] || 1000}
              onPositionChange={handleWidgetPositionChange}
              onSizeChange={handleWidgetSizeChange}
              bringToFront={bringWidgetToFront}
            >
              {renderWidget(widgetId)}
            </Widget>
          );
        })
      : [];
  }, [
    user,
    activeWidgets,
    widgetPositions,
    currentWorkspace,
    widgetSizes,
    widgetZIndex,
    handleWidgetPositionChange,
    handleWidgetSizeChange,
    bringWidgetToFront,
    handleCloseWidget,
    renderWidget,
  ]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 200);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleWorkspaceChange = useCallback(async () => {
    const newWorkspace = (currentWorkspace + 1) % 3;

    if (workspaceWidgets[currentWorkspace].includes("taskProjects")) {
      // Check if TaskProjectsWidget is saving
      const taskProjectsWidget = document.querySelector('[data-widget-id="taskProjects"]');
      if (taskProjectsWidget && taskProjectsWidget.dataset.isSaving === 'true') {
        console.log('TaskProjects is currently saving. Please wait before switching workspaces.');
        return;
      }
    }

    if (workspaceWidgets[currentWorkspace].includes("notepad")) {
      await saveCurrentNote();
    }

    setCurrentWorkspace(newWorkspace);
    setActiveWidgets(workspaceWidgets[newWorkspace] || []);
  }, [currentWorkspace, workspaceWidgets, saveCurrentNote]);

  useEffect(() => {
    if (user) {
      saveUserPreferences();
    }
  }, [currentWorkspace, saveUserPreferences, user]);

  useEffect(() => {
    const fetchSpotifyToken = async () => {
      if (user) {
        const storedToken = await getStoredSpotifyToken(user.googleId);
        if (storedToken) {
          setSpotifyToken(storedToken);
          setIsSpotifyAuthenticated(true);
        }
      }
    };
    fetchSpotifyToken();
  }, [user]);

  const handleSpotifyLogin = async (token, expiresIn) => {
    setSpotifyToken(token);
    setIsSpotifyAuthenticated(true);
    await saveSpotifyToken(user.googleId, token, expiresIn);
  };

  const handleSpotifyLogout = async () => {
    setSpotifyToken("");
    setIsSpotifyAuthenticated(false);
    await removeSpotifyToken(user.googleId);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Router>
          <div className="App">
            {!user ? (
              <LandingPage onLoginSuccess={handleGoogleLoginSuccess} />
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 15px",
                    height: "60px",
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Box
                    component="img"
                    src={
                      theme.palette.mode === "dark"
                        ? "/logo-dark.png"
                        : "/logo-light.png"
                    }
                    alt="Logo"
                    sx={{ height: 46, width: 122 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <IconButton
                      sx={{ ml: 1 }}
                      onClick={colorMode.toggleColorMode}
                      color="inherit"
                    >
                      {theme.palette.mode === "dark" ? (
                        <LightModeIcon />
                      ) : (
                        <NightlightIcon />
                      )}
                    </IconButton>
                    {user && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Avatar
                          src={user.picture}
                          alt={user.name}
                          sx={{ width: 32, height: 32, mr: 1 }}
                        />
                        <Typography variant="body2" sx={{ mr: 2 }}>
                          {user.name}
                        </Typography>
                        <Tooltip title="Create a sticky note">
                          <IconButton
                            onClick={handleCreateStickyNote}
                            color="inherit"
                            size="small"
                          >
                            <NoteAddIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Add Widgets">
                          <IconButton
                            onClick={handleWidgetsClick}
                            color="inherit"
                            size="small"
                          >
                            <WidgetsIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Switch Workspace">
                          <IconButton
                            onClick={handleWorkspaceChange}
                            color="inherit"
                            size="small"
                          >
                            <CircleIcon
                              style={{
                                color: workspaceColors[currentWorkspace],
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          onClick={handleNotificationsClick}
                          size="small"
                          color="inherit"
                        >
                          <Badge
                            badgeContent={notifications.length}
                            color="error"
                          >
                            <NotificationsIcon />
                          </Badge>
                        </IconButton>
                        <Tooltip title="Logout">
                          <IconButton
                            onClick={handleLogout}
                            color="inherit"
                            size="small"
                          >
                            <LogoutIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Box>
                {showWidgetList && (
                  <div
                    ref={widgetListRef}
                    className="widget-list-container"
                    style={{
                      position: "absolute",
                      top: "65px",
                      right: "15px",
                      zIndex: 1000,
                    }}
                  >
                    <WidgetList
                      onClose={handleCloseWidgetList}
                      activeWidgets={activeWidgets}
                      onToggleWidget={handleToggleWidget}
                      showSpotifyWidget={showSpotifyWidget}
                    />
                  </div>
                )}
                {showNotifications && (
                  <div
                    ref={notificationListRef}
                    className="notification-list-container"
                    style={{
                      position: "absolute",
                      top: "65px",
                      right: "15px",
                      zIndex: 1000,
                    }}
                  >
                    <NotificationList
                      onClose={handleCloseNotifications}
                      notifications={notifications}
                    />
                  </div>
                )}
                <div
                  className="dashboard"
                  style={{
                    position: "fixed",
                    top: "60px",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflow: "hidden",
                  }}
                >
                  {memoizedWidgets}
                  {stickyNotes
                    .filter((note) => note.workspace === currentWorkspace)
                    .map((note) => (
                      <StickyNotes
                        key={note.id}
                        id={note.id}
                        content={note.content}
                        initialPosition={note.position}
                        zIndex={note.zIndex}
                        color={note.color}
                        onClose={handleCloseStickyNote}
                        bringToFront={bringStickyNoteToFront}
                        onPositionChange={handleStickyNotePositionChange}
                        onContentChange={handleStickyNoteContentChange}
                        onColorChange={handleStickyNoteColorChange}
                      />
                    ))}
                  {showSpotifyWidget && (
                    <Widget
                      id="spotify"
                      onClose={() => setShowSpotifyWidget(false)}
                      initialPos={widgetPositions.spotify || { x: 20, y: 20 }}
                      initialSize={
                        widgetSizes.spotify || { width: 300, height: 400 }
                      }
                      zIndex={9999}
                      onPositionChange={(id, newPosition) => {
                        setWidgetPositions((prev) => ({
                          ...prev,
                          spotify: newPosition,
                        }));
                      }}
                      onSizeChange={(id, newSize) => {
                        setWidgetSizes((prev) => ({
                          ...prev,
                          spotify: newSize,
                        }));
                      }}
                      bringToFront={() => {}}
                    >
                      <SpotifyWidget
                        onClose={() => setShowSpotifyWidget(false)}
                        isVisible={showSpotifyWidget}
                        token={spotifyToken}
                        isAuthenticated={isSpotifyAuthenticated}
                        onLogin={handleSpotifyLogin}
                        onLogout={handleSpotifyLogout}
                        userId={user?.googleId}
                      />
                    </Widget>
                  )}
                </div>
              </>
            )}
          </div>
          <Routes>
            <Route path="/startup/:id" element={<StartupDetail />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default React.memo(App);

