import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  useTheme,
  Popover,
  IconButton,
  Autocomplete,
  CircularProgress,
  Snackbar
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';

const styles = {
  "Very Professional": `Tone: Highly formal, courteous, and precise.
Language: Uses industry jargon or technical terms appropriately, full sentences, and proper grammar. Avoids contractions and colloquialisms.
Purpose: Suitable for official communication, legal matters, corporate settings, or communication with high-level executives or clients.
Structure: Includes a formal greeting (e.g., "Dear Mr. Smith"), clear and concise paragraphs, formal sign-off (e.g., "Sincerely, [Your Name]").`,
  "Professional": `Tone: Polite, respectful, and straightforward.
Language: Standard business language with correct grammar and punctuation, but may include some contractions.
Purpose: Appropriate for everyday business correspondence, internal communications, and customer interactions.
Structure: Begins with a polite greeting (e.g., "Hello John"), clear message body, and a polite closing (e.g., "Best regards, [Your Name]").`,
  "Personal": `Tone: Friendly, conversational, and engaging.
Language: Uses more relaxed language, contractions, and personal pronouns. May include small talk or pleasantries.
Purpose: Ideal for communicating with colleagues, friends, or acquaintances in a professional setting where formality is not required.
Structure: Can start with a more informal greeting (e.g., "Hi John"), includes a more personalized message, and ends with a casual sign-off (e.g., "Cheers, [Your Name]").`,
  "Formal": `Tone: Polite and respectful, but not overly stiff or rigid.
Language: Uses proper grammar and vocabulary but allows for some approachable language. Less formal than "Very Professional."
Purpose: Suitable for situations where respect and politeness are important, such as communicating with someone you don't know well or in a respectful context.
Structure: Starts with a polite greeting (e.g., "Dear Jane"), organized paragraphs, and ends with a formal but not overly stiff closing (e.g., "Kind regards, [Your Name]").`,
  "Casual": `Tone: Relaxed, informal, and conversational.
Language: Uses everyday language, slang, emojis, and abbreviations.
Purpose: Suitable for emails or messages between friends, close colleagues, or in casual business environments.
Structure: Often starts with an informal greeting (e.g., "Hey John"), includes short, friendly sentences, and may end with an informal closing (e.g., "Catch you later, [Your Name]").`,
  "Persuasive": `Tone: Engaging, motivating, and confident.
Language: Uses persuasive language, emotive words, and calls to action.
Purpose: Ideal for marketing emails, sales pitches, or any text that aims to persuade the reader to take a specific action.
Structure: Often includes an attention-grabbing opening, compelling arguments or offers, and a strong call to action.`,
  "Empathetic": `Tone: Compassionate, understanding, and supportive.
Language: Uses language that conveys empathy and care, often with a softer tone.
Purpose: Useful for customer service emails, sensitive communications, or situations where the reader's emotions need to be considered.
Structure: Begins with an understanding greeting (e.g., "I understand how you're feeling, [Name]"), provides supportive content, and ends with a reassuring closing (e.g., "Take care, [Your Name]").`,
  "Concise": `Tone: Brief, to the point, and clear.
Language: Uses short sentences, bullet points, and minimal words to convey key information quickly.
Purpose: Suitable for quick updates, reminders, or when communicating with someone who prefers brevity.
Structure: Minimalist, with direct greetings, succinct content, and a brief closing.`
};

const languages = [
  "Auto detect", "English", "Spanish", "French", "German", "Chinese (Mandarin)", 
  "Japanese", "Portuguese", "Russian", "Arabic", "Italian", "Dutch", "Korean",
  "Hindi", "Turkish", "Polish", "Thai", "Vietnamese", "Indonesian (Bahasa Indonesia)", 
  "Swedish", "Hebrew"
];

const AiTranslatorWidget = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [fromLanguage, setFromLanguage] = useState("Auto detect");
  const [toLanguage, setToLanguage] = useState("English");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme();

  const handleTranslate = async () => {
    if (!inputText || !selectedStyle || !toLanguage) return;

    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/translation/translate`, {
        fromLanguage,
        toLanguage,
        inputText,
        style: selectedStyle
      });

      setOutputText(response.data.result);
    } catch (error) {
      console.error('Translation error:', error);
      setOutputText('An error occurred during translation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSwapLanguages = () => {
    if (fromLanguage !== "Auto detect") {
      setFromLanguage(toLanguage);
      setToLanguage(fromLanguage);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(outputText).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 2,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    }}>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Enter text to translate..."
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover fieldset': {
              borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
            },
          },
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Autocomplete
          options={languages}
          renderInput={(params) => <TextField {...params} label="From" />}
          value={fromLanguage}
          onChange={(event, newValue) => setFromLanguage(newValue)}
          sx={{ flexGrow: 1 }}
        />
        <IconButton onClick={handleSwapLanguages}>
          <SwapHorizIcon />
        </IconButton>
        <Autocomplete
          options={languages.filter(lang => lang !== "Auto detect")}
          renderInput={(params) => <TextField {...params} label="To" />}
          value={toLanguage}
          onChange={(event, newValue) => setToLanguage(newValue)}
          sx={{ flexGrow: 1 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Autocomplete
          options={Object.keys(styles)}
          renderInput={(params) => <TextField {...params} label="Select translation style" />}
          value={selectedStyle}
          onChange={(event, newValue) => {
            setSelectedStyle(newValue);
          }}
          sx={{ flexGrow: 1 }}
        />
        <IconButton 
          size="small" 
          onClick={handleInfoClick}
          disabled={!selectedStyle}
        >
          <InfoIcon fontSize="small" />
        </IconButton>
      </Box>
      <Button 
        variant="contained" 
        onClick={handleTranslate}
        disabled={!selectedStyle || !toLanguage || isLoading}
        sx={{
          mt: 2,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
      >
        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Translate'}
      </Button>
      {outputText && (
        <>
          <TextField
            multiline
            rows={10}  // Increased from 4 to 10 (2.5 times)
            variant="outlined"
            fullWidth
            value={outputText}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              mt: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                },
              },
            }}
          />
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyToClipboard}
            sx={{ mt: 1 }}
          >
            Copy to Clipboard
          </Button>
        </>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, maxWidth: 300 }}>
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {selectedStyle ? styles[selectedStyle] : ''}
          </Typography>
        </Box>
      </Popover>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Copied to clipboard"
      />
    </Box>
  );
};

export default AiTranslatorWidget;