import React, { useState, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, TextField, Button, Paper, CircularProgress, Fade, useTheme, Grid } from '@mui/material';
import CasinoIcon from '@mui/icons-material/Casino';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import axios from 'axios';

const RandomWidget = ({ userId }) => {
  const theme = useTheme();
  const [game, setGame] = useState('number');
  const [minNumber, setMinNumber] = useState(1);
  const [maxNumber, setMaxNumber] = useState(100);
  const [result, setResult] = useState(null);
  const [isRolling, setIsRolling] = useState(false);
  const [coinStats, setCoinStats] = useState({ heads: 0, tails: 0 });
  const [diceStats, setDiceStats] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 });

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const coinResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/coinflip/stats`);
      setCoinStats(coinResponse.data);
      const diceResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/dice/stats`);
      setDiceStats(diceResponse.data);
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  const handleGameChange = (event) => {
    setGame(event.target.value);
    setResult(null);
  };

  const generateRandomNumber = () => {
    const randomNum = Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
    setResult(randomNum);
  };

  const flipCoin = async () => {
    setIsRolling(true);
    setResult(null);
    const audio = new Audio('/sounds/coinflip.wav');
    audio.play();

    setTimeout(async () => {
      const result = Math.random() < 0.5 ? 'heads' : 'tails';
      setResult(result);
      setIsRolling(false);

      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/coinflip`, { result, userId }); 
        fetchStats();
      } catch (error) {
        console.error('Error saving coin flip result:', error);
      }
    }, 2000);
  };

  const rollDice = async () => {
    setIsRolling(true);
    setResult(null);
    const audio = new Audio('/sounds/dice.wav');
    audio.play();

    setTimeout(async () => {
      const result = Math.floor(Math.random() * 6) + 1;
      setResult(result);
      setIsRolling(false);

      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/dice`, { result, userId }); 
        fetchStats();
      } catch (error) {
        console.error('Error saving dice roll result:', error);
      }
    }, 2000);
  };

  const totalCoinFlips = coinStats.heads + coinStats.tails;
  const totalDiceRolls = Object.values(diceStats).reduce((a, b) => a + b, 0);

  return (
    <Box sx={{ 
      p: 2, 
      backgroundColor: theme.palette.mode === 'light' ? '#f0f4f8' : '#1a1a2e', 
      borderRadius: '10px',
      color: theme.palette.text.primary
    }}>
      <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
        <CasinoIcon sx={{ mr: 1 }} /> Random Games
      </Typography>
      <Select
        value={game}
        onChange={handleGameChange}
        fullWidth
        sx={{ 
          mb: 2, 
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary
        }}
      >
        <MenuItem value="number">Random Number</MenuItem>
        <MenuItem value="coin">Coin Flip</MenuItem>
        <MenuItem value="dice">Roll Dice</MenuItem>
      </Select>

      {game === 'number' && (
        <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Min"
            type="number"
            value={minNumber}
            onChange={(e) => setMinNumber(Number(e.target.value))}
            sx={{ backgroundColor: theme.palette.background.paper }}
          />
          <TextField
            label="Max"
            type="number"
            value={maxNumber}
            onChange={(e) => setMaxNumber(Number(e.target.value))}
            sx={{ backgroundColor: theme.palette.background.paper }}
          />
          <Button 
            variant="contained" 
            onClick={generateRandomNumber}
            sx={{ 
              backgroundColor: theme.palette.primary.main,
              '&:hover': { backgroundColor: theme.palette.primary.dark }
            }}
          >
            Generate
          </Button>
        </Box>
      )}

      {game === 'coin' && (
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <Button 
            variant="contained" 
            onClick={flipCoin} 
            disabled={isRolling}
            sx={{ 
              backgroundColor: theme.palette.secondary.main,
              '&:hover': { backgroundColor: theme.palette.secondary.dark },
              '&:disabled': { backgroundColor: theme.palette.action.disabledBackground }
            }}
          >
            {isRolling ? <CircularProgress size={24} color="inherit" /> : 'Flip Coin'}
          </Button>
        </Box>
      )}

      {game === 'dice' && (
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <Button 
            variant="contained" 
            onClick={rollDice} 
            disabled={isRolling}
            sx={{ 
              backgroundColor: theme.palette.secondary.main,
              '&:hover': { backgroundColor: theme.palette.secondary.dark },
              '&:disabled': { backgroundColor: theme.palette.action.disabledBackground }
            }}
          >
            {isRolling ? <CircularProgress size={24} color="inherit" /> : 'Roll Dice'}
          </Button>
        </Box>
      )}

      <Fade in={result !== null}>
        <Paper elevation={3} sx={{ p: 2, mb: 2, textAlign: 'center', backgroundColor: theme.palette.background.paper }}>
          {game === 'number' && (
            <Typography variant="h4">Result: {result}</Typography>
          )}
          {game === 'coin' && (
            <Box>
              <img 
                src={`/images/${result}.png`} 
                alt={result} 
                style={{ 
                  width: 100, 
                  height: 100, 
                  animation: isRolling ? 'flip 2s linear' : 'none'
                }} 
              />
              <Typography variant="h6" sx={{ mt: 1 }}>
                Result: {result && result.charAt(0).toUpperCase() + result.slice(1)}
              </Typography>
            </Box>
          )}
          {game === 'dice' && (
            <Box>
              <img 
                src={`/images/dice-${result}.png`} 
                alt={`Dice ${result}`} 
                style={{ 
                  width: 100, 
                  height: 100, 
                  animation: isRolling ? 'roll 2s linear' : 'none'
                }} 
              />
              <Typography variant="h6" sx={{ mt: 1 }}>
                Result: {result}
              </Typography>
            </Box>
          )}
        </Paper>
      </Fade>

      {game === 'coin' && (
        <Box sx={{ mt: 2, backgroundColor: theme.palette.background.paper, p: 2, borderRadius: '10px' }}>
          <Typography variant="h6" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
            <MonetizationOnIcon sx={{ mr: 1 }} /> Coin Flip Statistics
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body1" sx={{ color: theme.palette.info.main }}>
              Total Flips: {totalCoinFlips}
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.error.main }}>
              Heads: {coinStats.heads} ({((coinStats.heads / totalCoinFlips) * 100).toFixed(1)}%)
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.success.main }}>
              Tails: {coinStats.tails} ({((coinStats.tails / totalCoinFlips) * 100).toFixed(1)}%)
            </Typography>
          </Box>
          <Box sx={{ width: '100%', height: '20px', backgroundColor: theme.palette.action.hover, borderRadius: '10px', overflow: 'hidden' }}>
            <Box 
              sx={{ 
                width: `${(coinStats.heads / totalCoinFlips) * 100}%`, 
                height: '100%', 
                backgroundColor: theme.palette.error.main,
                transition: 'width 0.5s ease-in-out'
              }} 
            />
          </Box>
        </Box>
      )}

      {game === 'dice' && (
        <Box sx={{ mt: 2, backgroundColor: theme.palette.background.paper, p: 2, borderRadius: '10px' }}>
          <Typography variant="h6" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
            <BarChartIcon sx={{ mr: 1 }} /> Dice Roll Statistics
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, color: theme.palette.info.main }}>
            Total Rolls: {totalDiceRolls}
          </Typography>
          <Grid container spacing={1}>
            {Object.entries(diceStats).map(([face, count]) => (
              <Grid item xs={4} key={face}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <img src={`/images/dice-${face}.png`} alt={`Dice ${face}`} style={{ width: 20, height: 20, marginRight: 8 }} />
                  <Typography variant="body2">
                    {count} ({((count / totalDiceRolls) * 100).toFixed(1)}%)
                  </Typography>
                </Box>
                <Box sx={{ width: '100%', height: '10px', backgroundColor: theme.palette.action.hover, borderRadius: '5px', overflow: 'hidden' }}>
                  <Box 
                    sx={{ 
                      width: `${(count / totalDiceRolls) * 100}%`, 
                      height: '100%', 
                      backgroundColor: theme.palette.primary.main,
                      transition: 'width 0.5s ease-in-out'
                    }} 
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <style>
        {`
          @keyframes flip {
            0% { transform: rotateY(0deg); }
            100% { transform: rotateY(720deg); }
          }
          @keyframes roll {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </Box>
  );
};

export default RandomWidget;