import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const suppressResizeObserverLoopError = () => {
  const resizeObserverLoopErrRe = /^ResizeObserver loop limit exceeded/;
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (args.length > 0 && typeof args[0] === 'string' && resizeObserverLoopErrRe.test(args[0])) {
      // Suppress ResizeObserver loop limit exceeded error
      return;
    }
    originalConsoleError.apply(console, args);
  };
};

suppressResizeObserverLoopError();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
