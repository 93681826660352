import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Typography, Box, Button, LinearProgress, useTheme } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const PomodoroWidget = () => {
  const [mode, setMode] = useState('pomodoro');
  const [timeLeft, setTimeLeft] = useState(25 * 60);
  const [isActive, setIsActive] = useState(false);
  const [pomodoroCount, setPomodoroCount] = useState(0);
  const [notificationPermission, setNotificationPermission] = useState(false);
  const intervalRef = useRef(null);
  const audioRef = useRef(new Audio('/sounds/beep.wav'));
  const theme = useTheme();

  const modeTimers = useMemo(() => ({
    pomodoro: 25 * 60,
    shortBreak: 5 * 60,
    longBreak: 15 * 60,
  }), []);

  useEffect(() => {
    if ('Notification' in window) {
      const permission = Notification.permission;
      setNotificationPermission(permission === 'granted');
    }
  }, []);

  const sendNotification = useCallback(() => {
    if (notificationPermission) {
      new Notification('Pomodoro Timer', {
        body: `${mode.charAt(0).toUpperCase() + mode.slice(1)} session ended!`,
        icon: '/path/to/icon.png' // Add an icon path if you have one
      });
    }
  }, [notificationPermission, mode]);

  useEffect(() => {
    if (isActive && timeLeft > 0) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (isActive && timeLeft === 0) {
      clearInterval(intervalRef.current);
      audioRef.current.play();
      sendNotification();
      if (mode === 'pomodoro') {
        setPomodoroCount((prevCount) => prevCount + 1);
        setMode(prevMode => prevMode === 'pomodoro' ? (pomodoroCount % 4 === 3 ? 'longBreak' : 'shortBreak') : 'pomodoro');
      } else {
        setMode('pomodoro');
      }
      setTimeLeft(modeTimers[mode === 'pomodoro' ? (pomodoroCount % 4 === 3 ? 'longBreak' : 'shortBreak') : 'pomodoro']);
    }

    return () => clearInterval(intervalRef.current);
  }, [isActive, timeLeft, mode, pomodoroCount, modeTimers, sendNotification]);

  const toggleTimer = () => {
    if (!isActive && !notificationPermission) {
      requestNotificationPermission();
    }
    setIsActive(!isActive);
  };

  const resetTimer = () => {
    setIsActive(false);
    setTimeLeft(modeTimers[mode]);
  };

  const changeMode = (newMode) => {
    setMode(newMode);
    setTimeLeft(modeTimers[newMode]);
    setIsActive(false);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const progress = ((modeTimers[mode] - timeLeft) / modeTimers[mode]) * 100;

  const requestNotificationPermission = async () => {
    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      setNotificationPermission(permission === 'granted');
    }
  };

  const buttonStyle = {
    backgroundColor: theme.palette.mode === 'light' ? '#3a7bd5' : '#006ae6',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#2d62aa' : '#0056b3',
    },
    boxShadow: theme.palette.mode === 'light' ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
    transition: 'all 0.3s ease',
  };

  return (
    <Box sx={{ 
      textAlign: 'center', 
      p: 2, 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      background: theme.palette.mode === 'light' 
        ? 'linear-gradient(135deg, #f5f7fa 0%, #e4e8f0 100%)' 
        : 'inherit',
    }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button 
          onClick={() => changeMode('pomodoro')} 
          variant="contained"
          sx={{
            ...buttonStyle,
            flex: 1,
            opacity: mode === 'pomodoro' ? 1 : 0.7,
          }}
        >
          POMODORO
        </Button>
        <Button 
          onClick={() => changeMode('shortBreak')} 
          variant="contained"
          sx={{
            ...buttonStyle,
            flex: 1,
            mx: 1,
            opacity: mode === 'shortBreak' ? 1 : 0.7,
          }}
        >
          SHORT BREAK
        </Button>
        <Button 
          onClick={() => changeMode('longBreak')} 
          variant="contained"
          sx={{
            ...buttonStyle,
            flex: 1,
            opacity: mode === 'longBreak' ? 1 : 0.7,
          }}
        >
          LONG BREAK
        </Button>
      </Box>

      <Typography variant="h2" sx={{ 
        mb: 2, 
        fontWeight: 'bold', 
        color: theme.palette.mode === 'light' ? '#2c3e50' : '#ffffff',
        textShadow: theme.palette.mode === 'light' ? '0 1px 2px rgba(0,0,0,0.1)' : 'none',
      }}>
        {formatTime(timeLeft)}
      </Typography>

      <LinearProgress 
        variant="determinate" 
        value={progress} 
        sx={{ 
          mb: 2, 
          height: 6,
          borderRadius: 3,
          backgroundColor: theme.palette.mode === 'light' ? '#e0e0e0' : '#ffffff',
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.mode === 'light' ? '#3a7bd5' : '#4c7baf',
            borderRadius: 3,
          },
        }}
      />

      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button
          variant="contained"
          startIcon={isActive ? <PauseIcon /> : <PlayArrowIcon />}
          onClick={toggleTimer}
          sx={buttonStyle}
        >
          {isActive ? 'PAUSE' : 'START'}
        </Button>
        <Button
          variant="contained"
          startIcon={<RestartAltIcon />}
          onClick={resetTimer}
          sx={buttonStyle}
        >
          RESET
        </Button>
      </Box>

      <Typography 
        variant="body2" 
        sx={{ 
          mt: 'auto', 
          fontSize: '0.8rem',
          color: theme.palette.mode === 'light' ? '#34495e' : '#9a9aaa'
        }}
      >
        Completed Pomodoros: {pomodoroCount}
      </Typography>
    </Box>
  );
};

export default PomodoroWidget;