import React, { useState } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Box, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const EmojiZone = () => {
  const [open, setOpen] = useState(false);

  const handleEmojiSelect = (emoji) => {
    navigator.clipboard.writeText(emoji.native);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ width: 300, borderRadius: 2 }}>
      <Picker
        data={data}
        onEmojiSelect={handleEmojiSelect}
        showPreview={false}
        showSkinTones={false}
        theme="auto"
      />
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Emoji copied!
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EmojiZone;