import React from 'react';
import { Typography, Box } from '@mui/material';

const NewsWidget = () => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>News Combiner</Typography>
      <Typography>Coming soon.</Typography>
    </Box>
  );
};

export default NewsWidget;