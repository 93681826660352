import React from 'react';
import { Typography, Box, Button, Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { GoogleLogin } from '@react-oauth/google';

const LandingPage = ({ onLoginSuccess }) => (
  <Box
    sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      background: theme => `linear-gradient(to bottom, ${theme.palette.common.black}, ${theme.palette.common.black})`,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/background/overlay-2.webp)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <Container maxWidth="lg" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box sx={{ color: 'common.white', textAlign: { xs: 'center', md: 'left' } }}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt="Logo"
                sx={{ width: 222, height: 84, mb: 8 }}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Typography variant="h1" component="h1" gutterBottom sx={{ fontWeight: 800, fontSize: '60px' }}>
                Get the{' '}
                <Box
                  component="span"
                  sx={{
                    fontWeight: 800,
                    background: 'linear-gradient(90deg, #006ae6 20%, #00e6d6 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Tools
                </Box>{' '}
                you deserve
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <GoogleLogin
                onSuccess={onLoginSuccess}
                onError={() => {
                  console.log('Login Failed');
                }}
                useOneTap
                flow="implicit"
                render={renderProps => (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    sx={{
                      bgcolor: 'common.white',
                      color: 'common.black',
                      '&:hover': {
                        bgcolor: 'grey.100',
                      },
                    }}
                  >
                    Get Started
                  </Button>
                )}
              />
            </motion.div>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/assets/illustrations/dashboard.png`}
              alt="Dashboard Illustration"
              sx={{ width: '100%', maxWidth: 460 }}
            />
          </motion.div>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default LandingPage;