import React, { useState } from 'react';
import { Box, Grid, Switch, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ArticleIcon from '@mui/icons-material/Article';
import TimerIcon from '@mui/icons-material/Timer';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import AssignmentIcon from '@mui/icons-material/Assignment';
import QrCodeIcon from '@mui/icons-material/QrCode';
import NoteIcon from '@mui/icons-material/Note';
import BusinessIcon from '@mui/icons-material/Business';
import LinkIcon from '@mui/icons-material/Link';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ImageIcon from '@mui/icons-material/Image';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LanguageIcon from '@mui/icons-material/Language';
import LockIcon from '@mui/icons-material/Lock';
import CasinoIcon from '@mui/icons-material/Casino';
import MicIcon from '@mui/icons-material/Mic';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SpaIcon from '@mui/icons-material/Spa';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import WallpaperIcon from '@mui/icons-material/Wallpaper'; // Add this import
import CalculateIcon from '@mui/icons-material/Calculate'; // Add this import
import TranslateIcon from '@mui/icons-material/Translate'; // Add this import

const categories = [
  'All',
  'AI',
  'Finances',
  'Web Tools',
  'Games',
  'Image',
  'Video',
  'Productivity',
  'PlaceHolder'
];

const widgets = [
  { id: 'spotify', name: 'Spotify Player', icon: <MusicNoteIcon />, category: 'Productivity' },
  { id: 'news', name: 'News Combiner', icon: <ArticleIcon />, category: 'Web Tools' },
  { id: 'pomodoro', name: 'Pomodoro Timer', icon: <TimerIcon />, category: 'Productivity' },
  { id: 'weather', name: 'Weather', icon: <WbSunnyIcon />, category: 'Web Tools' },
  { id: 'tasks', name: 'Task List', icon: <AssignmentIcon />, category: 'Productivity' },
  { id: 'qrcode', name: 'QR Code Generator', icon: <QrCodeIcon />, category: 'Web Tools' },
  { id: 'notepad', name: 'Notepad', icon: <NoteIcon />, category: 'Productivity' },
  { id: 'startupElevator', name: 'Startup Elevator', icon: <BusinessIcon />, category: 'Finances' },
  { id: 'urlShortener', name: 'URL Shortener', icon: <LinkIcon />, category: 'Web Tools' },
  { id: 'tictactoe', name: 'Tic Tac Toe', icon: <SportsEsportsIcon />, category: 'Games' },
  { id: 'socialMediaBanner', name: 'Social Media Banner', icon: <ImageIcon />, category: 'Image' },
  { id: 'emojiZone', name: 'Emoji Zone', icon: <EmojiEmotionsIcon />, category: 'Web Tools' },
  { id: 'colorPicker', name: 'Color Picker', icon: <ColorLensIcon />, category: 'Image' },
  { id: 'tokenizer', name: 'Tokenizer', icon: <LanguageIcon />, category: 'AI' },
  { id: 'encryption', name: 'Encryption Tool', icon: <LockIcon />, category: 'Web Tools' },
  { id: 'random', name: 'Random Games', icon: <CasinoIcon />, category: 'Games' },
  { id: 'recorder', name: 'Voice Recorder', icon: <MicIcon />, category: 'Productivity' },
  { id: 'speechToText', name: 'Speech to Text', icon: <RecordVoiceOverIcon />, category: 'Productivity' },
  { id: 'sites', name: 'Website Hosting', icon: <LanguageIcon />, category: 'Web Tools' },
  { id: 'meditation', name: 'Meditation', icon: <SpaIcon />, category: 'Productivity' },
  { id: 'userReview', name: 'Review DashFlow', icon: <FeedbackIcon />, category: 'Productivity' },
  { id: 'books', name: 'Book Recommendations', icon: <MenuBookIcon />, category: 'Productivity' },
  { id: 'promptSaver', name: 'Prompt Saver', icon: <AutoFixHighIcon />, category: 'AI' },
  { id: 'backgroundRemover', name: 'Background Remover', icon: <WallpaperIcon />, category: 'Image' },
  { id: 'taskProjects', name: 'Task Projects', icon: <AssignmentIcon />, category: 'Productivity' },
  { id: 'promptRefiner', name: 'Prompt Refiner', icon: <AutoFixHighIcon />, category: 'AI' },
  { id: 'imageGenerator', name: 'Image Generator', icon: <ImageIcon />, category: 'AI' },
  { id: 'calculator', name: 'Calculator', icon: <CalculateIcon />, category: 'Productivity' },
  { id: 'aiTranslator', name: 'AI Translator', icon: <TranslateIcon />, category: 'AI' },
  { id: 'cursorPromptGenerator', name: 'Cursor Prompt Generator', icon: <AutoFixHighIcon />, category: 'AI' },
  // Add more widgets here...
];

function WidgetList({ onClose, activeWidgets, onToggleWidget, showSpotifyWidget }) {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const filteredWidgets = selectedCategory === 'All'
    ? widgets.sort((a, b) => a.name.localeCompare(b.name))
    : widgets.filter(widget => widget.category === selectedCategory);

  // Distribute widgets across columns, 6 widgets per column max
  const columns = [[], [], []];
  filteredWidgets.forEach((widget, index) => {
    const columnIndex = Math.floor(index / 6);
    if (columnIndex < 3) {
      columns[columnIndex].push(widget);
    }
  });

  return (
    <Box sx={{ 
      width: '100%', 
      maxWidth: 1200,
      bgcolor: '#0b0c10',
      borderRadius: 3,
      boxShadow: 3, 
      overflow: 'hidden',
      border: '1px solid #1b1c22',
      padding: '23px 40px',
    }}>
      <Grid container spacing={2}>
        <Grid item sx={{ width: 230, borderRight: 1, borderColor: 'divider' }}>
          <List sx={{ pt: 0 }}>
            {categories.map((category) => (
              <ListItem
                key={category}
                button
                selected={category === selectedCategory}
                onClick={() => setSelectedCategory(category)}
                sx={{ 
                  color: '#b5b7c8',
                  '&:hover': {
                    bgcolor: '#0d0e12',
                  },
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  },
                  py: '11.5px',
                }}
              >
                <ListItemText primary={category} />
              </ListItem>
            ))}
          </List>
        </Grid>
        {columns.map((column, columnIndex) => (
          <Grid item xs key={columnIndex} sx={{ minHeight: 200 }}> {/* Set a minimum height to ensure visibility */}
            <List sx={{ pt: 0 }}>
              {column.map((widget) => (
                <ListItem 
                  key={widget.id} 
                  sx={{ py: '11.5px' }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <Box sx={{ color: '#575a67' }}>
                      {widget.icon}
                    </Box>
                  </ListItemIcon>
                  <ListItemText 
                    primary={widget.name} 
                    primaryTypographyProps={{ 
                      variant: 'body2', 
                      fontWeight: 'medium',
                      color: '#b5b7c8'
                    }}
                  />
                  <Switch 
                    edge="end" 
                    size="small"
                    checked={widget.id === 'spotify' ? showSpotifyWidget : activeWidgets.includes(widget.id)}
                    onChange={() => onToggleWidget(widget.id)}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default WidgetList;