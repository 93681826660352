import React, { useState, useRef } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  IconButton,
  Tooltip,
  InputAdornment
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CryptoJS from 'crypto-js';

const UseCasesContent = () => (
  <Box sx={{ paddingTop: '10px', paddingLeft: '10px', maxWidth: 300 }}>
    <Typography variant="subtitle1" gutterBottom>Common Use Cases:</Typography>
    <ul style={{ paddingLeft: '20px', paddingBottom: '20px', paddingRight: '20px', margin: 0 }}>
      <li><Typography variant="body2">Secure Communication</Typography></li>
      <li><Typography variant="body2">Secret Messages for Fun</Typography></li>
      <li><Typography variant="body2">Digital Time Capsules</Typography></li>
      <li><Typography variant="body2">Private Notes and Journals</Typography></li>
      <li><Typography variant="body2">Verification Purposes</Typography></li>
      <li><Typography variant="body2">Personal Safety</Typography></li>
      <li><Typography variant="body2">Sharing Sensitive Work Files</Typography></li>
      <li><Typography variant="body2">Event or Access Management</Typography></li>
      <li><Typography variant="body2">Game Development</Typography></li>
      <li><Typography variant="body2">Data Backup with Security</Typography></li>
    </ul>
    <Typography variant="subtitle1" gutterBottom>Important: We can't break the encryption if you lose your password, and we don't store this information.</Typography>
  </Box>
);

const EncryptionWidget = () => {
  const [input, setInput] = useState('');
  const [password, setPassword] = useState('');
  const [output, setOutput] = useState('');
  const [isShaking, setIsShaking] = useState(false);
  const outputRef = useRef(null);
  const incorrectSound = useRef(new Audio('/sounds/incorrect.wav'));
  const correctSound = useRef(new Audio('/sounds/correct.wav')); // Add this line

  const handleEncrypt = () => {
    if (!input || !password) return;
    const encrypted = CryptoJS.AES.encrypt(input, password).toString();
    setOutput(encrypted);
  };

  const handleDecrypt = () => {
    if (!input || !password) return;
    try {
      const decrypted = CryptoJS.AES.decrypt(input, password).toString(CryptoJS.enc.Utf8);
      if (!decrypted) {
        throw new Error('Decryption failed');
      }
      setOutput(decrypted);
      correctSound.current.play(); // Add this line
    } catch (error) {
      setOutput('Decryption failed. The password is incorrect or the input is not a valid encrypted message.');
      setIsShaking(true);
      incorrectSound.current.play();
      setTimeout(() => setIsShaking(false), 820); // Duration of shake animation + 20ms buffer
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(output);
  };

  return (
    <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Encryption/Decryption Tool</Typography>
        <Tooltip title={<UseCasesContent />} arrow>
          <IconButton>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
      
      <TextField
        label="Input"
        multiline
        rows={4}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <Button variant="contained" color="primary" onClick={handleEncrypt} sx={{ mr: 1 }}>
          Encrypt
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDecrypt} sx={{ ml: 1 }}>
          Decrypt
        </Button>
      </Box>
      
      <TextField
        label="Output"
        multiline
        rows={4}
        value={output}
        fullWidth
        margin="normal"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCopy} edge="end">
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        ref={outputRef}
        sx={{
          '& .MuiInputBase-root': {
            animation: isShaking ? 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both' : 'none',
          },
          '@keyframes shake': {
            '10%, 90%': {
              transform: 'translate3d(-1px, 0, 0)',
            },
            '20%, 80%': {
              transform: 'translate3d(2px, 0, 0)',
            },
            '30%, 50%, 70%': {
              transform: 'translate3d(-4px, 0, 0)',
            },
            '40%, 60%': {
              transform: 'translate3d(4px, 0, 0)',
            },
          },
        }}
      />
      
      {/* Remove the Paper component with use cases and the disclaimer */}
    </Box>
  );
};

export default EncryptionWidget;