import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Link, IconButton, Tooltip, Paper, Dialog, DialogTitle, DialogContent } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton,
         FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon } from 'react-share';

const URLShortenerWidget = ({ userId }) => {
  const [longUrl, setLongUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [userUrls, setUserUrls] = useState([]);
  const [showUrls, setShowUrls] = useState(false);
  const [error, setError] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');

  const validateUrl = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
  };

  const handleShortenLink = async () => {
    if (!validateUrl(longUrl)) {
      setError('Please enter a valid URL');
      return;
    }
    setError('');
    try {
      console.log('Shortening URL with:', { longUrl, userId });
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/shorten`, { longUrl, userId }); 
      const shortCode = response.data.shortUrl.split('/').pop();
      const shortUrl = `${process.env.REACT_APP_BACKEND_URL}/s/${shortCode}`; 
      setShortUrl(shortUrl);
      setUserUrls(prev => [...prev, { ...response.data, shortUrl }]);
    } catch (error) {
      console.error('Error shortening URL:', error.response?.data || error.message);
      setError('Error shortening URL. Please try again.');
    }
  };

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url);
  };

  const handleDeleteLink = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/shorten/${id}`); 
      setUserUrls(prev => prev.filter(url => url._id !== id));
    } catch (error) {
      console.error('Error deleting URL:', error);
    }
  };

  const handleShowUrls = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/shorten/${userId}`); 
      setUserUrls(response.data);
      setShowUrls(true);
    } catch (error) {
      console.error('Error fetching URLs:', error);
    }
  };

  const handleShortenAnother = () => {
    setLongUrl('');
    setShortUrl('');
    setShowUrls(false);
    setError('');
  };

  const downloadQRCode = (url) => {
    const svg = document.getElementById(`qr-code-${url}`);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `qrcode-${url.split('/').pop()}.png`;
      downloadLink.href = pngFile;
      downloadLink.click();
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
  };

  const handleShareClick = (url) => {
    setShareUrl(url);
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
  };

  const handleRefreshVisits = async (urlId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/shorten/refresh/${urlId}`); 
      setUserUrls(prev => prev.map(url => url._id === urlId ? { ...url, visits: response.data.visits } : url));
    } catch (error) {
      console.error('Error refreshing visit count:', error);
    }
  };

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {!shortUrl && !showUrls ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <LinkIcon sx={{ mr: 1 }} />
            <Typography variant="h6">Shorten a long URL:</Typography>
          </Box>
          <TextField
            fullWidth
            label="Enter long link here"
            value={longUrl}
            onChange={(e) => setLongUrl(e.target.value)}
            error={!!error}
            helperText={error}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 2 }}>
            <Button variant="outlined" onClick={handleShowUrls}>My URLs</Button>
            <Button variant="contained" onClick={handleShortenLink}>Shorten Link</Button>
          </Box>
        </>
      ) : showUrls ? (
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Your URLs:</Typography>
          {userUrls.map(url => (
            <Paper key={url._id} sx={{ p: 2, mb: 2 }}>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                Long URL: <TextField value={url.longUrl} fullWidth InputProps={{ readOnly: true }} />
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                Shortened URL: <TextField value={url.shortUrl} fullWidth InputProps={{ readOnly: true }} />
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Visit URL">
                    <IconButton component="a" href={url.shortUrl} target="_blank" rel="noopener">
                      <ArrowForwardIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Generate QR Code">
                    <IconButton onClick={() => downloadQRCode(url.shortUrl)}>
                      <QrCodeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Share">
                    <IconButton onClick={() => handleShareClick(url.shortUrl)}>
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy to clipboard">
                    <IconButton onClick={() => handleCopyLink(url.shortUrl)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete link">
                    <IconButton onClick={() => handleDeleteLink(url._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                    <VisibilityIcon fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2">Visits: {url.visits || 0}</Typography>
                    <Tooltip title="Refresh Visits">
                      <IconButton onClick={() => handleRefreshVisits(url._id)} size="small">
                        <RefreshIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <QRCodeSVG
                id={`qr-code-${url.shortUrl}`}
                value={url.shortUrl}
                size={128}
                style={{ display: 'none' }}
              />
            </Paper>
          ))}
          <Button variant="contained" onClick={handleShortenAnother}>Shorten another</Button>
        </Box>
      ) : (
        <Paper sx={{ p: 2, width: '100%' }}>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            Long URL: <TextField value={longUrl} fullWidth InputProps={{ readOnly: true }} />
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            Shortened URL: <TextField value={shortUrl} fullWidth InputProps={{ readOnly: true }} />
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Tooltip title="Visit URL">
              <IconButton component="a" href={shortUrl} target="_blank" rel="noopener">
                <ArrowForwardIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Generate QR Code">
              <IconButton onClick={() => downloadQRCode(shortUrl)}>
                <QrCodeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share">
              <IconButton onClick={() => handleShareClick(shortUrl)}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy to clipboard">
              <IconButton onClick={() => handleCopyLink(shortUrl)}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Button variant="contained" onClick={handleShowUrls} sx={{ mr: 1 }}>My URLs</Button>
          <Button variant="contained" onClick={handleShortenAnother}>Shorten another</Button>
          <QRCodeSVG
            id={`qr-code-${shortUrl}`}
            value={shortUrl}
            size={128}
            style={{ display: 'none' }}
          />
        </Paper>
      )}

      <Dialog open={shareDialogOpen} onClose={handleCloseShareDialog}>
        <DialogTitle>
          Share Shortened URL
          <IconButton
            aria-label="close"
            onClick={handleCloseShareDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <QRCodeSVG value={shareUrl} size={128} />
            <Typography variant="body1">{shareUrl}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <EmailShareButton url={shareUrl}>
                <EmailIcon size={32} round />
              </EmailShareButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default URLShortenerWidget;