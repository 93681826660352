import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const StartupDetail = () => {
  const { id } = useParams();
  const [startup, setStartup] = useState(null);

  useEffect(() => {
    const fetchStartup = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/startups/${id}`);
        setStartup(response.data);
      } catch (error) {
        console.error('Error fetching startup:', error);
      }
    };
    fetchStartup();
  }, [id]);

  if (!startup) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar src={startup.logo} alt={startup.name} sx={{ mr: 2, width: 56, height: 56 }} />
        <Typography variant="h4">{startup.name}</Typography>
      </Box>
      <Typography variant="body1" sx={{ mb: 2 }}>{startup.shortDescription}</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>{startup.description}</Typography>
      <Typography variant="h6">Comments</Typography>
      <Box sx={{ mt: 2 }}>
        {/* Placeholder for comments section */}
        <Typography variant="body2">Comments section coming soon...</Typography>
      </Box>
    </Box>
  );
};

export default StartupDetail;