import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, TextField, List, ListItem, ListItemText, Paper, useTheme, FormControlLabel, Switch } from '@mui/material';
import axios from 'axios';

const UserReviewWidget = ({ user }) => {
  const theme = useTheme();
  const [submitted, setSubmitted] = useState(false);
  const [review, setReview] = useState({
    newFeatures: '',
    subscriptionPrice: '',
    recommendReason: '',
    message: '',
  });
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [adminResponse, setAdminResponse] = useState('');
  const [userResponse, setUserResponse] = useState(null);
  const [showAnswered, setShowAnswered] = useState(false);
  const [hasUnansweredReview, setHasUnansweredReview] = useState(false);
  const [latestReview, setLatestReview] = useState(null);
  const [hasUnreadResponse, setHasUnreadResponse] = useState(false);

  const fetchReviews = useCallback(async () => {
    try {
      console.log('Fetching reviews...');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user-reviews/filtered?answered=${showAnswered}`); 
      console.log('Fetch successful:', response.data);
      setReviews(response.data);
    } catch (error) {
      console.error('Error fetching reviews:', error.response ? error.response.data : error.message);
    }
  }, [showAnswered]);

  const fetchUserResponse = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user-reviews/response/${user.googleId}`); 
      setUserResponse(response.data.response);
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        console.error('Error fetching user response:', error);
      }
    }
  }, [user.googleId]);

  const fetchUserReviewStatus = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user-reviews/status/${user.googleId}`); 
      setHasUnansweredReview(response.data.hasUnansweredReview);
      setHasUnreadResponse(response.data.hasUnreadResponse);
      setLatestReview(response.data.latestReview);
      if (response.data.latestReview && response.data.latestReview.answered) {
        setUserResponse(response.data.latestReview.adminResponse);
      }
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        console.error('Error fetching user review status:', error);
      }
    }
  }, [user.googleId]);

  useEffect(() => {
    if (user.email === 'bbertapeli@gmail.com') {
      fetchReviews();
    } else {
      fetchUserReviewStatus();
    }
  }, [user.email, fetchReviews, fetchUserReviewStatus, latestReview]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReview(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user-reviews`, { 
        userId: user.googleId,
        ...review
      });
      setSubmitted(true);
      setHasUnansweredReview(true);
      setLatestReview({
        ...review,
        userId: user.googleId,
        answered: false,
        timestamp: new Date(),
        _id: response.data.reviewId
      });
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  const handleAdminResponse = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user-reviews/response`, { 
        reviewId: selectedReview._id,
        response: adminResponse
      });
      setSelectedReview(null);
      setAdminResponse('');
      fetchReviews();
    } catch (error) {
      console.error('Error submitting admin response:', error);
    }
  };

  const resetWidget = () => {
    setSubmitted(false);
    setReview({
      newFeatures: '',
      subscriptionPrice: '',
      recommendReason: '',
      message: '',
    });
    setUserResponse(null);
    setHasUnansweredReview(false);
    setLatestReview(null);
  };

  const markReviewAsRead = useCallback(async () => {
    if (latestReview) {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/user-reviews/mark-read/${latestReview._id}`); 
        setHasUnreadResponse(false);
      } catch (error) {
        console.error('Error marking review as read:', error);
      }
    }
  }, [latestReview]);

  const questions = [
    {
      label: "What new features or tools would make you consider subscribing to DashFlow?",
      name: "newFeatures",
      rows: 2
    },
    {
      label: "If DashFlow had all the features and tools you want, including AI-driven options and extensive functionality, how much would you be willing to pay for a subscription?",
      name: "subscriptionPrice",
      rows: 1
    },
    {
      label: "What would inspire you to recommend DashFlow to a friend?",
      name: "recommendReason",
      rows: 2
    },
    {
      label: "Have a message for us? Share it here, and we will get back to you!",
      name: "message",
      rows: 3
    }
  ];

  const paperStyle = {
    p: 3,
    bgcolor: theme.palette.mode === 'dark' ? '#0b0c10' : 'background.paper',
    color: theme.palette.text.primary,
  };

  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'primary.main',
      },
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
  };

  const handleToggleAnswered = () => {
    setShowAnswered(prev => !prev);
  };

  if (user.email !== 'bbertapeli@gmail.com') {
    if (latestReview && latestReview.answered && hasUnreadResponse) {
      return (
        <Paper elevation={3} sx={paperStyle}>
          <Typography variant="h6" gutterBottom>DashFlow answered your message:</Typography>
          <Typography sx={{ mt: 2, mb: 2 }}>{latestReview.adminResponse}</Typography>
          <Button variant="contained" onClick={() => {
            markReviewAsRead();
            resetWidget();
          }}>Submit another Review</Button>
        </Paper>
      );
    }

    if (latestReview && !latestReview.answered) {
      return (
        <Paper elevation={3} sx={{ ...paperStyle, textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Thanks for your message!
          </Typography>
          <Typography variant="body1">
            We're reviewing it and will get back to you soon. You'll also receive a notification.
          </Typography>
        </Paper>
      );
    }

    return (
      <Paper elevation={3} sx={paperStyle}>
        <Typography variant="h5" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}>
          Review DashFlow
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ mb: 2, textAlign: 'center' }}>
          Your feedback means the world to us! Please take a moment to leave a review and share your thoughts.
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ mb: 4, textAlign: 'center', fontStyle: 'italic' }}>
          Remember, this is just an MVP, and everything will be polished and expanded over time, especially based on your feedback.
        </Typography>
        {questions.map((q) => (
          <Box key={q.name} sx={{ mb: 3 }}>
            <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
              {q.label}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={q.rows}
              variant="outlined"
              name={q.name}
              value={review[q.name]}
              onChange={handleInputChange}
              sx={textFieldStyle}
            />
          </Box>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ borderRadius: 28, px: 3 }}
          >
            Submit Review
          </Button>
        </Box>
      </Paper>
    );
  }

  if (selectedReview) {
    return (
      <Box>
        <Typography variant="subtitle1">Review from {selectedReview.userEmail}</Typography>
        {questions.map((q) => (
          <Box key={q.name} sx={{ mb: 2 }}>
            <Typography variant="body2" fontWeight="bold">{q.label}</Typography>
            <Typography variant="body1">{selectedReview[q.name]}</Typography>
          </Box>
        ))}
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          label="Your Response"
          value={adminResponse}
          onChange={(e) => setAdminResponse(e.target.value)}
          sx={{ mt: 2, mb: 2 }}
        />
        <Button variant="contained" onClick={handleAdminResponse}>Submit Response</Button>
      </Box>
    );
  }

  return (
    <Paper elevation={3} sx={{ ...paperStyle, maxHeight: '400px', overflowY: 'auto' }}>
      <Typography variant="h6" gutterBottom>User Reviews</Typography>
      <FormControlLabel
        control={<Switch checked={showAnswered} onChange={handleToggleAnswered} />}
        label={showAnswered ? "Showing Answered" : "Showing Unanswered"}
      />
      <List>
        {reviews.map((review) => (
          <ListItem key={review._id} button onClick={() => setSelectedReview(review)}>
            <ListItemText 
              primary={review.userEmail} 
              secondary={`${new Date(review.timestamp).toLocaleDateString()} - ${review.answered ? 'Answered' : 'Unanswered'}`}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default UserReviewWidget;