import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Typography, Fade, Paper, TextField } from '@mui/material';
import { styled } from '@mui/system';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';

const RecordButton = styled(IconButton)(({ theme }) => ({
  width: 80,
  height: 80,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StopButton = styled(IconButton)(({ theme }) => ({
  width: 80,
  height: 80,
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const CountdownTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '3rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

const WaveformCanvas = styled('canvas')({
  width: '100%',
  height: '50px',
  backgroundColor: 'transparent',
});

const RecorderWidget = ({ userId }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordings, setRecordings] = useState([]);
  const [countdown, setCountdown] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingName, setEditingName] = useState('');

  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const beepAudio = useRef(new Audio('/sounds/beep.wav'));
  const canvasRef = useRef(null);
  const animationRef = useRef(null);

  const saveRecording = useCallback(async (audioBlob, name) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, `${name}.wav`);
    formData.append('userId', userId);
    formData.append('name', name);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/recordings`, formData, { 
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    } catch (error) {
      console.error('Error saving recording:', error);
    }
  }, [userId]);

  const startRecording = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const source = audioCtx.createMediaStreamSource(stream);
    const analyserNode = audioCtx.createAnalyser();
    analyserNode.fftSize = 2048;
    source.connect(analyserNode);

    setAudioContext(audioCtx);
    setAnalyser(analyserNode);

    mediaRecorder.current = new MediaRecorder(stream);
    mediaRecorder.current.ondataavailable = (event) => {
      audioChunks.current.push(event.data);
    };
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);
      const newRecording = { url: audioUrl, timestamp: new Date().toLocaleString(), name: `Recording ${recordings.length + 1}` };
      setRecordings(prevRecordings => [...prevRecordings, newRecording]);
      saveRecording(audioBlob, newRecording.name);
      audioChunks.current = [];
    };
    mediaRecorder.current.start();
    setIsRecording(true);
    setCountdown(null);
  }, [recordings.length, saveRecording]);

  const drawWaveform = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !analyser) return;

    const canvasCtx = canvas.getContext('2d');
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      if (!canvas) return;

      animationRef.current = requestAnimationFrame(draw);
      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = '#87CEFA';
      canvasCtx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    draw();
  }, [analyser]);

  const loadRecordings = useCallback(async () => {
    if (!userId) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/recordings/${userId}`); 
      setRecordings(response.data.map(recording => ({
        ...recording,
        url: `${process.env.REACT_APP_BACKEND_URL}/api/recordings/download/${recording.filename}`, 
      })));
    } catch (error) {
      console.error('Error loading recordings:', error);
    }
  }, [userId]);

  useEffect(() => {
    if (countdown > 0) {
      beepAudio.current.play();
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      startRecording();
    }
  }, [countdown, startRecording]);

  useEffect(() => {
    if (isRecording && canvasRef.current) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationRef.current);
      if (canvasRef.current) {
        const canvasCtx = canvasRef.current.getContext('2d');
        canvasCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }
  }, [isRecording, drawWaveform]);

  useEffect(() => {
    loadRecordings();
  }, [loadRecordings]);

  const startCountdown = () => {
    setCountdown(3);
  };

  const stopRecording = () => {
    mediaRecorder.current.stop();
    setIsRecording(false);
    if (audioContext) {
      audioContext.close();
    }
  };

  const handleDelete = async (index) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/recordings/${recordings[index]._id}`);
      const newRecordings = [...recordings];
      newRecordings.splice(index, 1);
      setRecordings(newRecordings);
    } catch (error) {
      console.error('Error deleting recording:', error);
    }
  };

  const handleDownload = (url, timestamp) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `recording_${timestamp.replace(/[/:]/g, '-')}.wav`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handlePlayPause = (index) => {
    if (currentlyPlaying === index) {
      recordings[index].audio.pause();
      setCurrentlyPlaying(null);
    } else {
      if (currentlyPlaying !== null) {
        recordings[currentlyPlaying].audio.pause();
      }
      if (!recordings[index].audio) {
        recordings[index].audio = new Audio(recordings[index].url);
      }
      recordings[index].audio.play();
      setCurrentlyPlaying(index);
    }
  };

  const handleRename = (index) => {
    setEditingIndex(index);
    setEditingName(recordings[index].name);
  };

  const handleRenameSubmit = async (index) => {
    const newRecordings = [...recordings];
    newRecordings[index].name = editingName;
    setRecordings(newRecordings);
    setEditingIndex(null);

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/recordings/${recordings[index]._id}`, { 
        name: editingName
      });
    } catch (error) {
      console.error('Error updating recording name:', error);
    }
  };

  return (
    <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'relative',
        height: '100px',
      }}>
        {!isRecording && countdown === null && (
          <RecordButton onClick={startCountdown}>
            <MicIcon fontSize="large" />
          </RecordButton>
        )}
        {countdown !== null && (
          <Fade in={countdown !== null}>
            <CountdownTypography>{countdown}</CountdownTypography>
          </Fade>
        )}
        {isRecording && (
          <StopButton onClick={stopRecording} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <StopIcon />
          </StopButton>
        )}
      </Box>
      {isRecording && (
        <Box sx={{ height: 50, mb: 2 }}>
          <WaveformCanvas ref={canvasRef} />
        </Box>
      )}
      <Paper sx={{ 
        flexGrow: 1, 
        overflow: 'auto', 
        maxHeight: 'calc(100% - 150px)',
        '& .MuiListItem-root': { 
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
          padding: '8px 16px' 
        }, 
        '& .MuiListItemText-primary': { 
          fontWeight: 'bold', 
          fontSize: '0.9rem' 
        }, 
        '& .MuiListItemText-secondary': { 
          fontSize: '0.75rem' 
        } 
      }}>
        <List disablePadding>
          {recordings.map((recording, index) => (
            <ListItem key={index} dense>
              <IconButton size="small" onClick={() => handlePlayPause(index)}>
                {currentlyPlaying === index ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
              {editingIndex === index ? (
                <TextField
                  value={editingName}
                  onChange={(e) => setEditingName(e.target.value)}
                  onBlur={() => handleRenameSubmit(index)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleRenameSubmit(index);
                    }
                  }}
                  autoFocus
                  size="small"
                  sx={{ ml: 1, flexGrow: 1 }}
                />
              ) : (
                <ListItemText
                  primary={recording.name}
                  secondary={recording.timestamp}
                  sx={{ ml: 1 }}
                />
              )}
              <ListItemSecondaryAction>
                {editingIndex === index ? (
                  <IconButton edge="end" aria-label="confirm" size="small" onClick={() => handleRenameSubmit(index)}>
                    <CheckIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <IconButton edge="end" aria-label="rename" size="small" onClick={() => handleRename(index)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
                <IconButton edge="end" aria-label="download" size="small" onClick={() => handleDownload(recording.url, recording.timestamp)}>
                  <GetAppIcon fontSize="small" />
                </IconButton>
                <IconButton edge="end" aria-label="delete" size="small" onClick={() => handleDelete(index)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default RecorderWidget;