import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, CircularProgress, IconButton, Fade, Rating } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios';

const meditationInstructions = [
  "Sit comfortably with your back straight.",
  "Close your eyes gently.",
  "Take a deep breath in, then exhale slowly.",
  "Focus on the feeling of your breath.",
  "Notice any tension in your body; relax it.",
  "Let your thoughts come and go without judgment.",
  "If your mind wanders, gently return to your breath.",
  "Feel the rise and fall of your chest.",
  "Be aware of the present moment.",
  "When ready, open your eyes slowly."
];

const MeditationWidget = ({ user }) => {
  const [timeLimit, setTimeLimit] = useState(5);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentInstruction, setCurrentInstruction] = useState(0);
  const audioRef = useRef(null);
  const audioContextRef = useRef(null);
  const gainNodeRef = useRef(null);
  const intervalRef = useRef(null);
  const instructionIntervalRef = useRef(null);
  const [showReview, setShowReview] = useState(false);
  const [rating, setRating] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(null);

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (instructionIntervalRef.current) clearInterval(instructionIntervalRef.current);
      if (audioRef.current) audioRef.current.pause();
      if (audioContextRef.current) audioContextRef.current.close();
    };
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleTimeChange = (change) => {
    setTimeLimit((prev) => Math.max(1, Math.min(60, prev + change)));
  };

  const selectRandomTrack = (duration) => {
    // In a real application, this list should come from your server
    // For now, we'll simulate it with a few tracks
    const availableTracks = [
        "0001_0452.mp3",
        "0002_0456.mp3",
        "0003_0507.mp3",
        "0004_0513.mp3",
        "0005_0521.mp3",
        "0006_0536.mp3",
        "0007_0604.mp3",
        "0008_0606.mp3",
        "0009_0608.mp3",
        "0010_0625.mp3",
        "0011_0711.mp3",
        "0012_0702.mp3",
        "0013_0736.mp3",
        "0014_0808.mp3",
        "0015_0905.mp3",
        "0016_0944.mp3",
        "0017_0952.mp3",
        "0018_1000.mp3",
        "0019_1002.mp3",
        "0020_1030.mp3",
        "0021_1031.mp3",
        "0022_1045.mp3",
        "0023_1520.mp3",
        "0024_1532.mp3",
        "0025_1614.mp3",
        "0026_2104.mp3",
        "0027_2218.mp3",
        "0028_0513.mp3",
        "0029_0349.mp3",
        "0030_0518.mp3",
        "0031_1513.mp3",
        "0032_0255.mp3",
        "0033_0607.mp3",
        "0034_1118.mp3",
        "0035_0613.mp3",
        "0036_0339.mp3",
        "0037_0643.mp3",
        "0038_0257.mp3",
        "0039_1559.mp3",
        "0040_1523.mp3",
        "0041_0938.mp3",
        "0042_0312.mp3",
        "0043_0101.mp3",
        "0044_0108.mp3",
        "0045_0141.mp3",
        "0046_0148.mp3",
        "0047_0153.mp3",
        "0048_0200.mp3",
        "0049_0207.mp3",
        "0050_0211.mp3",
        "0051_0257.mp3",
        "0052_0258.mp3",
        "0053_0303.mp3",
        "0054_0355.mp3",
        "0055_0403.mp3",
        "0056_0405.mp3",
        "0057_0422.mp3",
        "0058_0446.mp3"
    ];
    

    const suitableTracks = availableTracks.filter(track => {
      const trackDuration = parseInt(track.split('_')[1].split('.')[0]);
      return trackDuration >= duration;
    });

    if (suitableTracks.length === 0) {
      return null;
    }

    const randomIndex = Math.floor(Math.random() * suitableTracks.length);
    const selectedTrack = suitableTracks[randomIndex];
    setCurrentTrack(selectedTrack);
    return `/meditation/${selectedTrack}`;
  };

  const startMeditation = () => {
    setCurrentTime(0);
    setIsPlaying(true);
    setCurrentInstruction(0);
    setShowReview(false);

    const trackPath = selectRandomTrack(timeLimit * 60);
    if (!trackPath) {
      console.error('No suitable track found');
      setIsPlaying(false);
      return;
    }

    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    gainNodeRef.current = audioContextRef.current.createGain();
    gainNodeRef.current.connect(audioContextRef.current.destination);

    // Set initial volume to 70%
    gainNodeRef.current.gain.setValueAtTime(0.7, audioContextRef.current.currentTime);

    audioRef.current = new Audio(trackPath);
    const source = audioContextRef.current.createMediaElementSource(audioRef.current);
    source.connect(gainNodeRef.current);

    audioRef.current.play().catch(error => {
      console.error('Error playing audio:', error);
      console.log('Attempted to play:', trackPath);
      setIsPlaying(false);
    });

    intervalRef.current = setInterval(() => {
      setCurrentTime((prevTime) => {
        if (prevTime >= timeLimit * 60) {
          endMeditation();
          return timeLimit * 60;
        }
        // Start fading out 10 seconds before the end
        if (timeLimit * 60 - prevTime <= 10) {
          const timeLeft = timeLimit * 60 - prevTime;
          const volume = Math.max(0, 0.7 * (timeLeft / 10));
          gainNodeRef.current.gain.exponentialRampToValueAtTime(volume, audioContextRef.current.currentTime + 0.5);
        }
        return prevTime + 1;
      });
    }, 1000);

    instructionIntervalRef.current = setInterval(() => {
      setCurrentInstruction((prev) => (prev + 1) % meditationInstructions.length);
    }, 4000);
  };

  const endMeditation = () => {
    clearInterval(intervalRef.current);
    clearInterval(instructionIntervalRef.current);
    setIsPlaying(false);
    setCurrentTime(0);
    setCurrentInstruction(0);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.setValueAtTime(0.7, audioContextRef.current.currentTime);
    }
    setShowReview(true);
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
      clearInterval(instructionIntervalRef.current);
    } else {
      audioRef.current.play().catch(console.error);
      intervalRef.current = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (timeLimit * 60 - prevTime <= 10) {
            const timeLeft = timeLimit * 60 - prevTime;
            const volume = Math.max(0, 0.7 * (timeLeft / 10));
            gainNodeRef.current.gain.exponentialRampToValueAtTime(volume, audioContextRef.current.currentTime + 0.5);
          }
          return prevTime + 1;
        });
      }, 1000);
      instructionIntervalRef.current = setInterval(() => {
        setCurrentInstruction((prev) => (prev + 1) % meditationInstructions.length);
      }, 4000);
    }
    setIsPlaying(!isPlaying);
  };

  const stopMeditation = () => {
    endMeditation();
  };

  const restartMeditation = () => {
    stopMeditation();
    startMeditation();
  };

  const handleRatingSubmit = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/meditation/feedback`, {
        userId: user.googleId,
        trackId: currentTrack,
        rating: rating,
        duration: timeLimit
      });
      setShowReview(false);
      setRating(0);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, bgcolor: 'background.paper', borderRadius: 2 }}>
      {showReview ? (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Welcome back, {user.name.split(' ')[0]}. Take a moment to notice how you feel. 🙏
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
            We hope you enjoyed this meditation. Please rate the sound you just listened to so we can continue to improve your experience.
          </Typography>
          <Rating
            name="meditation-rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            size="large"
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={handleRatingSubmit} disabled={!rating}>
            Submit Feedback
          </Button>
        </Box>
      ) : (
        <>
          {isPlaying && (
            <Fade in={isPlaying} timeout={500}>
              <Typography variant="h5" sx={{ mb: 2, textAlign: 'center', minHeight: '3em' }}>
                {meditationInstructions[currentInstruction]}
              </Typography>
            </Fade>
          )}
          <Box sx={{ position: 'relative', display: 'inline-flex', width: 200, height: 200 }}>
            {isPlaying && (
              <>
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={200}
                  thickness={4}
                  sx={{ color: 'grey.300' }}
                />
                <CircularProgress
                  variant="determinate"
                  value={(currentTime / (timeLimit * 60)) * 100}
                  size={200}
                  thickness={4}
                  sx={{ 
                    color: 'primary.main',
                    position: 'absolute',
                    left: 0,
                  }}
                />
              </>
            )}
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {!isPlaying ? (
                <>
                  <Typography variant="h5" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    Set a Time
                  </Typography>
                  <Typography variant="body2" gutterBottom sx={{ color: 'text.secondary', mb: 2, textAlign: 'center' }}>
                    We will choose the best songs based on your time limit
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <IconButton onClick={() => handleTimeChange(-1)} color="primary" size="small">
                      <RemoveIcon />
                    </IconButton>
                    <Typography variant="h5" sx={{ mx: 2, minWidth: 60, textAlign: 'center' }}>
                      {formatTime(timeLimit * 60)}
                    </Typography>
                    <IconButton onClick={() => handleTimeChange(1)} color="primary" size="small">
                      <AddIcon />
                    </IconButton>
                  </Box>
                  <Button
                    variant="contained"
                    onClick={startMeditation}
                    startIcon={<PlayArrowIcon />}
                    sx={{ borderRadius: 28, px: 3 }}
                  >
                    Start
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h4" component="div" color="text.primary" sx={{ mb: 2 }}>
                    {formatTime(timeLimit * 60 - currentTime)}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton onClick={togglePlayPause} size="small" color="primary">
                      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <IconButton onClick={stopMeditation} size="small" color="error">
                      <StopIcon />
                    </IconButton>
                    <IconButton onClick={restartMeditation} size="small" color="secondary">
                      <ReplayIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MeditationWidget;