import axios from 'axios';
import { handleGoogleLoginSuccess, handleLogout } from '../app/auth/auth';

export const handleGoogleLoginSuccessWrapper = async (
  credentialResponse,
  setUser,
  setMode,
  setWorkspaceWidgets,
  setWidgetPositions,
  setWidgetSizes,
  setWidgetZIndex,
  setStickyNotes,
  setActiveWidgets,
  currentWorkspace
) => {
  try {
    const userData = await handleGoogleLoginSuccess(
      credentialResponse,
      setUser,
      setMode,
      setWorkspaceWidgets,
      setWidgetPositions,
      setWidgetSizes,
      setWidgetZIndex,
      setStickyNotes
    );
    const initializedWorkspaceWidgets = userData.workspaceWidgets || [[], [], []];
    setWorkspaceWidgets(initializedWorkspaceWidgets);
    const initializedWidgetPositions = userData.widgetPositions || {0: {}, 1: {}, 2: {}};
    setWidgetPositions(initializedWidgetPositions);
    const initializedWidgetSizes = userData.widgetSizes || {0: {}, 1: {}, 2: {}};
    setWidgetSizes(initializedWidgetSizes);
    const initializedWidgetZIndex = userData.widgetZIndex || {};
    setWidgetZIndex(initializedWidgetZIndex);
    const initializedStickyNotes = userData.stickyNotes || [];
    setStickyNotes(initializedStickyNotes);
    setActiveWidgets(initializedWorkspaceWidgets[currentWorkspace] || []);

    if (userData.widgets && userData.widgets.includes('recorder')) {
      const recorderWidget = document.querySelector('[data-widget-id="recorder"]');
      if (recorderWidget) {
        const recorderComponent = recorderWidget.__react_internal_instance$.child.child.stateNode;
        recorderComponent.loadRecordings();
      }
    }
  } catch (error) {
    console.error('Error handling Google login:', error);
  }
};

export const handleLogoutWrapper = async (
  user,
  mode,
  workspaceWidgets,
  widgetPositions,
  widgetSizes,
  widgetZIndex,
  setUser,
  setMode,
  setWorkspaceWidgets,
  setWidgetPositions,
  setWidgetSizes,
  setWidgetZIndex,
  setStickyNotes,
  handleCloseWidgetList,
  handleCloseNotifications,
  saveCurrentNote,
  saveUserPreferences
) => {
  try {
    await saveCurrentNote();
    await saveUserPreferences();
    await handleLogout(
      user,
      mode,
      workspaceWidgets,
      widgetPositions,
      widgetSizes,
      widgetZIndex,
      setUser,
      setMode,
      setWorkspaceWidgets,
      setWidgetPositions,
      setWidgetSizes,
      setWidgetZIndex,
      setStickyNotes,
      handleCloseWidgetList,
      handleCloseNotifications
    );
  } catch (error) {
    console.error('Error logging out:', error);
  }
};
