import React, { useState } from 'react';
import { Box, IconButton, Snackbar, Tooltip, Typography } from '@mui/material'; // Added Typography import
import MuiAlert from '@mui/material/Alert';
import { SketchPicker } from 'react-color-new';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Draggable from 'react-draggable';


const ColorPicker = () => {
  const [image, setImage] = useState(null);
  const [color, setColor] = useState({ r: 0, g: 0, b: 0, hex: '#000000' });
  const [open, setOpen] = useState(false);
  const [copiedText, setCopiedText] = useState('');

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.error('Invalid file type. Please upload an image.');
    }
  };

  const handleUrlUpload = () => {
    const url = prompt('Enter image URL:');
    if (url) {
      setImage(url);
    }
  };

  const handleColorChange = (color) => {
    setColor({ r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, hex: color.hex });
  };

  const handleCopy = (text) => {
    setCopiedText(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1, p: 0.5 }}>
        <Tooltip title="Upload File">
          <IconButton component="label" size="small">
            <FileUploadIcon fontSize="small" />
            <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Upload from URL">
          <IconButton onClick={handleUrlUpload} size="small">
            <LinkIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ position: 'relative', flexGrow: 1, overflow: 'hidden' }}>
        {image && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
            <img src={image} alt="Uploaded" style={{ width: '100%', height: 'auto' }} />
          </Box>
        )}
        <Draggable>
          <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 1000 }}>
            <SketchPicker color={color.hex} onChangeComplete={handleColorChange} />
          </Box>
        </Draggable>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ width: 50, height: 50, bgcolor: `rgb(${color.r}, ${color.g}, ${color.b})`, border: '1px solid #000' }} />
          <Typography>R: {color.r} G: {color.g} B: {color.b}</Typography>
          <CopyToClipboard text={`${color.r}, ${color.g}, ${color.b}`} onCopy={() => handleCopy(`${color.r}, ${color.g}, ${color.b}`)}>
            <IconButton size="small">
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </CopyToClipboard>
          <Typography>Hex: {color.hex}</Typography>
          <CopyToClipboard text={color.hex} onCopy={() => handleCopy(color.hex)}>
            <IconButton size="small">
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </CopyToClipboard>
        </Box>
      </Box>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {copiedText} copied!
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default ColorPicker;