import React, { useState, useRef, useEffect, useMemo, Component } from 'react';
import { Box, IconButton, Tooltip, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Chip, Fade, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { format } from 'date-fns';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/material/styles';

const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}`;

const QuillWrapper = styled('div')(({ theme }) => ({
  '& .ql-container': {
    border: 'none',
    fontSize: '22px',
    fontFamily: 'inherit',
  },
  '& .ql-editor': {
    padding: '20px 0 0 0', // Add top padding to lower the first line
    color: theme.palette.mode === 'light' ? '#867478' : '#ad9d8f',
    backgroundColor: 'transparent',
  },
  '& .ql-toolbar': {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.mode === 'light' ? '#e9e9db' : '#2e2a2e',
    padding: '8px 0', // Add some vertical padding to the toolbar
  },
  '& .ql-stroke': {
    stroke: theme.palette.mode === 'light' ? '#867478' : '#ad9d8f',
  },
  '& .ql-fill': {
    fill: theme.palette.mode === 'light' ? '#867478' : '#ad9d8f',
  },
  '& .ql-picker': {
    color: theme.palette.mode === 'light' ? '#867478' : '#ad9d8f',
  },
}));

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong with the Quill editor.</h1>;
    }

    return this.props.children;
  }
}

const NotepadWidget = ({ userId, onClose, setSaveCurrentNote }) => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [notes, setNotes] = useState([]);
  const [currentNoteId, setCurrentNoteId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const theme = useTheme();
  const notesContainerRef = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchInputRef = useRef(null);
  const [deleteButtonRef, setDeleteButtonRef] = useState(null);
  const [activeNoteId, setActiveNoteId] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      fetchNotes();
    }
    setTitle('');
    setContent('');
    setCurrentNoteId(null);
    setActiveNoteId(null);
    setUnsavedChanges(false);
  }, [userId]);

  useEffect(() => {
    const extractTags = () => {
      const allTags = notes.reduce((acc, note) => {
        const noteTags = (note.content.match(/#\w+/g) || []).map(tag => tag.slice(1));
        return [...acc, ...noteTags];
      }, []);
      setTags([...new Set(allTags)]);
    };
    extractTags();
  }, [notes]);

  const fetchNotes = async () => {
    if (!userId) {
      console.error('No userId provided');
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/notes/${userId}`);
      setNotes(response.data || []);
    } catch (error) {
      console.error('Error fetching notes:', error.response || error);
      setNotes([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContentChange = (value) => {
    setContent(value);
    setUnsavedChanges(true);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setUnsavedChanges(true);
  };

  const handleNewNote = () => {
    setTitle('');
    setContent('');
    setCurrentNoteId(null);
    setActiveNoteId(null);
    setUnsavedChanges(false);
  };

  const handleNoteClick = async (note) => {
    if (unsavedChanges) {
      await saveCurrentNote();
    }
    setTitle(note.title);
    setContent(note.content);
    setCurrentNoteId(note._id);
    setActiveNoteId(note._id);
    setIsSearching(false);
    setSearchQuery('');
    setSearchResults([]);
    setUnsavedChanges(false);
  };

  const handleSaveNote = async () => {
    try {
      let savedNote;
      if (currentNoteId) {
        savedNote = await axios.put(`${BASE_URL}/api/notes/${currentNoteId}`, {
          userId,
          title: title || 'Untitled',
          content,
          updatedAt: new Date()
        });
      } else {
        savedNote = await axios.post(`${BASE_URL}/api/notes`, {
          userId,
          title: title || 'Untitled',
          content
        });
        setCurrentNoteId(savedNote.data._id);
      }
      setUnsavedChanges(false);
      fetchNotes();
    } catch (error) {
      console.error('Error saving note:', error.response || error);
    }
  };

  const handleScrollNotes = (direction) => {
    if (notesContainerRef.current) {
      const scrollAmount = 100;
      notesContainerRef.current.scrollLeft += direction * scrollAmount;
    }
  };

  const handleDeleteNote = async () => {
    if (!currentNoteId) return;
    try {
      await axios.delete(`${BASE_URL}/api/notes/${currentNoteId}`);
      setTitle('');
      setContent('');
      setCurrentNoteId(null);
      fetchNotes();
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error deleting note:', error.response || error);
    }
  };

  const handleTagClick = (tag) => {
    const noteWithTag = notes.find(note => note.content.includes(`#${tag}`));
    if (noteWithTag) {
      setTitle(noteWithTag.title);
      setContent(noteWithTag.content);
      setCurrentNoteId(noteWithTag._id);
    }
  };

  const handleSearchToggle = () => {
    setIsSearching(!isSearching);
    if (!isSearching) {
      setTimeout(() => searchInputRef.current?.focus(), 100);
    } else {
      setSearchQuery('');
      setSearchResults([]);
    }
  };

  const handleSearch = () => {
    const results = notes.filter(note => 
      note.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      note.content.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(results);
  };

  const highlightSearchResults = (text) => {
    if (!searchQuery) return text;
    const parts = text.split(new RegExp(`(${searchQuery})`, 'gi'));
    return parts.map((part, i) => 
      part.toLowerCase() === searchQuery.toLowerCase() 
        ? <mark key={i} style={{ backgroundColor: 'yellow', color: 'black' }}>{part}</mark> 
        : part
    );
  };

  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const handleDownloadAsRTF = async () => {
    if (!content) return;
    
    if (!currentNoteId) {
      await handleSaveNote();
    }

    const rtfContent = content
      .replace(/<p>/gi, '\\par ')
      .replace(/<\/p>/gi, '')
      .replace(/<br>/gi, '\\par ')
      .replace(/&nbsp;/gi, ' ')
      .replace(/[<][^>]*[>]/gi, '');

    const rtfHeader = `{\\rtf1\\ansi\\deff0
{\\fonttbl{\\f0 Times New Roman;}}
{\\colortbl;\\red0\\green0\\blue0;}
\\viewkind4\\uc1\\pard\\cf1\\f0\\fs24
${title}\\par
\\par
`;

    const rtfFooter = '}';

    const fullRtfContent = rtfHeader + rtfContent + rtfFooter;

    const blob = new Blob([fullRtfContent], { type: 'application/rtf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${title || 'Untitled'}.rtf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const saveCurrentNote = async () => {
    if (unsavedChanges) {
      try {
        let savedNote;
        if (currentNoteId) {
          savedNote = await axios.put(`${BASE_URL}/api/notes/${currentNoteId}`, {
            userId,
            title: title || 'Untitled',
            content,
            updatedAt: new Date()
          });
        } else {
          savedNote = await axios.post(`${BASE_URL}/api/notes`, {
            userId,
            title: title || 'Untitled',
            content
          });
          setCurrentNoteId(savedNote.data._id);
        }
        setUnsavedChanges(false);
        fetchNotes();
      } catch (error) {
        console.error('Error saving note:', error.response || error);
      }
    }
  };

  useEffect(() => {
    setSaveCurrentNote(() => saveCurrentNote);
  }, [setSaveCurrentNote, unsavedChanges, title, content]);

  const modules = useMemo(() => ({
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean']
    ],
  }), []);

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: '"Roboto Slab", serif',
      backgroundColor: theme.palette.mode === 'light' ? '#e9e9db' : '#2e2a2e',
      color: theme.palette.mode === 'light' ? '#867478' : '#ad9d8f',
      position: 'relative',
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', pb: 0, mb: -1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1, pb: 0 }}>
          <IconButton size="small" onClick={() => handleScrollNotes(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <Box ref={notesContainerRef} sx={{ display: 'flex', overflowX: 'hidden', flex: 1 }}>
            {notes.map((note, index) => (
              <Button
                key={index}
                onClick={() => handleNoteClick(note)}
                sx={{
                  mr: 1,
                  px: 1,
                  py: 0.5,
                  minWidth: 'auto',
                  maxWidth: '150px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  backgroundColor: note._id === activeNoteId 
                    ? (theme.palette.mode === 'light' ? '#c1c1b7' : '#4d4a4d')
                    : (theme.palette.mode === 'light' ? '#d1d1c7' : '#3d3a3d'),
                  color: theme.palette.mode === 'light' ? '#4a4a4a' : '#d1d1d1',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'light' ? '#c1c1b7' : '#4d4a4d',
                  },
                  borderRadius: '8px',
                  fontSize: '0.55rem',
                }}
              >
                {note.title}
              </Button>
            ))}
          </Box>
          <IconButton size="small" onClick={() => handleScrollNotes(1)}>
            <ChevronRightIcon />
          </IconButton>
          <Tooltip title="Save Note">
            <span>
              <IconButton
                size="small"
                onClick={handleSaveNote}
                disabled={!content && !currentNoteId}
              >
                <SaveIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Download as RTF">
            <span>
              <IconButton
                size="small"
                onClick={handleDownloadAsRTF}
                disabled={!content}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Search">
            <IconButton size="small" onClick={handleSearchToggle}>
              {isSearching ? <SearchIcon fontSize="small" /> : <SearchIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
          <Tooltip title="New Note">
            <IconButton size="small" onClick={handleNewNote}>
              <AddIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Note">
            <span>
              <IconButton 
                size="small" 
                onClick={(event) => {
                  setDeleteButtonRef(event.currentTarget);
                  setDeleteDialogOpen(true);
                }} 
                disabled={!currentNoteId}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="You can add #tags to your text for easy finding later.">
            <IconButton size="small">
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        
        <Fade in={isSearching}>
          <Box sx={{ px: 2, py: 0 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Search notes..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              inputRef={searchInputRef}
            />
          </Box>
        </Fade>
      </Box>

      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out',
        transform: isSearching ? 'translateY(60px)' : 'translateY(0)',
        pt: 0,
        mt: -1,
        backgroundColor: theme.palette.mode === 'light' ? '#e9e9db' : '#2e2a2e',
      }}>
        {!isSearching && (
          <TextField
            value={title}
            onChange={handleTitleChange}
            placeholder="Title"
            variant="standard"
            sx={{ px: 2, py: 0 }}
          />
        )}
        <Box sx={{ flexGrow: 1, position: 'relative', p: 2, pt: 0, mt: -1, overflow: 'auto' }}>
          {searchResults.length > 0 ? (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: theme.palette.background.paper,
              overflowY: 'auto',
              p: 2,
              zIndex: 1,
            }}>
              {searchResults.map((note, index) => (
                <Box 
                  key={index} 
                  sx={{ 
                    mb: 2, 
                    p: 2, 
                    backgroundColor: theme.palette.mode === 'light' ? 'rgba(0,0,0,0.05)' : 'rgba(255,255,255,0.05)', 
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: theme.palette.mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)',
                    },
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                  onClick={() => handleNoteClick(note)}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" sx={{ mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold' }}>
                      {highlightSearchResults(note.title)}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                      {highlightSearchResults(stripHtmlTags(note.content).substring(0, 100))}...
                    </Typography>
                  </Box>
                  <Typography variant="caption" sx={{ ml: 2, whiteSpace: 'nowrap' }}>
                    {format(new Date(note.updatedAt), 'MMM d, yyyy')}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <QuillWrapper>
              {!isSearching && !searchResults.length && (
                <ErrorBoundary>
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={handleContentChange}
                    modules={modules}
                    style={{
                      height: 'calc(100% - 42px)',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  />
                </ErrorBoundary>
              )}
            </QuillWrapper>
          )}
        </Box>
      </Box>

      <Box sx={{ p: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {isLoading ? (
          <Typography>Loading tags...</Typography>
        ) : (
          (tags && tags.length > 0) ? (
            tags.map((tag, index) => (
              <Chip
                key={index}
                label={`#${tag}`}
                onClick={() => handleTagClick(tag)}
                size="small"
                sx={{
                  fontSize: '0.7rem',
                  backgroundColor: index % 2 === 0 
                    ? `${theme.palette.primary.light}99`  // 60% opacity
                    : `${theme.palette.secondary.light}99`,  // 60% opacity
                  color: theme.palette.getContrastText(
                    index % 2 === 0 
                      ? theme.palette.primary.light 
                      : theme.palette.secondary.light
                  ) + '99',  // 60% opacity for text
                }}
              />
            ))
          ) : (
            <Typography>No tags available</Typography>
          )
        )}
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          style: {
            position: 'absolute',
            maxWidth: '300px',
            width: '90%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        sx={{
          '& .MuiDialog-container': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        }}
      >
        <DialogTitle>{"Delete Note"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this note? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>No</Button>
          <Button onClick={handleDeleteNote} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

function stripHtmlTags(html) {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export default NotepadWidget;